/* eslint-disable array-callback-return */
import React from "react"
import { Spinner,Navbar, ButtonGroup,Popover,Alignment, Button, Collapse, Elevation, Card, Dialog, FormGroup, Classes, Tooltip, AnchorButton, Intent, InputGroup, Callout } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import moment from 'moment';
import { DatosPersonasJuridicas } from "../DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "../Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Elementos/InformacionGeneralNuevo"
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import styles from '../stylesPrint';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";


class TransmisionTitulos extends React.Component {

    state ={
        transmisiones: [
        ],
        distribuciones:[],
        distribucionesCopy:[],
        modalNuevoAbierto: false,
        modalBorrarTransmision:false,
        personas: [],
        personaSeleccionada: null,
        valoresATransferir: 1,
        maxTitulos:1,
        distribucionesSeleccionada:null,
        cargandoGrid:false,
        nuevaTransmisionActivado:true,
        editarTransmisionActivado:false,
        borrarTransmisionActivado:false,
        fechaSeleccionada:new Date(),
        transmisionSeleccionada:null,
        cargando:false,
        abierto1: false,
        abierto2: false, 
        abierto3: false, 
        abierto4: false, 
        abierto5: false, 
        abierto6: false, 
        abierto7: false, 
        abierto8: false,
        tipoCapital:false,
        imprimirTransmisionActivado:false,
        modalImprimirTransmision:false,
        cargandoImprimirT:false,
        variacionDistribucion:null
    }

    // cargarPersonasFisicas= async()=>{
	// 	let personasFisicas=await this.props.client
	// 	.query({
	// 		query: gql`
    //         {personasFisicas(ORDEN:"APELLIDO1"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasFisicas
	// 	})
	// 	return personasFisicas
	// }
	// cargarPersonasJuridicas=async()=>{
	// 	let personasJuridicas= await  this.props.client
	// 	.query({
	// 		query: gql`
    //         {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}`,
	// 		fetchPolicy:'network-only'

	// 	}).then(result=>{ 
	// 		return result.data.personasJuridicas
	// 	})
	// 	return personasJuridicas	
	// }
    cargarDatos= async()=>{
		let variacionDistribucion= await this.cargaraVariacionDist()
		this.setState({
			variacionDistribucion:variacionDistribucion,
		})
	}
    cargaraVariacionDist= async() =>{
        let variacionDist=  await this.props.client.query({
            query:gql`{capSocialVariacionesDistribucion
                {ID_VARIACION,
                ID_DISTRIBUCION,
                VARIACION,
                NUMERACION_TITULOS,
                ID_PERSONA,
                TIPO_TITULO
            }}`
                
                ,fetchPolicy:'network-only'
        }).then(async result=>{
           return  result.data.capSocialVariacionesDistribucion
        })  
        return variacionDist
    }

    obtenerNombreMiembros=async(miembro)=>{
        let nombre=''
        if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===miembro.ID_PERSONA){
                    nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                }
            })
            
 
         //Si es persona juridica
         }else if(miembro.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(persona=>{
                 if(persona.ID_PERSONA===miembro.ID_PERSONA){
                     nombre= persona.RAZON_SOCIAL
                 }
             })
             
         }
         miembro.nombre= nombre
    }

    convertirNumerosArray=(num)=>{
        let numeros=[]
        let numeracion=num.split(",")
        numeracion.forEach(num=>{
            if(num.includes("-")){
                let dis=num.split("-")
                for(let i=dis[0];i<=dis[1];i++){
                    numeros.push(String(i))
                }
            }else{
                numeros.push(num)
            }
        
        })
        return numeros
        
    }

    convertirArrayNumeros=(arr)=>{

        //union de los titulos.
        for(let i = 0;i < arr.length;i++)
        {
        arr[i] = parseInt(arr[i]);
        }

        let numeros=""

        var results = [];
        var limit   = arr.length-1; 

        
        for (var i = 0; i <= limit; ++i) {
        if(i<limit){
            if((arr[i]+1)===(arr[i+1])){results.push(arr[i])}else{
            if(results.length>0){
                results.push(arr[i])
                numeros+=results[0]+"-"+results[results.length-1]+","
                results = []
            }else{
                numeros+=arr[i]+","
                results = []
            }
            }
        }else if(i===limit){
            if((arr[i]-1)===(arr[i-1])){results.push(arr[i])}else{
            if(results.length>0){
                results.push(arr[i])
                numeros+=results[0]+"-"+results[results.length-1]+","
                results = []
            }else{
                numeros+=arr[i]+","
                results = []
            }
            }
        }
        }

        if(results.length>0){
        if(numeros.includes(results[0]+"-"+results[results.length-1])===false){
            numeros+=results[0]+"-"+results[results.length-1]+","
        }
        }

        if(numeros[numeros.length-1]===",")numeros=numeros.substring(0,numeros.length-1)

        return numeros
    }
    obtenerPotencias=(n)=>{
        let array = [];
        let binaryRepresentation = parseInt(n, 10).toString(2);
        binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
        for(let i = binaryRepresentation.length - 1; i >= 0; i--){
            if(parseInt(binaryRepresentation[i]) === 1){
                array[Math.pow(2, i)]=true
            }
    
        }
    
        return array
    }


    cargarTransmisiones=async()=>{

        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })

        for(let transmision of transmisiones){
            await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
            await this.obtenerNombreMiembros(transmision.FK_DESTINO)

            //obtencion del tipo del titulo.
            let tiposKeys=Object.keys(this.obtenerPotencias(transmision.TIPO_ACCION))
            let tipos=""

            tiposKeys.forEach(t=>{

                let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
                if(tipos===""){
                tipos+=tipoSel.DESCRIPCION
                }else{

                tipos+=","+tipoSel.DESCRIPCION

                }

            })

            transmision.tipo=tipos
        }

     

        //console.log(transmisiones)
        this.setState({transmisiones:transmisiones})
        //obtenemos el nombre de origen y destion
    }

   

    cargarCapital=async()=>{

        // let capital=this.props.capSocialInicialDistribucion
        // await this.props.client.query({
        //   query:gql`{capSocialInicialDistribucion{
        //     ID_DISTRIBUCION,
        //     ID_PERSONA,
        //     PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
        //     TIPO_TITULO,
        //     NUMERO_TITULOS,
        //     NUMERACION_TITULOS
        //   }}`,fetchPolicy:'network-only'
        // }).then(async(result)=>{
        //     return JSON.parse(JSON.stringify(result.data.capSocialInicialDistribucion))
        // })
        // console.log(capital)
        let variaciones=await this.props.client.query({
            query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:1,FECHA:{DESDE:"1970-01-01",HASTA:"${moment().format("YYYY-MM-DD")}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.capSocialVariaciones
        })
        let variacDist=  this.state.variacionDistribucion
        // await this.props.client.query({
        //     query:gql`{capSocialVariacionesDistribucion{ID_VARIACION,ID_DISTRIBUCION,VARIACION,NUMERACION_TITULOS}}`,fetchPolicy:'network-only'
        // }).then(async result=>{
        //    return  result.data.capSocialVariacionesDistribucion
        // })    
        let capital=this.props.capSocialInicialDistribucion
        let index=0
        for(let cap of capital){
        //buscamos el nombre de la persona
        let nombre=""
        if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===cap.ID_PERSONA){
                    nombre= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDP2:''} ${persona.NOMBRE}`
                }
            })
    
       
    
            //Si es persona juridica
            }else if(cap.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.props.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===cap.ID_PERSONA){
                            nombre= persona.RAZON_SOCIAL
                        }
                    })
              
            }
            capital[index].nombre=nombre

            //obtencion de las variaciones         

            for(let variacion of variaciones){   

                // await this.props.client.query({
                //     query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION},ID_DISTRIBUCION:${cap.ID_DISTRIBUCION}}){VARIACION,NUMERACION_TITULOS}}`,fetchPolicy:'network-only'
                // }).then(async result=>{
                    
                    for(let vari of variacDist){
                        if(vari.ID_VARIACION===variacion.ID_VARIACION && vari.ID_DISTRIBUCION===cap.ID_DISTRIBUCION){
                            console.log(vari)
                        cap.NUMERO_TITULOS+=vari.VARIACION
                        if(vari.VARIACION>0){

                            let numerosActuales= this.convertirNumerosArray(cap.NUMERACION_TITULOS)
                            let numerosAndadir=this.convertirNumerosArray(vari.NUMERACION_TITULOS)
                            for(let num of numerosAndadir){
                                numerosActuales.push(num)
                            }
                            let numerosFinal=this.convertirArrayNumeros(numerosActuales)
                            cap.NUMERACION_TITULOS=numerosFinal

                        }else{

                            let numerosActuales=this.convertirNumerosArray(cap.NUMERACION_TITULOS)
                            let numerosQuitar= this.convertirNumerosArray(vari.NUMERACION_TITULOS)

                            //eliminamos del array de actuales los elementos del array a quitar.
                            for(let numquitar of numerosQuitar){
                                numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
                            }
                            let numerosFinal=await this.convertirArrayNumeros(numerosActuales)

                            cap.NUMERACION_TITULOS=numerosFinal

                        }

                    }    

                }
    

            } 

            //obtencion del tipo del titulo.
            let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
            let tipos=""
    
            tiposKeys.forEach(t=>{
    
              let tipoSel=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(t):tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
              if(tipos===""){
                tipos+=tipoSel.DESCRIPCION
              }else{
                
                tipos+=","+tipoSel.DESCRIPCION
    
              }
    
          })

          capital[index].tipo=tipos
    
          index++
        }
        //console.log(capital)
        this.setState({
            distribuciones:capital,
            distribucionesCopy:capital
        })

    }

    cargarTiposAccion=async()=>{
        let personas= this.props.empresaPrincipal
        // await this.props.client
        // .query({
        //     query: gql`
        //     {
        //         personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
        //     }
        //     `,
        //     fetchPolicy:'network-only'
        // }).then(result=>{ 
        //     return result.data.personasJuridicas
        // // })
        // let tiposAccion= await this.props.client.query({
        //     query:gql`{tiposAccion{
        //         ID_TIPO_ACCION,
        //         DESCRIPCION,
        //         INCOMPATIBLE,
        //     }}`
        // }).then(async(result)=>{
        //     return result.data.tiposAccion
        // })
        // let tiposPartic=  await this.props.client.query({
        //     query:gql`{tiposParticipacion{
        //         ID_TIPO_PARTICIPACION,
        //         DESCRIPCION,
        //         INCOMPATIBLE,
        //     }}`
        // }).then(async(result)=>{
        //     return result.data.tiposParticipacion
        // })
        
        personas.forEach(persona=>{
            if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
                this.setState({
                    tiposParticipacion:this.props.tiposAccion
                })
            }else{
                this.setState({
                    tiposParticipacion: this.props.tiposParticipacion
                })
            }
            this.setState({tipoCapital:persona.ID_TIPO_CAPITAL_SOCIAL})

        })  
    }

    componentDidMount=async()=>{

      this.setState({cargando:true}, async () =>{
            await this.cargarDatos()
            await this.cargarTiposAccion()
            await this.cargarTransmisiones()
            await this.cargarCapital()
            await this.cargarPersonas()
            this.setState({cargando:false})
        })
    }
    cargraImprimirTrans=async()=>{
        this.setState({
            modalImprimirTransmision:true,
            cargandoImprimirT:true,
            cargandoGrid: true
        }, async () =>{
            this.setState({cargandoImprimirT:false}, () =>{
                ReactDOM.render(this.ImprimirVariacion(), document.getElementById('generarImprimirT-pdf'))
            })
        })
    }
    ImprimirVariacion=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{this.props.i18next.t("transmisiontitulos.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.transmisiones?this.state.transmisiones.map((transmision, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                   <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("transmisiontitulos.fecha",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("transmisiontitulos.vendedor",{ns:"page"}).toUpperCase()}</Text>    
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("transmisiontitulos.comprador",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{this.props.i18next.t("transmisiontitulos.tipo",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:10, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("transmisiontitulos.numero",{ns:"page"}).toUpperCase()}</Text>                                                                
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:10, fontFamily: 'Open Sans Bold', width:'20%'}}>NUMERACIÓN DE TÍTULOS</Text>                                                                
                                                            
                             </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{transmision.FECHA?moment(transmision.FECHA).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{transmision.FK_ORIGEN?transmision.FK_ORIGEN.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{transmision.FK_DESTINO?transmision.FK_DESTINO.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{transmision.tipo?transmision.tipo:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:10,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{transmision.NUMERO_TITULOS?transmision.NUMERO_TITULOS:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:10,fontFamily: 'Open Sans',width:'20%'}}>{transmision.NUMERACION_TITULOS?transmision.NUMERACION_TITULOS:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarPersonas=async ()=>{

        let personas=[]
        this.props.personasFisicas.forEach(persona=>{
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,"tipo":persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        })
        this.props.personasJuridicas.forEach(persona=>{
            personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,"ID_PERSONA":persona.ID_PERSONA,"tipo":persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        })
       
       
        this.setState({personas:personas})
    }

    cargarDis= async (fecha) =>{
            let variaciones=await this.props.client.query({
                query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:1,FECHA:{DESDE:"1970-01-01",HASTA:"${fecha}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
            }).then(result=>{
                return result.data.capSocialVariaciones
            })
            let capSocialVar=this.state.variacionDistribucion
            //  await this.props.client.query({
            //     query:gql`{capSocialVariacionesDistribucion{ID_VARIACION,VARIACION,NUMERACION_TITULOS,ID_PERSONA,TIPO_TITULO}}`,fetchPolicy:'network-only'
            // }).then(async result=>{
            //     return result.data.capSocialVariacionesDistribucion
            // })    
            let dis=JSON.parse(JSON.stringify(this.state.distribucionesCopy))
            let trans=JSON.parse(JSON.stringify(this.state.transmisiones))
            //mostramos las modificaciones hechas por las transmisiones
            for(let transmision of trans){

                //tambien ponemos las transmisiones como distribuciones
                if(new Date(transmision.FECHA)<new Date()){
                  
                    let existe=dis.find(distribucion=>{
                        return distribucion.DESTINO===transmision.DESTINO && distribucion.TIPO_ACCION===transmision.TIPO_ACCION 
                    })

                    if(!existe){
                        dis.push(transmision)
                    }else{
                        existe.NUMERO_TITULOS+=transmision.NUMERO_TITULOS

                        let numerosActuales= this.convertirNumerosArray(existe.NUMERO_TITULOS)
                        let numerosAndadir=this.convertirNumerosArray(transmision.NUMERO_TITULOS)
                        for(let num of numerosAndadir){
                            numerosActuales.push(num)
                        }
                        let numerosFinal= this.convertirArrayNumeros(numerosActuales)
                        existe.NUMERACION_TITULOS=numerosFinal
                    }

                    let distribucion= dis.find(disMod=>{
                        return (disMod.ID_PERSONA===transmision.ORIGEN && disMod.TIPO_TITULO===transmision.TIPO_ACCION )
                    })
                    if(!distribucion){
                        distribucion=dis.find(disMod=>{
                            return (disMod.DESTINO===transmision.ORIGEN && disMod.TIPO_ACCION===transmision.TIPO_ACCION )
                        })
                    }

                    distribucion.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
                    
                    let numerosActuales= this.convertirNumerosArray(distribucion.NUMERACION_TITULOS)
                    let numerosQuitar=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)

                    //eliminamos del array de actuales los elementos del array a quitar.
                    for(let numquitar of numerosQuitar){
                        numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
                    }
                    let numerosFinal=this.convertirArrayNumeros(numerosActuales)

                    distribucion.NUMERACION_TITULOS=numerosFinal
                }else{
                    if(transmision.DESTINO===this.state.transmisionSeleccionada.ORIGEN ){
                        dis.push(transmision)
                    }

                }

                //obtencion de las variaciones 
                // let variaciones=await this.props.client.query({
                //     query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:1,FECHA:{DESDE:"1970-01-01",HASTA:"${moment().format("YYYY-MM-DD")}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
                // }).then(result=>{
                //     return result.data.capSocialVariaciones
                // })


                for(let variacion of variaciones){   

                    // await this.props.client.query({
                    //     query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){VARIACION,NUMERACION_TITULOS,ID_PERSONA,TIPO_TITULO}}`,fetchPolicy:'network-only'
                    // }).then(async result=>{
                        for(let vari of capSocialVar){
                            if(vari.ID_VARIACION===variacion.ID_VARIACION){
                                if(transmision.DESTINO===vari.ID_PERSONA && transmision.TIPO_ACCION===vari.TIPO_TITULO){
                                    transmision.NUMERO_TITULOS+=vari.VARIACION
                                    if(vari.VARIACION>0){

                                        let numerosActuales=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
                                        let numerosAndadir=this.convertirNumerosArray(vari.NUMERACION_TITULOS)
                                        for(let num of numerosAndadir){
                                            numerosActuales.push(num)
                                        }
                                        let numerosFinal=this.convertirArrayNumeros(numerosActuales)
                                        transmision.NUMERACION_TITULOS=numerosFinal
        
                                    }else{
        
                                        let numerosActuales=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
                                        let numerosQuitar=this.convertirNumerosArray(vari.NUMERACION_TITULOS)
        
                                        //eliminamos del array de actuales los elementos del array a quitar.
                                        for(let numquitar of numerosQuitar){
                                            numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
                                        }
                                        let numerosFinal=this.convertirArrayNumeros(numerosActuales)
        
                                        transmision.NUMERACION_TITULOS=numerosFinal
        
                                    }

                                }
                            } 
                        }
               

                } 

            }
            return dis
    }




    render(){

        document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("transmisiontitulos.title",{ns:"page"})
        
        const abrirModalNuevo =async () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            // let dis=JSON.parse(JSON.stringify(this.state.distribucionesCopy))
            // let trans=JSON.parse(JSON.stringify(this.state.transmisiones))
            // //mostramos las modificaciones hechas por las transmisiones
            // for(let transmision of trans){

            //     //tambien ponemos las transmisiones como distribuciones
            //     if(new Date(transmision.FECHA)<new Date()){
                  
            //         let existe=dis.find(distribucion=>{return distribucion.DESTINO===transmision.DESTINO && distribucion.TIPO_ACCION===transmision.TIPO_ACCION })

            //         if(!existe){
            //             dis.push(transmision)
            //         }else{
            //             existe.NUMERO_TITULOS+=transmision.NUMERO_TITULOS

            //             let numerosActuales=await this.convertirNumerosArray(existe.NUMERO_TITULOS)
            //             let numerosAndadir=await this.convertirNumerosArray(transmision.NUMERO_TITULOS)
            //             for(let num of numerosAndadir){
            //                 numerosActuales.push(num)
            //             }
            //             let numerosFinal=await this.convertirArrayNumeros(numerosActuales)
            //             existe.NUMERACION_TITULOS=numerosFinal
            //         }

            //         let distribucion=await dis.find(disMod=>{return (disMod.ID_PERSONA===transmision.ORIGEN && disMod.TIPO_TITULO===transmision.TIPO_ACCION )})
            //         if(!distribucion){
            //             distribucion=await dis.find(disMod=>{return (disMod.DESTINO===transmision.ORIGEN && disMod.TIPO_ACCION===transmision.TIPO_ACCION )})
            //         }

            //         distribucion.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
                    
            //         let numerosActuales=await this.convertirNumerosArray(distribucion.NUMERACION_TITULOS)
            //         let numerosQuitar=await this.convertirNumerosArray(transmision.NUMERACION_TITULOS)

            //         //eliminamos del array de actuales los elementos del array a quitar.
            //         for(let numquitar of numerosQuitar){
            //             numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
            //         }
            //         let numerosFinal=await this.convertirArrayNumeros(numerosActuales)

            //         distribucion.NUMERACION_TITULOS=numerosFinal
            //     }

            //     //obtencion de las variaciones 
            //     let variaciones=await this.props.client.query({
            //         query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:1,FECHA:{DESDE:"1970-01-01",HASTA:"${moment().format("YYYY-MM-DD")}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
            //     }).then(result=>{
            //         return result.data.capSocialVariaciones
            //     })


            //     for(let variacion of variaciones){   

            //         await this.props.client.query({
            //             query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){VARIACION,NUMERACION_TITULOS,ID_PERSONA,TIPO_TITULO}}`,fetchPolicy:'network-only'
            //         }).then(async result=>{
            //             for(let vari of result.data.capSocialVariacionesDistribucion){
            //                 if(transmision.DESTINO===vari.ID_PERSONA && transmision.TIPO_ACCION===vari.TIPO_TITULO){
            //                     transmision.NUMERO_TITULOS+=vari.VARIACION
            //                     if(vari.VARIACION>0){

            //                         let numerosActuales=await this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
            //                         let numerosAndadir=await this.convertirNumerosArray(vari.NUMERACION_TITULOS)
            //                         for(let num of numerosAndadir){
            //                             numerosActuales.push(num)
            //                         }
            //                         let numerosFinal=await this.convertirArrayNumeros(numerosActuales)
            //                         transmision.NUMERACION_TITULOS=numerosFinal
    
            //                     }else{
    
            //                         let numerosActuales=await this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
            //                         let numerosQuitar=await this.convertirNumerosArray(vari.NUMERACION_TITULOS)
    
            //                         //eliminamos del array de actuales los elementos del array a quitar.
            //                         for(let numquitar of numerosQuitar){
            //                             numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
            //                         }
            //                         let numerosFinal=await this.convertirArrayNumeros(numerosActuales)
    
            //                         transmision.NUMERACION_TITULOS=numerosFinal
    
            //                     }

            //                 }
            //             }
            //         })

            //     } 

            // }
            let dis= await this.cargarDis(moment().format("YYYY-MM-DD"))
            console.log(dis)

            this.setState({
                nuevaTransmisionActivado:true,
                editarTransmisionActivado:false,
                borrarTransmisionActivado:false,
                fechaSeleccionada:new Date(),
                distribuciones:dis,
                maxTitulos:1,
                valoresATransferir:1,
                personaSeleccionada:null,
                distribucionesSeleccionada:null,
                modalNuevoAbierto: true,
                transmisionSeleccionada:null,
                cargandoGrid:true
            })
        }

        
        const handleClick1 = () => {
            this.setState({ abierto1: !this.state.abierto1, abierto2: false, abierto3: false, abierto4: false, abierto5: false, abierto6: false, abierto7: false, abierto8: false, abierto9: false });
        }
        
        const handleClick2 = () => {
            this.setState({ abierto1: false, abierto2: !this.state.abierto2, abierto3: false, abierto4: false, abierto5: false, abierto6: false, abierto7: false, abierto8: false, abierto9: false });
        }

        const handleClick3 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: !this.state.abierto3, abierto4: false, abierto5: false, abierto6: false, abierto7: false, abierto8: false, abierto9: false });
        }
        
        const handleClick4 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: !this.state.abierto4, abierto5: false, abierto6: false, abierto7: false, abierto8: false, abierto9: false });
        }

        const handleClick5 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: false, abierto5: !this.state.abierto5, abierto6: false, abierto7: false, abierto8: false, abierto9: false });
        }
        
        const handleClick6 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: false, abierto5: false, abierto6: !this.state.abierto6, abierto7: false, abierto8: false, abierto9: false });
        }
        const handleClick7 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: false, abierto5: false, abierto6: false, abierto7: !this.state.abierto7, abierto8: false, abierto9: false });
        }
        
        const handleClick8 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: false, abierto5: false, abierto6: false, abierto7: false, abierto8: !this.state.abierto8, abierto9: false });
        }
        
        const handleClick9 = () => {
            this.setState({ abierto1: false, abierto2: false, abierto3: false, abierto4: false, abierto5: false, abierto6: false, abierto7: false, abierto8: false, abierto9: !this.state.abierto9 });
        }


        const abrirModalEditar = async() => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.transmisionSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            // let dis=JSON.parse(JSON.stringify(this.state.distribucionesCopy))
            // let trans=JSON.parse(JSON.stringify(this.state.transmisiones))
            // //mostramos las modificaciones hechas por las transmisiones
            // for(let transmision of trans){
            //     if(new Date(transmision.FECHA)<new Date(this.state.transmisionSeleccionada.FECHA)){
            //         console.log(transmision)

                    
            //         let existe=dis.find(distribucion=>{return distribucion.DESTINO===transmision.DESTINO && distribucion.TIPO_ACCION===transmision.TIPO_ACCION })
            //         //tambien ponemos las transmisiones como distribuciones
            //         if(!existe){
            //             dis.push(transmision)
            //         }else{
            //             existe.NUMERO_TITULOS+=transmision.NUMERO_TITULOS

            //             let numerosActuales= this.convertirNumerosArray(existe.NUMERO_TITULOS)
            //             let numerosAndadir= this.convertirNumerosArray(transmision.NUMERO_TITULOS)
            //             for(let num of numerosAndadir){
            //                 numerosActuales.push(num)
            //             }
            //             let numerosFinal= this.convertirArrayNumeros(numerosActuales)
            //             existe.NUMERACION_TITULOS=numerosFinal

            //         }

            //         let distribucion=dis.find(disMod=>{return (disMod.ID_PERSONA===transmision.ORIGEN && disMod.TIPO_TITULO===transmision.TIPO_ACCION )})
            //         if(!distribucion)distribucion=dis.find(disMod=>{return (disMod.DESTINO===transmision.ORIGEN && disMod.TIPO_ACCION===transmision.TIPO_ACCION )})

            //         distribucion.NUMERO_TITULOS-=transmision.NUMERO_TITULOS

            //         console.log(distribucion.NUMERACION_TITULOS)
            //         console.log(transmision.NUMERACION_TITULOS)
            //         let numerosActuales=this.convertirNumerosArray(distribucion.NUMERACION_TITULOS)
            //         let numerosQuitar=this.convertirNumerosArray(transmision.NUMERACION_TITULOS)

            //         //eliminamos del array de actuales los elementos del array a quitar.
            //         for(let numquitar of numerosQuitar){
            //             numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
            //         }
            //         let numerosFinal=this.convertirArrayNumeros(numerosActuales)
                
            //         distribucion.NUMERACION_TITULOS=numerosFinal
                    


            //     }else{
            //         if(transmision.DESTINO===this.state.transmisionSeleccionada.ORIGEN ){
            //             dis.push(transmision)
            //         }
            //     }

            //     //obtencion de las variaciones 
            //     let variaciones=await this.props.client.query({
            //         query:gql`{capSocialVariaciones(FILTROS:{ID_TIPO_VARIACION:1,FECHA:{DESDE:"1970-01-01",HASTA:"${this.state.transmisionSeleccionada.FECHA}"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
            //     }).then(result=>{
            //         return result.data.capSocialVariaciones
            //     })
                
            //     for(let variacion of variaciones){   

            //         await this.props.client.query({
            //             query:gql`{capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){VARIACION,NUMERACION_TITULOS,ID_PERSONA,TIPO_TITULO}}`,fetchPolicy:'network-only'
            //         }).then(async result=>{
            //             for(let vari of result.data.capSocialVariacionesDistribucion){
            //                 if(transmision.DESTINO===vari.ID_PERSONA && transmision.TIPO_ACCION===vari.TIPO_TITULO){
            //                     transmision.NUMERO_TITULOS+=vari.VARIACION
            //                     if(vari.VARIACION>0){

            //                         let numerosActuales= this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
            //                         let numerosAndadir= this.convertirNumerosArray(vari.NUMERACION_TITULOS)
            //                         for(let num of numerosAndadir){
            //                             numerosActuales.push(num)
            //                         }
            //                         let numerosFinal= this.convertirArrayNumeros(numerosActuales)
            //                         transmision.NUMERACION_TITULOS=numerosFinal
    
            //                     }else{
    
            //                         let numerosActuales= this.convertirNumerosArray(transmision.NUMERACION_TITULOS)
            //                         let numerosQuitar=this.convertirNumerosArray(vari.NUMERACION_TITULOS)
    
            //                         //eliminamos del array de actuales los elementos del array a quitar.
            //                         for(let numquitar of numerosQuitar){
            //                             numerosActuales=numerosActuales.filter(el=>{return el!==numquitar})
            //                         }
            //                         let numerosFinal=this.convertirArrayNumeros(numerosActuales)
    
            //                         transmision.NUMERACION_TITULOS=numerosFinal
    
            //                     }
                                
            //                 }
            //             }
            //         })

            //     } 
                
            // }      
            let dis= await this.cargarDis(this.state.transmisionSeleccionada.FECHA)
            //console.log(dis)
            let personaSel=this.state.personas.find(persona=>{
                return persona.value===this.state.transmisionSeleccionada.DESTINO
            })
            let distribucion=dis.find((disMod,index)=>{
                return (disMod.ID_PERSONA===this.state.transmisionSeleccionada.ORIGEN && disMod.TIPO_TITULO===this.state.transmisionSeleccionada.TIPO_ACCION )
            })
            if(!distribucion){
                distribucion=dis.find((disMod,index)=>{
                    return (disMod.DESTINO===this.state.transmisionSeleccionada.ORIGEN)
                })
            }
            //console.log(dis)
            //console.log(distribucion)

            this.setState({
                nuevaTransmisionActivado:false,
                editarTransmisionActivado:true,
                borrarTransmisionActivado:false,
                distribuciones:dis,
                modalNuevoAbierto: true,
                valoresATransferir:this.state.transmisionSeleccionada.NUMERO_TITULOS,
                maxTitulos:distribucion.NUMERO_TITULOS,
                personaSeleccionada:personaSel,
                distribucionesSeleccionada:distribucion,
                fechaSeleccionada:new Date(this.state.transmisionSeleccionada.FECHA),
                cargandoGrid:true
            })

         
      
        }

     
        const abrirModalBorrar = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.transmisionSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                nuevaTransmisionActivado:false,
                editarTransmisionActivado:false,
                borrarTransmisionActivado:true,
                modalBorrarTransmision:true,
                cargandoGrid:true
            })
        }


        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const escogeFecha = (nuevaFecha) => {
            this.setState({fechaSeleccionada: nuevaFecha})
        }

        function vendedorFormatter(cell, row) {
        return (
            (cell) ? cell : row.FK_DESTINO.nombre
        );
        }

        const columns = [
        {
        dataField: 'nombre',
        text: this.props.i18next.t("transmisiontitulos.vendedor",{ns:"page"}),
        formatter: vendedorFormatter
        },
        {
            dataField: 'tipo',
            text: this.props.i18next.t("transmisiontitulos.tipo",{ns:"page"})
            },
            {
                dataField: 'NUMERO_TITULOS',
                text: this.props.i18next.t("transmisiontitulos.numero",{ns:"page"})
                },
                {
                    dataField: 'NUMERACION_TITULOS',
                    text: this.props.i18next.t("transmisiontitulos.numeracion",{ns:"page"})
                    },
        ];

        function dateFormatter(cell, row) {
        return (
        moment(cell).format("DD/MM/YYYY")
        );
        }

        function vendedorFormatter2(cell, row) {
            return (
                (cell) ? cell.nombre : ""
            );
            }

        const columns2 = [
        {
        dataField: 'FECHA',
        text: this.props.i18next.t("transmisiontitulos.fecha",{ns:"page"}),
        formatter: dateFormatter
        },{
            dataField: 'FK_ORIGEN',
            text: this.props.i18next.t("transmisiontitulos.vendedor",{ns:"page"}),
            formatter: vendedorFormatter2
            },{
                dataField: 'FK_DESTINO',
                text: this.props.i18next.t("transmisiontitulos.comprador",{ns:"page"}),
                formatter: vendedorFormatter2
                },{
                    dataField: 'tipo',
                    text: this.props.i18next.t("transmisiontitulos.tipo",{ns:"page"})
                    },{
                        dataField: 'NUMERO_TITULOS',
                        text: this.props.i18next.t("transmisiontitulos.numero",{ns:"page"})
                        },{
                            dataField: 'NUMERACION_TITULOS',
                            text: this.props.i18next.t("transmisiontitulos.numeracion",{ns:"page"})
                            }
        ];

        const guardarCambios = async() => {

            if(!this.state.distribucionesSeleccionada){
                return
            }

            if(!this.state.personaSeleccionada){
                return
            }

            if(this.state.personaSeleccionada.value===this.state.distribucionesSeleccionada.ID_PERSONA || this.state.personaSeleccionada.value===this.state.distribucionesSeleccionada.DESTINO ){
                alert(this.props.i18next.t("transmisiontitulos.valid1",{ns:"page"}))
                return
            }

            if(this.state.distribucionesSeleccionada.NUMERO_TITULOS===0){
                alert(this.props.i18next.t("transmisiontitulos.valid2",{ns:"page"}))
                return
            }
            let numerosAndadir=await this.convertirNumerosArray(this.state.distribucionesSeleccionada.NUMERACION_TITULOS)
            let restados=numerosAndadir.splice((numerosAndadir.length)-this.state.valoresATransferir,this.state.valoresATransferir)
            let numeracionFinal=this.convertirArrayNumeros(restados)
            //insertamos la transmision
            this.props.client.mutate({
                mutation:gql`mutation{crearCapSocialTransmisiones(VALORES:{
                    FECHA:"${moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")}",
                    ORIGEN:${this.state.distribucionesSeleccionada.ID_PERSONA?this.state.distribucionesSeleccionada.ID_PERSONA:this.state.distribucionesSeleccionada.DESTINO},
                    DESTINO:${this.state.personaSeleccionada.value},
                    TIPO_ACCION:${this.state.distribucionesSeleccionada.TIPO_TITULO?this.state.distribucionesSeleccionada.TIPO_TITULO:this.state.distribucionesSeleccionada.TIPO_ACCION},
                    NUMERO_TITULOS:${this.state.valoresATransferir},
                    NUMERACION_TITULOS:"${numeracionFinal}"
                })
                {ID_TRANSMISION}}`
            }).then(async result=>{
              
                    alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                    await this.cargarTransmisiones()
                    
                    
                    this.setState({
                        modalNuevoAbierto:false,
                        transmisionSeleccionada:null,
                        cargandoGrid:false
                    })


            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })



        }

        const actualizarTransmision=async()=>{

            if(!this.state.distribucionesSeleccionada){
                return
            }

            if(!this.state.personaSeleccionada){
                return
            }

            if(this.state.personaSeleccionada.value===this.state.distribucionesSeleccionada.ID_PERSONA || this.state.personaSeleccionada.value===this.state.distribucionesSeleccionada.DESTINO){
                alert(this.props.i18next.t("transmisiontitulos.valid1",{ns:"page"}))
                return
            }

            if(this.state.distribucionesSeleccionada.NUMERO_TITULOS===0){
                alert(this.props.i18next.t("transmisiontitulos.valid2",{ns:"page"}))
                return
            }

            let numerosAndadir=await this.convertirNumerosArray(this.state.distribucionesSeleccionada.NUMERACION_TITULOS)
            let restados=numerosAndadir.splice((numerosAndadir.length)-this.state.valoresATransferir,this.state.valoresATransferir)
            let numeracionFinal=this.convertirArrayNumeros(restados)

            this.props.client.mutate({
                mutation:gql`mutation{actualizarCapSocialTransmisiones(
                ID_TRANSMISION:${this.state.transmisionSeleccionada.ID_TRANSMISION},
                VALORES:{
                    FECHA:"${moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")}",
                    ORIGEN:${this.state.distribucionesSeleccionada.ID_PERSONA?this.state.distribucionesSeleccionada.ID_PERSONA:this.state.distribucionesSeleccionada.DESTINO},
                    DESTINO:${this.state.personaSeleccionada.value},
                    TIPO_ACCION:${this.state.distribucionesSeleccionada.TIPO_TITULO?this.state.distribucionesSeleccionada.TIPO_TITULO:this.state.distribucionesSeleccionada.TIPO_ACCION},
                    NUMERO_TITULOS:${this.state.valoresATransferir},
                    NUMERACION_TITULOS:"${numeracionFinal}"})
                {ID_TRANSMISION}}`
            }).then(async result=>{
              
                    alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                    await this.cargarTransmisiones()

                    
                    this.setState({
                        modalNuevoAbierto:false,
                        transmisionSeleccionada:null,
                        cargandoGrid:false
                    })


            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
            
        }
        const cerrarModalImprimir=()=>{
            this.setState({modalImprimirTransmision:false,cargandoGrid:false,transmisionSeleccionada:null})
        }
        
        const cerrarModal = () => {
            this.setState({ 
                modalNuevoAbierto: false,
                modalBorrarTransmision:false,
                cargandoGrid:false,transmisionSeleccionada:null
                
            })
        }

        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({personaSeleccionada:null})
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }

        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={this.props.i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={this.props.i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false,
                modalSectorAbierto: false,
            })
        }

        const guardarPersonaJuridica=async ()=>{

            
             
 
            

            let result=await this.informacion.handleGuardarDatos() 
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        
        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModal2();
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
                //cerrar el modal
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                //cerrar el modal
                cerrarModal2();
            }
        };

        const handleOnSelect2=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            transmisionSeleccionada: row
        });
        }
        return true;
        }

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            distribucionesSeleccionada: row,
            maxTitulos: row.NUMERO_TITULOS,
            valoresATransferir:1
        });
        }
        return true;
        }

        
        const cambiarValor=(ev)=>{
            this.setState({valoresATransferir:ev.currentTarget.value})
        }

        const eliminarTransmision=async()=>{


            let variacionesDistribucion=await this.props.client.query({
                query:gql`{capSocialVariacionesDistribucion(CAMPOS:"ID_VARIACION,TIPO_TITULO,ID_PERSONA",FILTROS:{ID_DISTRIBUCION:null,ID_PERSONA:${this.state.transmisionSeleccionada.DESTINO},TIPO_TITULO:${this.state.transmisionSeleccionada.TIPO_ACCION}}){ID_VARIACION,ID_PERSONA,TIPO_TITULO}}`
                ,fetchPolicy:'network-only'
            }).then(result=>{
                //console.log(result)
                return result.data.capSocialVariacionesDistribucion
            })
            
            for( let variacion of variacionesDistribucion){

                 //obtenemos las variaciones de la transmision.
                await this.props.client.mutate({
                    mutation:gql`mutation{eliminarCapSocialVariacionesDistribucion(ID_PERSONA:${variacion.ID_PERSONA},TIPO_TITULO:${variacion.TIPO_TITULO}){ID_VARIACION}}`
                // eslint-disable-next-line no-loop-func
                }).then(async result=>{

                        //si la variacion contiene 0 variaciones distribucion, tambien la eliminamos
                        variacionesDistribucion=await this.props.client.query({
                            query:gql`{capSocialVariacionesDistribucion(CAMPOS:"TIPO_TITULO,ID_PERSONA",FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_PERSONA,TIPO_TITULO}}`
                            ,fetchPolicy:'network-only'
                        }).then(async result=>{

                            if(result.data.capSocialVariacionesDistribucion.length===0){
                                await this.props.client.mutate({
                                    mutation:gql`mutation{eliminarCapSocialVariacion(ID_VARIACION:${variacion.ID_VARIACION}){ID_VARIACION}}`
                                }).catch(err=>{
                                    alert(this.props.i18next.t("error",{ns:"global"}))
                                    return
                                })
                            }
                        })

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                })

            
            }           

            await this.props.client.mutate({
                mutation:gql`mutation{eliminarCapSocialTransmisiones(ID_TRANSMISION:${this.state.transmisionSeleccionada.ID_TRANSMISION})
                {ID_TRANSMISION}}`
            }).then(async result=>{
              
                    alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({
                        modalBorrarTransmision:false,
                        transmisionSeleccionada:null,
                        cargandoGrid:false
                    })
                    this.cargarTransmisiones()

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })


        }

        return (
            <>
            {this.state.cargando === true?
                <Cargando />
                :
                <>
                     <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="print"
                        onClose={cerrarModalImprimir}
                        title={this.props.i18next.t("print",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalImprimirTransmision}
                        style={styles.dialog}
                    >
                        {!this.state.cargandoImprimirT?
                                <div style={{ width: '100%', height: '600px'}} id='generarImprimirT-pdf'>
                                </div>
                                :
                                    <Spinner />
                            }

                </Dialog>
                    <Dialog 
                        transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="info-sign"
                        onClose={cerrarModal}
                        title={this.props.i18next.t("transmisiontitulos.title",{ns:"page"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalNuevoAbierto}
                        onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                        className="dialog-ancho"
                    >  
                        <div id="modal-nueva-transmision" className="pixelAlrededorGrande">
                            <Card interactive={true} elevation={Elevation.TWO} id="vista-general">
                                <FormGroup
                                    label={this.props.i18next.t("transmisiontitulos.fechatransmision",{ns:"page"})+":"}
                                    labelFor="fecha"
                                >
                                    <div id="fecha-transmision-titulos">
                                        <DateInput minDate={new Date(1900, 1 ,1)}
                                            {...jsDateFormatter} 
                                            onChange={escogeFecha}
                                            defaultValue={new Date()}
                                            align={Alignment.RIGHT}
                                            value={this.state.fechaSeleccionada}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label={this.props.i18next.t("transmisiontitulos.selectvendedor",{ns:"page"})+":"}
                                    labelFor="vendedor"
                                >
                                <div id="tabla-socios" style={!this.state.distribucionesSeleccionada?{border:"1px solid red"}:{}}>
                                <BootstrapTable
                                keyField='ID_DISTRIBUCION'
                                data={ this.state.distribuciones }
                                columns={ columns }
                                selectRow={ const_table.selectRow(handleOnSelect) }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                                />
                                </div>
                                </FormGroup>
                                <FormGroup
                                    label={this.props.i18next.t("transmisiontitulos.comprador",{ns:"page"})+":"}
                                    labelFor="comprador"
                                    intent="danger"
                                    helperText={!this.state.personaSeleccionada?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("transmisiontitulos.comprador",{ns:"page"}):""}
                                >
                                    <div id="select-comprador" style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                                        <Select
                                            options={this.state.personas}
                                            className="pixelAlrededor"
                                            onChange={seleccionPersona}
                                            value={this.state.personaSeleccionada}
                                            isDisabled={this.state.editarActivado}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Popover  disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button id="nuevo-socio"  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                                    <Button className="bp3-minimal" icon="edit" id="editar-socio" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                                </FormGroup> 
                                <FormGroup
                                    label={this.props.i18next.t("transmisiontitulos.indiquenumero",{ns:"page"})+":"}
                                    labelFor="comprador"
                                >
                                    <InputGroup id="numero-titulos-transmitir" type="number" min={1} max={this.state.maxTitulos} onChange={cambiarValor} value={this.state.valoresATransferir} />
                                </FormGroup>

                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                            <Button onClick={cerrarModal}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                                        </Tooltip>
                                        <Tooltip content={this.state.nuevaTransmisionActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                                            <AnchorButton
                                                id="guardar-transmision"
                                                intent={Intent.PRIMARY}
                                                onClick={this.state.nuevaTransmisionActivado?guardarCambios:actualizarTransmision}
                                            >
                                            {this.state.nuevaTransmisionActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})}
                                            </AnchorButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEditarPersonaJuridica}
                    className="datos-empresa-dialog"
                >
                <DatosPersonasJuridicas
                    animacion={this.animacion}
                    cambiarPestaña={this.cambiarPestaña}
                    pestaña={this.state.pestaña}
                    i18next={this.props.i18next}
                    empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
                />
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                    <ButtonGroup>
                        <Button 
                            alignText="right"
                            icon="log-out"
                            intent="danger"
                            text={this.props.i18next.t("close",{ns:"global"})}
                            onClick={cerrarModal2}
                        />
                    </ButtonGroup>
                </div>
            </Dialog>
            <Dialog 
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalNuevaJuridicaAbierto}
                className="datos-empresa-dialog"
            >
                    <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={this.props.i18next}/>  
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={this.props.i18next.t("cancelar",{ns:"global"})} position="top">          
                        <Button
                            alignText="left"
                            icon="cross"
                            text={this.props.i18next.t("cancelar",{ns:"global"})}
                            intent="danger"
                            onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                    <Button
                        alignText="left"
                        icon="floppy-disk"
                        text={this.props.i18next.t("guardar",{ns:"global"})}
                        intent="danger"
                        onClick={guardarPersonaJuridica}
                        className="iconoIzquierda bp3-minimal"
                    />
                    </Tooltip>
                </div>
            </div>  
            </Dialog>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalNuevaJuridicaAbierto}
                className="datos-empresa-dialog"
            >
                    <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={this.props.i18next}/>  
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={this.props.i18next.t("cancelar",{ns:"global"})} position="top">          
                        <Button
                            alignText="left"
                            icon="cross"
                            text={this.props.i18next.t("cancelar",{ns:"global"})}
                            intent="danger"
                            onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                    <Button
                        alignText="left"
                        icon="floppy-disk"
                        text={this.props.i18next.t("guardar",{ns:"global"})}
                        intent="danger"
                        onClick={guardarPersonaJuridica}
                        className="iconoIzquierda bp3-minimal"
                    />
                    </Tooltip>
                </div>
            </div>  
            </Dialog>
            <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="warning-sign"
                onClose={cerrarModal}
                title={this.props.i18next.t("delete",{ns:"global"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalBorrarTransmision}
            >
                <div className={Classes.DIALOG_BODY}>
                    <h2>{this.props.i18next.t("transmisiontitulos.questiondel",{ns:"page"})}</h2>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={this.props.i18next.t("delete",{ns:"global"})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={eliminarTransmision}
                        >
                            {this.props.i18next.t("yes",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
            <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            className="dialog-ancho"
            isOpen={this.state.modalPersonaAbierto}
            >
            <DatosPersonasFisicas
                datosPersona={
                this.state.personaSeleccionada
                    ? this.state.personaSeleccionada.value
                    : null
                }
                ref={input => {
                this.datosPersona = input;
                }}
                i18next={this.props.i18next}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                    <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                </Tooltip>
                <Tooltip
                    content={
                    this.state.nuevaPresonaActivado
                        ? this.props.i18next.t("agregar",{ns:"global"})
                        : this.props.i18next.t("edit",{ns:"global"})
                    }
                    position="top"
                >
                    <AnchorButton
                    intent={Intent.PRIMARY}
                    onClick={
                        this.state.nuevaPresonaActivado
                        ? crearPersona
                        : actualizarPersona
                    }
                    >
                    {this.state.nuevaPresonaActivado
                        ? this.props.i18next.t("agregar",{ns:"global"})
                        : this.props.i18next.t("edit",{ns:"global"})}
                    </AnchorButton>
                </Tooltip>
                </div>
            </div>
            </Dialog>
            <div className="pixelAlrededor tablaSesenta">
            <Card interactive={true} elevation={Elevation.TWO} id="vista-general">
                <br />
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("transmisiontitulos.title",{ns:"page"})}</Callout>
                <br />
                <Navbar>
                    <Navbar.Group>
                        <ButtonGroup align={Alignment.LEFT}>
                            <Button className="bp3-minimal" icon="add" id="boton-nueva-transmision" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevaTransmisionActivado} disabled={this.state.cargando} onClick={abrirModalNuevo} />
                            <Button className="bp3-minimal" icon="edit" id="boton-editar-transmision" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarTransmisionActivado} disabled={this.state.cargando} onClick={abrirModalEditar} />
                            <Button className="bp3-minimal" icon="delete" id="boton-borrar-transmision" text={this.props.i18next.t("delete",{ns:"global"})} active={this.state.borrarTransmisionActivado} disabled={this.state.cargando} onClick={abrirModalBorrar}/>                            
                            <Button className="bp3-minimal" icon="print" id="boton-imprimir-transmision" text={this.props.i18next.t("print",{ns:"global"})} active={this.state.imprimirTransmisionActivado} disabled={this.state.cargando} onClick={()=>this.cargraImprimirTrans()}/>                            

                        </ButtonGroup>
                    </Navbar.Group>
                </Navbar>
                {this.state.cargandoGrid?
                    <Cargando/>
                :
                <div id="tabla-transmisiones">
                    <BootstrapTable
                    keyField='ID_TRANSMISION'
                    data={ this.state.transmisiones }
                    columns={ columns2 }
                    selectRow={ const_table.selectRow(handleOnSelect2) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
                    />
                </div>
                }
            </Card>
            </div>
            <div className="pixelAlrededor tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("transmisiontitulos.titleley",{ns:"page"})}</Callout>
                <br />
                    <div>
                    <div className="tablaMitadIzquierda">
                        <Button icon="git-repo" onClick={handleClick1}>
                            {this.props.i18next.t("transmisiontitulos.articulo104",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadDerecha">
                        <Button icon="git-repo" onClick={handleClick2}>
                            {this.props.i18next.t("transmisiontitulos.articulo105",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <Button icon="git-repo" onClick={handleClick3}>
                            {this.props.i18next.t("transmisiontitulos.articulo106",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadDerecha">
                        <Button icon="git-repo" onClick={handleClick4}>
                            {this.props.i18next.t("transmisiontitulos.articulo107",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <Button icon="git-repo" onClick={handleClick5}>
                            {this.props.i18next.t("transmisiontitulos.articulo108",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadDerecha">
                        <Button icon="git-repo" onClick={handleClick6}>
                            {this.props.i18next.t("transmisiontitulos.articulo109",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <Button icon="git-repo" onClick={handleClick7}>
                            {this.props.i18next.t("transmisiontitulos.articulo110",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadDerecha">
                        <Button icon="git-repo" onClick={handleClick8}>
                            {this.props.i18next.t("transmisiontitulos.articulo111",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitadIzquierda">
                        <Button icon="git-repo" onClick={handleClick9}>
                            {this.props.i18next.t("transmisiontitulos.articulo112",{ns:"page"})}
                        </Button>
                    </div>
                    <div className="tablaMitad">
                        <p></p>
                    </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    </Card>

                    <div>
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Collapse isOpen={this.state.abierto1}>
                            <Card> 
                                <p>{this.props.i18next.t("transmisiontitulos.desc104",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto2}>
                            <Card>
                                <p>{this.props.i18next.t("transmisiontitulos.desc105",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto3}>
                            <Card> 
                                <p>{this.props.i18next.t("transmisiontitulos.desc106",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto4}>
                            <Card>
                                <p>{this.props.i18next.t("transmisiontitulos.desc107",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto5}>
                            <Card> 
                                <p>{this.props.i18next.t("transmisiontitulos.desc108",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto6}>
                            <Card>
                                <p>{this.props.i18next.t("transmisiontitulos.desc109",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto7}>
                            <Card> 
                                <p>{this.props.i18next.t("transmisiontitulos.desc110",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto8}>
                            <Card>
                                <p>{this.props.i18next.t("transmisiontitulos.desc111",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                        <Collapse isOpen={this.state.abierto9}>
                            <Card>
                                <p>{this.props.i18next.t("transmisiontitulos.desc112",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                    </Card>
                    </div>
                </div>
            </>
            }
            </>
        )
    }
}export default withApollo(TransmisionTitulos)