import React from "react"
import { FormGroup, Elevation, Intent,AnchorButton,ButtonGroup ,Classes,Dialog,Popover,TextArea, Collapse, Card, Button,Tooltip } from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime"
import Select from "react-select"
import gql from "graphql-tag";
import InformacionGeneralNuevo from "../../Pantallas/Elementos/InformacionGeneralNuevo"
import { DatosPersonasJuridicas } from "../DatosPersonasJuridicas";
import ApolloClient from "apollo-boost";
import { DatosPersonasFisicas } from "../Elementos/DatosPersonasFisicas"
import moment from 'moment';

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export default class PropiedadesAdministrador extends React.Component {

    state={
        abierto: false,
        administradores: [
        ],
        cargoSeleccionado:null,
        adminstradorSeleccionado:null,
        representanteSeleccionado:null,
        cargos:[
            {value:0,label:"Ninguno"},
            {value:1,label:"Presidente"},
            {value:2,label:"Secrectario"}
        ],
        servicios:null,
        modalEditarPersonaJuridica:null,
        editarActivado:false,
        editando:false,
        modalPersonaAbierto:null,
        nuevaPersonaActivado:true,
        editarPersonaActivado:false,
        fechaNombramiento: new Date(),
    }


    cargarPersonas=async()=>{
        
        let administradores=[]
        let representantes=[]

        await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{ID_TIPO_PERSONA}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            //console.log(result)
            result.data.personasFisicas.forEach((persona)=>{
             
                administradores.push({"tipo":persona.PERSONA.ID_TIPO_PERSONA,"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`}) 
                representantes.push({"tipo":persona.PERSONA.ID_TIPO_PERSONA,"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":` ${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""},${persona.NOMBRE}`}) 

            })
            
        })

        this.setState({representantes:representantes})

        await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL,EMPRESA_PRINCIPAL,PERSONA{ID_TIPO_PERSONA}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            //console.log(result)
            result.data.personasJuridicas.forEach((persona)=>{
             
                administradores.push({"tipo":persona.PERSONA.ID_TIPO_PERSONA,"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,"esPrincipal":persona.EMPRESA_PRINCIPAL}) 

            })

        })

        this.setState({administradores:administradores})

        if(this.props.datos && Object.keys(this.props.datos).length >0){
            this.setState({editando:true})
            let adminSel=this.state.administradores.find(admin=>{return admin.value===this.props.datos.ID_PERSONA})
            this.setState({adminstradorSeleccionado:adminSel})

            let representanteSel=this.state.representantes.find(rep=>{return rep.value===this.props.datos.ID_REPRESENTANTE})
            this.setState({representanteSeleccionado:representanteSel})

            if(this.props.datos.PRESIDENTE || this.props.datos.SECRETARIO){
                let cargoSel=this.state.cargos.find(cargo=>{return cargo.value===(this.props.datos.PRESIDENTE?1:2)})
                this.setState({cargoSeleccionado:cargoSel})
            }

            this.setState({servicios:this.props.datos.PRESTACION_SERVICIOS})

        }else{
            this.setState({editando:false})
        }

    }

    componentDidMount(props){

        this.cargarPersonas()
    }




    render() {

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

        const handleClick = () => {
            this.setState({ abierto: !this.state.abierto });
        }

        const seleccionAdministrador  = (value) => { 
            this.setState({adminstradorSeleccionado: value})
            if(value.tipo!==2)this.setState({representanteSeleccionado:null})
        }

        const seleccionRepresentante  = (value) => { 
            this.setState({representanteSeleccionado: value})
        }

        const seleccionCargo  = (value) => { 
            this.setState({cargoSeleccionado: value})
        }

        
        const cambiarServicios  = (ev) => { 
            this.setState({servicios: ev.currentTarget.value})
        }

        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={this.props.i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={this.props.i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const crearPersona=async()=>{

            await this.datosPersona.crearPersona()
            if(this.datosPersona.state.idPersonaCreada){
                await this.cargarPersonas()
                //seleccionar empleado creado
                let personaSel=this.state.administradores.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({adminstradorSeleccionado:personaSel})
                //cerrar el modal
                cerrarModal2()
            }

        }

        const actualizarPersona=async ()=>{

            await this.datosPersona.actualizarPersona()
            if(this.datosPersona.state.idPersonaActualizada){
                await this.cargarPersonas()
                
                //seleccionar empleado creado
                let personaSel=this.state.administradores.find(persona=>{return persona.value===this.state.adminstradorSeleccionado.value})
                this.setState({adminstradorSeleccionado:personaSel})
                //recargamos la tabla de miembros
                this.props.cargarMiembros()
                //cerrar el modal
                this.setState({modalPersonaAbierto:false})
            }

        }

      

        const abrirModalEditarPersona=()=>{

            if(!this.state.adminstradorSeleccionado){
                alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.adminstradorSeleccionado.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.adminstradorSeleccionado.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const cerrarModal=()=>{
            this.setState({modalNuevaJuridicaAbierto:false})
            this.setState({modalEditarPersonaJuridica:false})
        }

        const guardarPersonaJuridica=async ()=>{
    
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.administradores.find(administrador=>{return administrador.value===this.informacion.state.personaCreada})
            this.setState({adminstradorSeleccionado:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.adminstradorSeleccionado && this.state.adminstradorSeleccionado.tipo===2){

                let personaSel=this.state.administradores.find(persona=>{return persona.value===this.state.adminstradorSeleccionado.value})
                this.setState({adminstradorSeleccionado:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
                await this.props.cargarMiembros()

            }
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false
            })
        }

        const escogeFecha = (nuevaFecha) => {
            this.setState({ fechaNombramiento: nuevaFecha })
        }
        
        return(
            <>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevaJuridicaAbierto}
                    className="datos-empresa-dialog"
                >
                        <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={this.props.i18next}/>  
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text={this.props.i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                        </Tooltip>
                    </div>
                </div>  
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas i18next={this.props.i18next} datosPersona={this.state.adminstradorSeleccionado?this.state.adminstradorSeleccionado.value:null} ref={(input) => { this.datosPersona= input; }} />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevaPersonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevaPersonaActivado?crearPersona:actualizarPersona} 
                            >
                            {this.state.nuevaPersonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} 
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                i18next={this.props.i18next}
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.adminstradorSeleccionado?this.state.adminstradorSeleccionado:null}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text={this.props.i18next.t("close",{ns:"global"})}
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
                

                <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededorGrande" style={{margin: 5}}>
                <FormGroup
                            label={this.props.i18next.t("organoadministracion.nameadministrador",{ns:"page"})+":"}
                            labelFor="nombre-adminstrador"
                            intent="danger"
                            helperText={!this.state.adminstradorSeleccionado?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("organoadministracion.nameadministrador",{ns:"page"}):""}
                >
                    <div style={!this.state.adminstradorSeleccionado?{border:"1px solid red"}:{}}> 
                        <Select
                            isDisabled={this.props.datos && Object.keys(this.props.datos).length >0?true:false}
                            options={this.state.administradores}
                            className="pixelAlrededor"
                            onChange={seleccionAdministrador}
                            ref={(input) => { this.selecAdministrador= input; }}
                            value={this.state.adminstradorSeleccionado}
                         />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Popover  disabled={this.state.editando} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editando} className="bp3-minimal" icon="add" text={this.props.i18next.t("new",{ns:"global"})} active={this.state.nuevaPersonaActivado} onClick={abrirPopover} />} />
                    <Button className="bp3-minimal" icon="edit" text={this.props.i18next.t("edit",{ns:"global"})} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                </FormGroup>                        
                <FormGroup
                        label={this.props.i18next.t("organoadministracion.representante",{ns:"page"})+":"}
                        labelFor="representante"
                        intent="danger"
                        helperText={this.state.adminstradorSeleccionado?(this.state.adminstradorSeleccionado.tipo===2 && !this.state.representanteSeleccionado?this.props.i18next.t("selectempty",{ns:"global"})+this.props.i18next.t("organoadministracion.representante",{ns:"page"}):""):""}
                >
                    <div style={this.state.adminstradorSeleccionado?(this.state.adminstradorSeleccionado.tipo===2 && !this.state.representanteSeleccionado ?{border:"1px solid red"}:{}):{}}> 
                    <Select
                        isDisabled={this.state.adminstradorSeleccionado?(this.state.adminstradorSeleccionado.tipo===1?true:false):true}
                        options={this.state.representantes}
                        className="pixelAlrededor"
                        onChange={seleccionRepresentante}
                        value={this.state.representanteSeleccionado}
                        ref={(input) => { this.selecRepresentante= input; }}
                                
                    />
                </div>
                </FormGroup>
                    <FormGroup
                        label={this.props.i18next.t("organoadministracion.consejo",{ns:"page"})+":"}
                        labelFor="cargo-consejo"
                    >
                            <Select
                                isDisabled={this.state.adminstradorSeleccionado!==null?false:true}
                                options={this.state.cargos}
                                className="pixelAlrededor"
                                onChange={seleccionCargo}
                                value={this.state.cargoSeleccionado}
x                           />
                        </FormGroup>

                        <FormGroup
                            label={this.props.i18next.t("organoadministracion.nombramiento",{ns:"page"})+":"}
                            labelFor="fecha-nombramiento"
                            inline={true}
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                value={this.state.fechaNombramiento}
                            />
                        </FormGroup>

                        <FormGroup
                            label={this.props.i18next.t("organoadministracion.servicios",{ns:"page"})+":"}
                            labelFor="prestacion"
                        >
                            <TextArea id="prestacion" value={this.state.servicios} onChange={cambiarServicios} fill={true} />
                        </FormGroup>
                        <Button icon="git-repo" onClick={handleClick}>
                            {this.props.i18next.t("organoadministracion.articulo",{ns:"page"})}
                        </Button>

                        <Collapse isOpen={this.state.abierto}>
                            <Card> 
                                <b>{this.props.i18next.t("organoadministracion.titlearticulo",{ns:"page"})}</b>
                                <p>{this.props.i18next.t("organoadministracion.detarticulo",{ns:"page"})}</p>
                            </Card>
                        </Collapse>
                </Card>
            </>
        )
    }
}
