/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Card, FormGroup, Navbar, InputGroup,ButtonGroup, Alignment,Popover, Button, NavbarDivider, Elevation, Callout, Dialog, Classes, Tooltip, AnchorButton, Intent, Spinner} from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { DateInput } from "@blueprintjs/datetime"
import moment from 'moment';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import { withApollo } from 'react-apollo';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import styles from './stylesPrint';

class AsesoriasExternas extends Component {

    state={
        nuevoActivado: true,
        editarActivado: false,
        borrarActivado: false,
        personas:[],
        asesorias: [
        ],
        abierto: false,
        value: null,
        enabled: true,
        actividadesSelect: [
        ],
        tipoSectorSelect: [
        ],
        tipoSectorVolumen: [
        ],
        perfilesRiesgo: [
        ],
        personaSeleccionada:null,
        sectorActividadSeleccionado:null,
        asesoriaSectorSeleccionado:null,
        tipoVolumenSeleccionado:null,
        perfilesRiesgoSeleccionado:null,
        nuevoSector:null,
        asesoriaSeleccionado:null,
        todosAsesorias:[],
        fechaSeleccionada:null,
        cargando:false,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirA:false,
        modalImprimirA: false,
        cargandoImprimirAG:false,
        modalImprimirAG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
        cargandoGrid:false
    }

    cargarasesorias=async()=>{

        this.setState({cargando:true})

        await this.props.client.query({
            query:gql`{asesoriasExternas{ID_ASESORIA_EXTERNA,ASESORIA{TIPO_PERSONA{ID_TIPO_PERSONA}},FECHA_NOMBRAMIENTO,ID_CLASIFICACION_VOLUMEN,CLASIFICACION_VOLUMEN{DESCRIPCION},ID_PERFIL_RIESGO,PERFIL_RIESGO{DESCRIPCION}}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{

            //console.log(result)
            let asesorias=[]
            for(let asesoria of result.data.asesoriasExternas){

                let nombreasesoria=""
                ////console.log(asesoria)
                //Si es persona fisica...
                if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

                    this.state.personasFisicas.find((persona)=>{
                        if (persona.ID_PERSONA===asesoria.ID_ASESORIA_EXTERNA) {
                            nombreasesoria=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                        }
                    }) 
                    

                //Si es persona juridica
                }else if(asesoria.ASESORIA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.state.personasJuridicas.find((persona)=>{
                        if (persona.ID_PERSONA===asesoria.ID_ASESORIA_EXTERNA) {
                            nombreasesoria=`${persona.RAZON_SOCIAL}`
                        }
                    }) 

                }
                asesorias.push({"id":asesoria.ID_ASESORIA_EXTERNA,"nombre":nombreasesoria,"fechaNombramiento":asesoria.FECHA_NOMBRAMIENTO,"idTipoVolumen":asesoria.ID_CLASIFICACION_VOLUMEN,"tipoVolumen":asesoria.CLASIFICACION_VOLUMEN.DESCRIPCION,"idPerfilRiesgo":asesoria.ID_PERFIL_RIESGO,"perfilRiesgo":asesoria.PERFIL_RIESGO.DESCRIPCION,TIPO:asesoria.TIPO})           
            }
            this.setState({
                asesorias:asesorias,
                cargando:false
            })
  
        })

    }

    cargarActividades=async ()=>{

        //cargar combo sectores.
        await this.props.client.query({
            query:gql`{sectoresActividad{ID_SECTOR_ACTIVIDAD,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let actividades=[]
            result.data.sectoresActividad.forEach(async(actividad)=>{
             
                actividades.push({"value":actividad.ID_SECTOR_ACTIVIDAD,"label":actividad.DESCRIPCION}) 

            })
            this.setState({actividadesSelect:actividades})
        })
    }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }

        this.setState({personas:personas})
    }

    generarImprimirA= async() => {
        this.setState({
            cargandoImprimirA:true,
            modalImprimirA:true,
            cargandoGrid:true
        }, async () => {
             this.setState({cargandoImprimirA:false}, () => {
                 ReactDOM.render(this.ImprimirA(), document.getElementById('generarA-pdf'))
             })
        })
    }

    generarImprimirAG= async() => {
        this.setState({
            cargandoImprimirAG:true,
            modalImprimirAG:true,
            cargandoGrid:true
        }, async () => {
             this.setState({cargandoImprimirAG:false}, () => {
                 ReactDOM.render(this.ImprimirAG(), document.getElementById('generarAG-pdf'))
             })
        })
    }


    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }
    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   

   }

   cargarClasificacionesVolumen=async ()=>{
        //cargar combo tipo auditor sector.
        this.props.client.query({
            query:gql`{clasificacionesVolumen{ID_CLASIFICACION_VOLUMEN,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let clasificacionesVolumen=[]
            result.data.clasificacionesVolumen.forEach(async(tipo)=>{
            
                clasificacionesVolumen.push({"value":tipo.ID_CLASIFICACION_VOLUMEN,"label":tipo.DESCRIPCION}) 

            })
            this.setState({tipoSectorVolumen:clasificacionesVolumen})
        })
    }

    cargarPerfilesRiesgo=async ()=>{
        //cargar combo tipo auditor volumen.
        this.props.client.query({
            query:gql`{perfilesRiesgo{ID_PERFIL_RIESGO,DESCRIPCION}}`,
            fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
            let perfilesRiesgo=[]
            result.data.perfilesRiesgo.forEach(async(tipo)=>{
            
                perfilesRiesgo.push({"value":tipo.ID_PERFIL_RIESGO,"label":tipo.DESCRIPCION}) 

            })
            this.setState({perfilesRiesgo:perfilesRiesgo})
        })
    }

    componentDidMount(props){

        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarasesorias()
            await this.cargarActividades();
            await this.cargarClasificacionesVolumen();
            await this.cargarPerfilesRiesgo();
            
            this.setState({cargandoForm: false})
        });
        
    }

    ImprimirA=()=>{
        let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.asesoriaSeleccionado.id})
        let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.asesoriaSeleccionado.idTipoVolumen})
        let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.asesoriaSeleccionado.idPerfilRiesgo})
        return (
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("asesoriasexternas.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("asesoriasexternas.asesoria",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.asesoriaSeleccionado?
                            <View >
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("name",{ns:"global"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{personaSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("asesoriasexternas.fecha",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{moment(this.state.asesoriaSeleccionado.fechaNombramiento).format('DD/MM/YYYY')}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("asesoriasexternas.tipo",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{volumenSel.label}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("asesoriasexternas.perfil",{ns:"page"}).toUpperCase()}:</Text>
                                    <Text style={styles.textData}>{perfilRiesgoSel.label}</Text>
                                </View>
                                
                            </View>
                    :<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
    }

    ImprimirAG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("asesoriasexternas.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.asesorias?this.state.asesorias.map((asesoria, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'40%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("asesoriasexternas.fecha",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("asesoriasexternas.tipo",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'20%'}}>{i18next.t("asesoriasexternas.perfil",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'40%'}}>{asesoria.nombre?asesoria.nombre:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{asesoria.fechaNombramiento?moment(asesoria.fechaNombramiento).format('DD/MM/YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{asesoria.tipoVolumen?asesoria.tipoVolumen:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'20%'}}>{asesoria.perfilRiesgo?asesoria.perfilRiesgo:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("asesoriasexternas.title",{ns:"page"})

        const abrirModalNuevo =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                personaSeleccionada:null,
                sectorActividadSeleccionado:null,
                asesoriaSectorSeleccionado:null,
                tipoVolumenSeleccionado:null,
                perfilesRiesgoSeleccionado:null,
                asesoriaSeleccionado:null,
                fechaSeleccionada:new Date(),
                cargandoGrid:true

            })
    
        }

        const abrirModalEditar =async () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.asesoriaSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.asesoriaSeleccionado.id})
            let volumenSel=this.state.tipoSectorVolumen.find(tipoVolumne=>{return tipoVolumne.value===this.state.asesoriaSeleccionado.idTipoVolumen})
            console.log(volumenSel)
            let perfilRiesgoSel=this.state.perfilesRiesgo.find(perfil=>{return perfil.value===this.state.asesoriaSeleccionado.idPerfilRiesgo})

            this.setState({
                modalAbierto: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                personaSeleccionada:personaSel,
                tipoVolumenSeleccionado:volumenSel,
                perfilesRiesgoSeleccionado:perfilRiesgoSel,
                fechaSeleccionada:new Date(this.state.asesoriaSeleccionado.fechaNombramiento),
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.asesoriaSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({modalBorrarAbierto: true, cargandoGrid:true})
            this.setState({nuevoActivado:false})
            this.setState({editarActivado:false})
            this.setState({borrarActivado:true})
        }

        function dateFormatter(cell, row) {
            return (
            moment(cell).format("DD/MM/YYYY")
            );
        }
        
        const columns = [
            {
            dataField: 'nombre',
            text: i18next.t("name",{ns:"global"})
            },{
                dataField: 'fechaNombramiento',
                text: i18next.t("asesoriasexternas.fecha",{ns:"page"}),
                formatter: dateFormatter
                },{
                    dataField: 'tipoVolumen',
                    text: i18next.t("asesoriasexternas.tipo",{ns:"page"})
                    },{
                        dataField: 'perfilRiesgo',
                        text: i18next.t("asesoriasexternas.perfil",{ns:"page"})
                        }
        ];

        const escogeFecha = (nuevaFecha) => {
            this.setState({fechaSeleccionada: nuevaFecha})
        }

      
        const cambiarnuevoSector=(ev)=>{
            this.setState({nuevoSector:ev.currentTarget.value})
        }

        const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };


        const añadirNuevo = (value) => {


            if(!this.state.personaSeleccionada){
                return
            }

            if(!this.state.fechaSeleccionada){
                return
            }
        
            if(!this.state.tipoVolumenSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.asesorias.find(asesoria=>{return asesoria.id===this.state.personaSeleccionada.value})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            this.setState({pendienteGuardar:true})
            this.setState({controlesDesactivados:true})
            
            this.state.asesorias.push({
                "id":0,
                "nombre": this.state.personaSeleccionada.label,
                "fechaNombramiento":this.state.fechaSeleccionada,
                "tipoVolumen": this.state.tipoVolumenSeleccionado.label,
                "perfilRiesgo": this.state.perfilesRiesgoSeleccionado.label,
            })

            cerrarModal()
        }

        const actualizarAsesoria = () => {

            if(!this.state.personaSeleccionada ){
                return
            }


            if(!this.state.fechaSeleccionada){
                return
            }

            if(!this.state.tipoVolumenSeleccionado){
                return
            }

            if(!this.state.perfilesRiesgoSeleccionado){
                return
            }

            //comprobar que no existe ya
            let existe=this.state.asesorias.find(asesoria=>{return (asesoria.id===this.state.personaSeleccionada.value && asesoria.id!==this.state.asesoriaSeleccionado.id)})
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }

            let asesorias=this.state.asesorias
            this.state.asesorias.find((asesoria,index) =>{
                if(asesoria.id===this.state.asesoriaSeleccionado.id){
    
                    asesorias[index]["nombre"]=this.state.personaSeleccionada.label
                    asesorias[index]["fechaNombramiento"]=this.state.fechaSeleccionada
                    asesorias[index]["tipoVolumen"]=this.state.tipoVolumenSeleccionado.label
                    asesorias[index]["perfilRiesgo"]=this.state.perfilesRiesgoSeleccionado.label

                }
                return asesoria.id===this.state.asesoriaSeleccionado.id
            })
            this.setState({pendienteActualizar:true})
            this.setState({controlesDesactivados:true})
            this.setState({asesorias:asesorias})
            
            cerrarModal()
        }

        const eliminarAsesoria = () => {
            let asesorias=this.state.asesorias.filter(asesoria => asesoria.id !== this.state.asesoriaSeleccionado.id)
            this.setState({asesorias:asesorias})
            this.setState({pendienteBorrar:true})     
            this.setState({controlesDesactivados:true})
            cerrarModal()
        };

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                cargandoGrid:false,
                personaSeleccionada:null,
                asesoriaSeleccionado:null
            })
        }


        const seleccionPersona  = (value) => { 
            this.setState({personaSeleccionada: value})
        }
 
        const seleccionAsesoriaVolumen  = (value) => { 
            this.setState({tipoVolumenSeleccionado: value})
        }
        const seleccionPerfilRiesgo  = (value) => { 
            this.setState({perfilesRiesgoSeleccionado: value})
        }
        
        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        asesoriaSeleccionado: row
        });
        }
        return true;
        }
        

        const guardarCambios=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(this.state.pendienteGuardar){
         
                    this.props.client.mutate({
                        mutation:gql`mutation{crearAsesoriaExterna(VALORES:{ID_ASESORIA_EXTERNA:${this.state.personaSeleccionada.value},ID_CLASIFICACION_VOLUMEN:${this.state.tipoVolumenSeleccionado.value},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado.value},FECHA_NOMBRAMIENTO:"${moment(this.state.fecha).format('YYYY-MM-DD')}"}){ID_ASESORIA_EXTERNA}}`
    
                    }).then(async result=>{
                      
                        alert(i18next.t("insertcorrecto",{ns:"global"}))
                        await this.cargarasesorias()

                        this.setState({
                            pendienteGuardar:false,
                            controlesDesactivados:false,
                            asesoriaSeleccionado:null,
                            cargandoGrid:false
                        })

   
                    }).catch(err=>{
                        alert(i18next.t("error",{ns:"global"}))
                    })

            }else if(this.state.pendienteActualizar){
              
                
                this.props.client.mutate({
                    mutation:gql`mutation{actualizarAsesoriaExterna(ID_ASESORIA_EXTERNA:${this.state.asesoriaSeleccionado.id},VALORES:{ID_CLASIFICACION_VOLUMEN:${this.state.tipoVolumenSeleccionado?this.state.tipoVolumenSeleccionado.value:this.state.asesoriaSeleccionado.idTipoVolumen},ID_PERFIL_RIESGO:${this.state.perfilesRiesgoSeleccionado?this.state.perfilesRiesgoSeleccionado.value:this.state.asesoriaSeleccionado.idPerfilRiesgo},FECHA_NOMBRAMIENTO:"${this.state.fechaSeleccionada?moment(this.state.fechaSeleccionada).format('YYYY-MM-DD'):this.state.asesoriaSeleccionado.fechaNombramiento}"}){ID_ASESORIA_EXTERNA}}`
                }).then(async result=>{
                   
                    alert(i18next.t("updatecorrecto",{ns:"global"}))
                    await this.cargarasesorias()

                   
                   this.setState({
                    pendienteActualizar:false,
                       controlesDesactivados:false,
                       asesoriaSeleccionado:null,
                       cargandoGrid:false
                   })

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })


            }else if(this.state.pendienteBorrar){

                this.props.client.mutate({
                    mutation:gql`mutation{eliminarAsesoriaExterna(ID_ASESORIA_EXTERNA:${this.state.asesoriaSeleccionado.id}){ID_ASESORIA_EXTERNA}}`
                }).then(result=>{
                  
                    alert(i18next.t("deletecorrecto",{ns:"global"}))
                    this.setState({
                        pendienteBorrar:false,
                        controlesDesactivados:false,
                        asesoriaSeleccionado:null,
                        cargandoGrid:false
                    })
                    
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
            }

        }

        const cancelarCambios = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                personaSeleccionada:null,
                asesoriaSeleccionado:null,
                controlesDesactivados:false,
                pendienteBorrar:false,
                pendienteActualizar:false,
                pendienteGuardar:false,
                cargandoGrid:false
            })
            this.cargarasesorias()
        }



        const anadirSector=(ev)=>{


            if(!this.state.nuevoSector)return

            //comprobar que no exista un elemento con la misma descripción.
            let existe=this.state.actividadesSelect.find(sector=>{
                return sector.label===this.state.nuevoSector
            })

            if(existe){
                alert(i18next.t("valid2",{ns:"global"}))
                return
            }

            //creamos el modo convocatoria.
            this.props.client.mutate({
                mutation: gql`
                  mutation{crearSectorActividad(VALORES:{DESCRIPCION:"${this.state.nuevoSector}"})}
                `
                }).then(async result=>{
                    if(result.data.crearSectorActividad.includes("ERROR") ){
                        alert(i18next.t("asesoriasexternas.valid",{ns:"page"}))
                    }else{
                        await this.cargarActividades()

                        let sectorCreado=JSON.parse(result.data.crearSectorActividad)
                        let sectorSel=this.state.actividadesSelect.find(sector=>{
                            return sector.value===sectorCreado.ID_SECTOR_ACTIVIDAD
                        })
                        this.setState({sectorActividadSeleccionado:sectorSel})
                        cerrarModal2()
                    }

                })

        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                cerrarModal2();
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
                this.setState({personaSeleccionada:personaSel})
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                await this.setState({modalNuevaJuridicaAbierto:false})
                //cerrar el modal
                cerrarModal2();
            }
        };

        const abrirPopover = () => {
            this.setState({popoverAbierto: true})
        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({personaSeleccionada:null})
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            
        }

        const abrirModalEditarPersona=()=>{

            if(!this.state.personaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({nuevaPresonaActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.personaSeleccionada.tipo===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.personaSeleccionada.tipo===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const guardarPersonaJuridica=async ()=>{

            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.personas.find(persona=>{return persona.value===this.informacion.state.personaCreada})
            this.setState({personaSeleccionada:personaSel})
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarasesorias()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false,
                modalSectorAbierto: false,
            })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirA:false,
                modalImprimirAG:false,
                cargandoGrid:false,
                personaSeleccionada:null,
                asesoriaSeleccionado:null
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirAG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirAG?
                            <div style={{ width: '100%', height: '500px'}} id='generarAG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirA}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirA?
                            <div style={{ width: '100%', height: '500px'}} id='generarA-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("serviciosempresariales.asesoria",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                >
                    <div className="pixelAlrededor bp3-card separarBordes">
                        <FormGroup
                            label={i18next.t("asesoriasexternas.asesoria",{ns:"page"})+":"}
                            labelFor="asesoria"
                            intent="danger"
                            helperText={!this.state.personaSeleccionada?i18next.t("selectempty",{ns:"global"})+i18next.t("asesoriasexternas.asesoria",{ns:"page"}):""}
                        >
                        <div style={!this.state.personaSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.personas}
                                className="pixelAlrededor"
                                onChange={seleccionPersona}
                                isDisabled={this.state.editarActivado}
                                value={this.state.personaSeleccionada}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup>
                            <Popover  disabled={this.state.editarActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editarActivado} className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona} />
                        </FormGroup> 
                        <FormGroup
                            label={i18next.t("asesoriasexternas.fecha",{ns:"page"})+":"}
                            labelFor="fecha-nombramiento"
                            inline={true}
                            className="pixelAlrededor" 
                        >
                            <DateInput minDate={new Date(1900, 1 ,1)}
                                {...jsDateFormatter} 
                                onChange={escogeFecha}
                                defaultValue={new Date()}
                                align={Alignment.RIGHT}
                                value={this.state.fechaSeleccionada}
                            />
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("asesoriasexternas.tipo",{ns:"page"})+":"}
                            labelFor="tipo-asesoria-volumen"
                            intent="danger"
                            helperText={!this.state.tipoVolumenSeleccionado?i18next.t("selectempty",{ns:"global"})+i18next.t("asesoriasexternas.tipo",{ns:"page"}):""}
                        >
                        <div style={!this.state.tipoVolumenSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.tipoSectorVolumen}
                                className="pixelAlrededor"
                                onChange={seleccionAsesoriaVolumen}
                                value={this.state.tipoVolumenSeleccionado}
                            />
                        </div>
                        </FormGroup>
                        <FormGroup
                            label={i18next.t("asesoriasexternas.perfil",{ns:"page"})+":"}
                            labelFor="perfil-riesgo"
                            intent="danger"
                            helperText={!this.state.perfilesRiesgoSeleccionado?i18next.t("selectempty",{ns:"global"})+i18next.t("asesoriasexternas.perfil",{ns:"page"}):""}
                        >
                        <div style={!this.state.perfilesRiesgoSeleccionado?{border:"1px solid red"}:{}}>
                            <Select
                                options={this.state.perfilesRiesgo}
                                className="pixelAlrededor"
                                onChange={seleccionPerfilRiesgo}
                                value={this.state.perfilesRiesgoSeleccionado}
                            />
                        </div>
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={this.state.nuevoActivado?añadirNuevo:actualizarAsesoria}
                            >
                            {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("asesoriasexternas.addsector",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalSectorAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={i18next.t("asesoriasexternas.namesector",{ns:"page"})+":"}
                        labelFor="informacion"
                        intent="danger"
                        helperText={this.state.nuevoSector?"":i18next.t("emptyfield",{ns:"global"})+i18next.t("asesoriasexternas.namesector",{ns:"page"})}
                        >
                            <InputGroup id="texto-modo-convocatoria" onChange={cambiarnuevoSector}  placeholder={i18next.t("placeholder",{ns:"global"})} intent={this.state.nuevoSector?"primary":"danger"} />
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("aceptar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirSector}
                            >
                                {i18next.t("aceptar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal2}>{i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("asesoriasexternas.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarAsesoria}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
            <Dialog
        autoFocus={true}
        enforceFocus={true}
        icon="info-sign"
        onClose={cerrarModal2}
        title={i18next.t("propiedadpersona",{ns:"global"})}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isCloseButtonShown={true}
        className="dialog-ancho"
        isOpen={this.state.modalPersonaAbierto}
        >
        <DatosPersonasFisicas
            datosPersona={
            this.state.personaSeleccionada
                ? this.state.personaSeleccionada.value
                : null
            }
            ref={input => {
            this.datosPersona = input;
            }}
            i18next={i18next}
        />
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                <Button onClick={cerrarModal2}>{i18next.t("close",{ns:"global"})}</Button>
            </Tooltip>
            <Tooltip
                content={
                this.state.nuevaPresonaActivado
                    ? i18next.t("agregar",{ns:"global"})
                    : i18next.t("edit",{ns:"global"})
                }
                position="top"
            >
                <AnchorButton
                intent={Intent.PRIMARY}
                onClick={
                    this.state.nuevaPresonaActivado
                    ? crearPersona
                    : actualizarPersona
                }
                >
                {this.state.nuevaPresonaActivado
                    ? i18next.t("agregar",{ns:"global"})
                    : i18next.t("edit",{ns:"global"})}
                </AnchorButton>
            </Tooltip>
            </div>
        </div>
        </Dialog>
        <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title={i18next.t("propcompany",{ns:"global"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
                i18next={i18next}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text={i18next.t("close",{ns:"global"})}
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
            title={i18next.t("propcompany",{ns:"global"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={i18next}/>  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content={i18next.t("cancelar",{ns:"global"})} position="top">          
                    <Button
                        alignText="left"
                        icon="cross"
                        text={i18next.t("cancelar",{ns:"global"})}
                        intent="danger"
                        onClick={()=>{this.setState({modalNuevaJuridicaAbierto:false})}}
                        className="iconoIzquierda bp3-minimal"
                    />
                </Tooltip>
                <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text={i18next.t("guardar",{ns:"global"})}
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
                
                <div className="pixelAlrededorGrande tablaSesenta">
                    <Card>
                        <Callout className="bp3-intent-danger">{i18next.t("asesoriasexternas.title",{ns:"page"})}</Callout>
                        {this.state.cargandoGrid?
                            <Cargando/>
                        :
                        <>
                        <Navbar>
                            <Navbar.Group>
                                <ButtonGroup align={Alignment.LEFT}>
                                    <NavbarDivider />
                                    {this.state.controlesDesactivados?[
                                        <>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} disabled />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} disabled />
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} disabled/>
                                            <Button className="bp3-minimal" icon="print"  text={i18next.t("print",{ns:"global"})} disabled/>
                                            <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} disabled/>
                                        </>
                                    ]:[
                                        <>
                                            <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                            <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar} />
                                            <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirModalBorrar}/>
                                            <Tooltip 
                                                content={i18next.t("print",{ns:"global"})} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    disabled={this.state.asesoriaSeleccionado?false:true}  
                                                    text={i18next.t("print",{ns:"global"})} onClick={()=>this.generarImprimirA()}
                                                />
                                            </Tooltip>  
                                            <Tooltip 
                                                content={i18next.t("print",{ns:"global"})} 
                                                position="right" 
                                            >
                                                <Button 
                                                    className="bp3-minimal" 
                                                    icon="print" 
                                                    text={i18next.t("printgral",{ns:"global"})} 
                                                    onClick={()=>this.generarImprimirAG()}
                                                />
                                            </Tooltip>
                                        </>
                                    ]}
                                </ButtonGroup>
                            </Navbar.Group>
                        </Navbar>
                        <BootstrapTable
                        keyField='id'
                        data={ this.state.asesorias }
                        columns={ columns }
                        selectRow={ const_table.selectRow(handleOnSelect) }
                        pagination={ paginationFactory(const_table.options) }
                        headerClasses="header-class"
                        rowClasses="row-class"
                        hover
                        condensed
                        noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                        />
                        <br />
                        <Button 
                            alignText="right"
                            icon="floppy-disk"
                            text={i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            disabled={!this.state.controlesDesactivados}
                            onClick={guardarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        <Button 
                            alignText="right"
                            icon="cross"
                            intent="danger"
                            text={i18next.t("cancelar",{ns:"global"})}
                            disabled={!this.state.controlesDesactivados}
                            onClick={cancelarCambios}
                            className="iconoDerecha bp3-minimal"
                        />
                        </>
                        }
                        <br />
                        <br />
                    </Card>
                </div>
                <div className="pixelAlrededor tablaCuarenta">
                    <Card interactive={true} elevation={Elevation.TWO}>
                        <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("asesoriasexternas.titleley",{ns:"page"})}</Callout>
                        <br />
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1089", "_blank")}>{i18next.t("asesoriasexternas.articulo1089",{ns:"page"})}</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1091", "_blank")}>{i18next.t("asesoriasexternas.articulo1091",{ns:"page"})}</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1101", "_blank")}>{i18next.t("asesoriasexternas.articulo1101",{ns:"page"})}</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1104", "_blank")}>{i18next.t("asesoriasexternas.articulo1104",{ns:"page"})}</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1105", "_blank")}>{i18next.t("asesoriasexternas.articulo1105",{ns:"page"})}</AnchorButton>
                        <AnchorButton onClick={()=> window.open("https://www.boe.es/buscar/act.php?id=BOE-A-1889-4763&p=20180804&tn=1#art1106", "_blank")}>{i18next.t("asesoriasexternas.articulo1106",{ns:"page"})}</AnchorButton>
                        <br />
                    </Card>
                </div>
            </>
            }
            </>
        )
    }
}

export default withApollo(AsesoriasExternas)