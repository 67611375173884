import React from 'react';
import { FormGroup, InputGroup, Button, Dialog, Classes, Tooltip, AnchorButton, Intent, Card, Elevation,TextArea} from "@blueprintjs/core"
import { DateInput } from "@blueprintjs/datetime";
import "moment/locale/es"
import Select from "react-select"
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import moment from 'moment';

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

const estadoInicial = {

    grupos: [],
    grupoElegido: null,
    grupoEmpresarial:null,
    sitioWeb:null,
    fechaInicio: null,
    fechaFin: null,
    razonSocial:null,
    nombreComercial:null,
    modalGrupoEmpresarial:false,
    idEmpresa:null,
    urlValida:true,
    tipoDocumento:4,
    personaCreada:null,
    gravamenes: null,
    domicilio: null,
    localidad: null,
    fechaAlta: null,
    fechaBaja: null,
    observaciones: null,
    paises:[],
    paisSeleccionado:null,
    cif:null,
    cifValido:true,
    idCif:null,
    paisNuevo:null

};


class InformacionGeneralNuevo extends React.Component {
    
    state=estadoInicial

    cargarPaises=async()=>{


        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });

        await client
        .query({
            query: gql`
            {
                paises{ID_PAIS,DESCRIPCION}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            let paises=[]
            for(let pais of result.data.paises){
                paises.push({"label":pais.DESCRIPCION,"value":pais.ID_PAIS})
            }
    
            this.setState({paises:paises})
        })
    
    }

    cargarGrupos=async ()=>{

        client = new ApolloClient({
            uri: process.env.REACT_APP_DOMINIO+"/api",
            credentials: 'include',
        });
        
        await client
        .query({
            query: gql`
            {
                gruposEmpresariales{ID_GRUPO_EMPRESARIAL,DESCRIPCION}
            }
            `
        }).then(result=>{
            result.data.gruposEmpresariales.forEach((grupo,index)=>{
                result.data.gruposEmpresariales[index]={"label":grupo.DESCRIPCION,"value":grupo.ID_GRUPO_EMPRESARIAL}
            })   
            this.setState({grupos:result.data.gruposEmpresariales})
        })

    }

    cargarDatosEmpresa=()=>{
        //DATOS JURIDICOS
        client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_PERSONA,ID_GRUPO_EMPRESARIAL,RAZON_SOCIAL,NOMBRE_COMERCIAL,WEB_CORPORATIVA,GRAVAMENES,DOMICILIO,LOCALIDAD,FECHA_ALTA,FECHA_BAJA,ID_NACIONALIDAD,OBSERVACIONES}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{

            this.setState({
                idEmpresa:result.data.personasJuridicas[0].ID_PERSONA,
                razonSocial:result.data.personasJuridicas[0].RAZON_SOCIAL,
                nombreComercial:result.data.personasJuridicas[0].NOMBRE_COMERCIAL,
                sitioWeb:result.data.personasJuridicas[0].WEB_CORPORATIVA,
                gravamenes: result.data.personasJuridicas[0].GRAVAMENES,
                domicilio: result.data.personasJuridicas[0].DOMICILIO,
                localidad: result.data.personasJuridicas[0].LOCALIDAD,
                fechaAlta: result.data.personasJuridicas[0].FECHA_ALTA?new Date(result.data.personasJuridicas[0].FECHA_ALTA):null,
                fechaBaja: result.data.personasJuridicas[0].FECHA_BAJA?new Date(result.data.personasJuridicas[0].FECHA_BAJA):null,
                observaciones: result.data.personasJuridicas[0].OBSERVACIONES
            })
            
            if(result.data.personasJuridicas[0].ID_NACIONALIDAD){
                let paisSeleccionado=this.state.paises.find(pais=>{return pais.value===result.data.personasJuridicas[0].ID_NACIONALIDAD})
                this.setState({paisSeleccionado:paisSeleccionado})
            }

            if(result.data.personasJuridicas[0].ID_GRUPO_EMPRESARIAL){
                let grupoSeleccionado=this.state.grupos.find(grupo=>{return grupo.value===result.data.personasJuridicas[0].ID_GRUPO_EMPRESARIAL})
                this.setState({grupoElegido:grupoSeleccionado})
            }

            client
            .query({
                query: gql`
                {
                    documentosIdentificativos(FILTROS:{ID_PERSONA:${this.props.empresaSeleccionada}}){ID_DOCUMENTO_IDENTIFICATIVO,VALOR}
                }
                `,
                fetchPolicy: 'network-only'
    
            }).then(result=>{

                if(result.data.documentosIdentificativos.length>0){

                    this.setState({cif:result.data.documentosIdentificativos[0].VALOR,idCif:result.data.documentosIdentificativos[0].ID_DOCUMENTO_IDENTIFICATIVO})
                }
            })
        })
    }

   
    //cuando se haya montado el componente lo rellenamos con sus datos.
    componentWillMount=(props)=>{

        this.cargarGrupos()
        this.cargarPaises()

        if(this.props.empresaSeleccionada){
            this.cargarDatosEmpresa()
        }

    }

   

    handleGuardarDatos =async () => {

        if(this.props.editable===false){
            alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
            return false
        }

        if(!this.state.razonSocial){
            return false
        }
        
        if(!this.state.cif){
            return false
        }

        //if(this.validateCIF(this.state.cif)){

            client = new ApolloClient({
                uri: process.env.REACT_APP_DOMINIO+"/api",
                credentials: 'include',
            });

            let enUso=await client
            .query({
            query: gql`
                {
                    documentosIdentificativos(CAMPOS:"ID_PERSONA",FILTROS:{VALOR:"${this.state.cif}"}){ID_PERSONA}
                }
            `,fetchPolicy:'network-only'
            })
            .then(result =>{
    
                return result.data.documentosIdentificativos
            });

            if(this.state.idCif){

                if(enUso.length>0 && enUso[0].ID_PERSONA!==this.props.empresaSeleccionada){
                    alert(this.props.i18next.t("juridicas.cifuso",{ns:"page"}))
                    return false
                }

            }else{

                if(enUso.length>0){
                    alert(this.props.i18next.t("juridicas.cifuso",{ns:"page"}))
                    return false
                }
    
            }

     

            this.setState({cifValido:true})

        //}else{
        //    this.setState({cifValido:false})
        //    return false
        //}

        const validate=(url) =>{
            var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
            if (pattern.test(url)) {
                return true;
            } 
                return false;
     
        }


        //si el usuario no tiene permisos para editar, no hacemos nada.
        if(this.props.editable===false)return

        if(this.state.sitioWeb){

            //comprobar si el sitio web es valido.
            if(validate(this.state.sitioWeb)===false){
                this.setState({urlValida:false})
                alert(this.props.i18next.t("juridicas.sitionovalid",{ns:"page"}))
                return false
            }else{
                this.setState({urlValida:true})
            }

        }
    
        let persona=""
        //damos de alta a la persona en la tabla personas
        if(this.props.empresaSeleccionada){

            let res=await client.mutate({
                mutation: gql`
                    mutation{actualizarPersonaJuridica(ID_PERSONA:${this.props.empresaSeleccionada},VALORES:{RAZON_SOCIAL:"${this.state.razonSocial}",NOMBRE_COMERCIAL:${this.state.nombreComercial?'"'+this.state.nombreComercial+'"':null},ID_GRUPO_EMPRESARIAL:${this.state.grupoElegido?this.state.grupoElegido.value:null},WEB_CORPORATIVA:${this.state.sitioWeb?'"'+this.state.sitioWeb+'"':null},GRAVAMENES:${this.state.gravamenes?'"'+this.state.gravamenes+'"':null},DOMICILIO:${this.state.domicilio?'"'+this.state.domicilio+'"':null},LOCALIDAD:${this.state.localidad?'"'+this.state.localidad+'"':null},FECHA_ALTA:${this.state.fechaAlta?'"'+moment(this.state.fechaAlta).format("YYYY-MM-DD")+'"':null},FECHA_BAJA:${this.state.fechaBaja?'"'+moment(this.state.fechaBaja).format("YYYY-MM-DD")+'"':null},ID_NACIONALIDAD:${this.state.paisSeleccionado?this.state.paisSeleccionado.value:null},OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}}){ID_PERSONA}}
                `
                }).then(result=>{
                
                    if(this.state.idCif){

                        client.mutate({
                            mutation: gql`
                                mutation{actualizarDocumentoIdentificativo(ID_DOCUMENTO_IDENTIFICATIVO:${this.state.idCif},VALORES:{VALOR:"${this.state.cif}"}){ID_DOCUMENTO_IDENTIFICATIVO}}
                            `
                        }).catch(err=>{
                            alert(this.props.i18next.t("error",{ns:"global"}))
                        })

                    }else{

                        client.mutate({
                            mutation: gql`
                                mutation{crearDocumentoIdentificativo(VALORES:{ID_PERSONA:${this.props.empresaSeleccionada},ID_TIPO_DOCUMENTO:${4},VALOR:"${this.state.cif}",FECHA_EMISION:${null},FECHA_CADUCIDAD:${null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
                            `
                        }).catch(err=>{
                            alert(this.props.i18next.t("error",{ns:"global"}))
                        })
                    }
                

                    this.setState({personaCreada:this.props.empresaSeleccionada})    
                    return true

                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                    return false
                })
     
                if(!res){
                    return false
                }

                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))

        }else{


            let res=await client.mutate({
                mutation:gql`mutation{crearPersona(VALORES:{ID_TIPO_PERSONA:2}){ID_PERSONA}}`
    
            }).then(async result=>{
                
                persona=result.data.crearPersona.ID_PERSONA

                return await client.mutate({
                mutation: gql`
                    mutation{crearPersonasJuridicas(VALORES:{ID_PERSONA:${result.data.crearPersona.ID_PERSONA},RAZON_SOCIAL:"${this.state.razonSocial}",NOMBRE_COMERCIAL:${this.state.nombreComercial?'"'+this.state.nombreComercial+'"':null},ID_GRUPO_EMPRESARIAL:${this.state.grupoElegido?this.state.grupoElegido.value:null},WEB_CORPORATIVA:${this.state.sitioWeb?'"'+this.state.sitioWeb+'"':null},EMPRESA_PRINCIPAL:false,GRAVAMENES:${this.state.gravamenes?'"'+this.state.gravamenes+'"':null},DOMICILIO:${this.state.domicilio?'"'+this.state.domicilio+'"':null},LOCALIDAD:${this.state.localidad?'"'+this.state.localidad+'"':null},FECHA_ALTA:${this.state.fechaAlta?'"'+moment(this.state.fechaAlta).format("YYYY-MM-DD")+'"':null},FECHA_BAJA:${this.state.fechaBaja?'"'+moment(this.state.fechaBaja).format("YYYY-MM-DD")+'"':null},ID_NACIONALIDAD:${this.state.paisSeleccionado?this.state.paisSeleccionado.value:null},OBSERVACIONES:${this.state.observaciones?'"""'+this.state.observaciones+'"""':null}}){ID_PERSONA}}
                `
                }).then(result=>{
                 
                    
                    client.mutate({
                        mutation: gql`
                            mutation{crearDocumentoIdentificativo(VALORES:{ID_PERSONA:${result.data.crearPersonasJuridicas.ID_PERSONA},ID_TIPO_DOCUMENTO:${4},VALOR:"${this.state.cif}",FECHA_EMISION:${null},FECHA_CADUCIDAD:${null}}){ID_DOCUMENTO_IDENTIFICATIVO}}
                        `
                    }).catch(err=>{
                        alert(this.props.i18next.t("error",{ns:"global"}))
                    })

                    this.setState({personaCreada:persona})  
                    return true  
                        
                }).catch(err=>{
                    alert(this.props.i18next.t("error",{ns:"global"}))
                    return false
                })
     
            })   
            if(!res){
                return false
            }

        }

        return true

    }

    validateCIF(cf)
	{
    var i = 0;
    var temp=0;
	// eslint-disable-next-line no-array-constructor
	var v1 = new Array(0,2,4,6,8,1,3,5,7,9);
	cf = cf.toUpperCase();
	if (!/^[A-Za-z0-9]{9}$/.test(cf)) return false
	else if (!/^[ABCDEFGHKLMNPQS]/.test(cf)) return false
    else
        {
            for(i=2; i<=6; i+=2)
                {
                temp += v1[parseInt(cf.substr(i-1, 1))];
                temp += parseInt(cf.substr(i, 1));
                }
            temp = temp + v1[parseInt(cf.substr(7,1))];
            temp = (10 - ( temp % 10));
            if(temp === 10 && (cf.charAt(cf.length-1) === 'J' || cf.charAt(cf.length-1) === 0)) return true;
            else if (cf.charAt(cf.length-1) === temp || cf.charAt(cf.length-1) === String.fromCharCode(64 + temp)) return true;
            else return false;
        }
	}
        
   
    render() {
    

        const seleccionGrupo = (value) => {
            this.setState({grupoElegido: value})
        }

        const cambiarWeb = (ev) => {
            if(!ev.currentTarget.value)this.setState({urlValida:true})
            this.setState({sitioWeb: ev.currentTarget.value})
        }

        const abrirWebPestaña = () => {
            let win = window.open (this.state.sitioWeb, '_blank')
            win.focus()
        }

        const handleCambarRazonSocial = (e) => {
            this.setState({razonSocial:e.currentTarget.value})
        }

        const handleGrupoEmpresarial = (e) => {
            this.setState({grupoEmpresarial:e.currentTarget.value})
        }

        const handleNombreComercial = (e) => {
            this.setState({nombreComercial:e.currentTarget.value})
        }

        const abrirModalGrupo=()=>{
            this.setState({modalGrupoEmpresarial:true})
        }

        const cerrarModal=()=>{
            this.setState({modalGrupoEmpresarial:false})
            this.setState({modalNuevoCifAbierto:false})
            this.setState({modalCuentaAbierto:false})
            this.setState({modalCuentaBorrar:false})

        }

        const anadirGrupo=()=>{

            //si no se escribe nada.
            if(!this.state.grupoEmpresarial)return

            //insertamos el grupo empresarial.
            client.mutate({
            mutation: gql`
                mutation{crearGrupoEmpresarial(VALORES:{DESCRIPCION:"${this.state.grupoEmpresarial}"}){ID_GRUPO_EMPRESARIAL}}
            `
            }).then(async result=>{
            
                await this.cargarGrupos()
                let grupoSeleccionado=this.state.grupos.find(grupo=>{return grupo.value===result.data.crearGrupoEmpresarial.ID_GRUPO_EMPRESARIAL})
                this.setState({grupoElegido:grupoSeleccionado})

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
                return
            })
            cerrarModal()
        }

        const handleCambiarGravamenes = ev => {
            this.setState({ gravamenes: ev.currentTarget.value });
          };
          
          const handleCambiarDomicilio = ev => {
            this.setState({ domicilio: ev.currentTarget.value });
          };
      
          const handleCambiarLocalidad = ev => {
            this.setState({ localidad: ev.currentTarget.value });
          };
      
          const cambiarTextoObservaciones = ev => {
            this.setState({ observaciones: ev.currentTarget.value });
          };
      
          const escogeFechaAlta = nuevaFecha => {
            this.setState({ fechaAlta: nuevaFecha });
          };
      
          const escogeFechaBaja = nuevaFecha => {
            this.setState({ fechaBaja: nuevaFecha });
          };
      
          const seleccionarPais = pais => {
            this.setState({ paisSeleccionado: pais });
          };
      
          const jsDateFormatter = {
            // note that the native implementation of Date functions differs between browsers
            formatDate: date => moment(date).format("DD/MM/YYYY"),
            parseDate: str => moment(str, "DD/MM/YYYY").toDate(),
            placeholder: "DD/MM/YYYY"
        };

          const cambiarCif = (ev) => {
            this.setState({cif: ev.currentTarget.value})
          }

          const anadirPais=()=>{

            //si no se escribe nada.
            if(!this.state.paisNuevo)return

            let existe=this.state.paises.find(pais=>{return pais.label===this.state.paisNuevo})
            if(existe){
                alert(this.props.i18next.t("yaexiste",{ns:"global"}))
                return
            }

            //insertamos el pais.
            client.mutate({
            mutation: gql`
              mutation{crearPais(VALORES:{DESCRIPCION:"${this.state.paisNuevo}"}){ID_PAIS}}
            `
            }).then(async result=>{

                await this.cargarPaises()

                let paisSel=this.state.paises.find(pais=>{
                    return pais.value===result.data.crearPais.ID_PAIS
                })
                
                this.setState({paisSeleccionado:paisSel,modalPais:false})
               

            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })
        }

        
        const handleCambiarNuevoPais=(ev)=>{
            this.setState({paisNuevo:ev.currentTarget.value})
        }
       
        return (
            
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={()=>{this.setState({modalPais:false})}}
                    title={this.props.i18next.t("juridicas.addpais",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    isOpen={this.state.modalPais}
                    usePortal={true}
                    className="zIndexAlto"
                >
                    <div className={Classes.DIALOG_BODY}>
                        <FormGroup
                        label={this.props.i18next.t("juridicas.namepais",{ns:"page"})+":"}
                        labelFor="informacion"
                        >
                            <InputGroup id="texto-nombre-grupo" onChange={handleCambiarNuevoPais} placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent="primary"/>
                        </FormGroup>

                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirPais}
                            >
                                {this.props.i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={()=>{this.setState({modalPais:false})}}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                    </div>
                </Dialog>
                <Dialog transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal}
                title={this.props.i18next.t("juridicas.addgroup",{ns:"page"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalGrupoEmpresarial}
            >
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                    label={this.props.i18next.t("juridicas.namegroup",{ns:"page"})+":"}
                    labelFor="informacion"
                    intent="danger"
                    helperText={this.state.grupoEmpresarial?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("juridicas.namegroup",{ns:"page"})}
                    >
                        <InputGroup id="texto-nombre-grupo" onChange={handleGrupoEmpresarial} placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={this.state.grupoEmpresarial?"primary":"danger"} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={this.props.i18next.t("agregar",{ns:"global"})} position="top">
                        <AnchorButton
                            intent={Intent.PRIMARY}
                            onClick={anadirGrupo}
                        >
                            {this.props.i18next.t("agregar",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={cerrarModal}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
                    </Tooltip>
                </div>
            </div>
        </Dialog>
        
            <div className="pixelAlrededor">
            { /* razón social*/  }
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
            <FormGroup
                label={this.props.i18next.t("juridicas.razonsocial",{ns:"page"})+":"}
                labelFor="informacion"
                intent="danger"
                helperText={(this.state.razonSocial && this.state.razonSocial!=="")?"":this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("juridicas.razonsocial",{ns:"page"})}
            >
                <InputGroup id="texto-razon-social" value={this.state.razonSocial?this.state.razonSocial:""} onChange={handleCambarRazonSocial} placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent={(this.state.razonSocial)?"primary":"danger"}/>
            </FormGroup>
            </Card>
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
            <FormGroup
            label={this.props.i18next.t("juridicas.namecomercial",{ns:"page"})+":"}
            labelFor="informacion"
            >
                <InputGroup id="texto-nombre-comercial" value={this.state.nombreComercial?this.state.nombreComercial:""} onChange={handleNombreComercial} placeholder={this.props.i18next.t("placeholder",{ns:"global"})} intent="primary"/>
            </FormGroup>
            </Card>

           

            </div>

            <div>
            { /* Grpo empresarial */ }
            <div>
            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">
            <FormGroup
            label={this.props.i18next.t("juridicas.group",{ns:"page"})+":"}
            labelFor="informacion"
            >
            <div>
                <Select 
                    value={this.state.grupoElegido}
                    onChange={seleccionGrupo}
                    options={this.state.grupos}
                    className="grupoEmpresarial"
                />
            </div>
            <div>
                <Button 
                    alignText="right"
                    icon="add"
                    className="iconoIzquierda"
                    onClick={abrirModalGrupo}
                    
                />           
                </div>
            </FormGroup>
            </Card>

            <Card interactive={true} elevation={Elevation.TWO} className="pixelAlrededor">


            <FormGroup
                label={this.props.i18next.t("juridicas.cif",{ns:"page"})+":"}
                labelFor="cif"
                helperText={this.state.cif?(!this.state.cifValido?this.props.i18next.t("juridicas.validcif",{ns:"page"}):null):this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("juridicas.cif",{ns:"page"})}
                intent={this.state.cifValido?"":"danger"}
            >   
                <InputGroup 
                    id="cif" 
                    placeholder={this.props.i18next.t("placeholder",{ns:"global"})} 
                    intent={this.state.cifValido && this.state.cif?"primary":"danger"}
                    value={this.state.cif?this.state.cif:""}
                    onChange={cambiarCif}
                    disabled={this.props.empresaSeleccionada===1?true:false}
                />
            </FormGroup>

            { /* web */ }

            <FormGroup
                label={this.props.i18next.t("juridicas.sitioweb",{ns:"page"})+":"}
                labelFor="informacion"
                helperText={this.state.urlValida?"":this.props.i18next.t("juridicas.validurl",{ns:"page"})}
                intent={this.state.urlValida?"":"danger"}
            >   
                <div>
                    <InputGroup 
                        id="sitio-web-corporativo" 
                        placeholder={this.props.i18next.t("placeholder",{ns:"global"})}
                        intent={this.state.urlValida?"primary":"danger"}

                        value={this.state.sitioWeb?this.state.sitioWeb:""}
                        onChange={cambiarWeb}
                    />
                </div>
                <div>
                    <Button 
                        alignText="right"
                        icon="export"
                        className="iconoIzquierda"
                        onClick={abrirWebPestaña}
                    />
                </div> 
            </FormGroup>
            <FormGroup label={this.props.i18next.t("juridicas.gravamenes",{ns:"page"})+":"} labelFor="gravamenes">
            <InputGroup
              id="gravamenes"
              intent="primary"
              defaultValue={
                this.state.gravamenes
              }
              onChange={handleCambiarGravamenes}
            />
          </FormGroup>
          {this.props.empresaSeleccionada!==1?
            <>
                <FormGroup label={this.props.i18next.t("juridicas.domicilio",{ns:"page"})+":"} labelFor="domicilio">
                <InputGroup
                    id="domicilio"
                    intent="primary"
                    defaultValue={
                    this.state.domicilio
                    }
                    onChange={handleCambiarDomicilio}
                />
                </FormGroup>
                <FormGroup label={this.props.i18next.t("juridicas.localidad",{ns:"page"})+":"} labelFor="localidad">
                <InputGroup
                    id="localidad"
                    intent="primary"
                    defaultValue={
                    this.state.localidad
                    }
                    onChange={handleCambiarLocalidad}
                />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("juridicas.fechaalta",{ns:"page"})+":"}
                    labelFor="fecha-alta"
                    intent="danger"
                >
                <DateInput minDate={new Date(1900, 1 ,1)}
                    {...jsDateFormatter}
                    intent="primary"
                    onChange={escogeFechaAlta}
                    value={this.state.fechaAlta}
                />
                </FormGroup>
                <FormGroup
                    label={this.props.i18next.t("juridicas.fechabaja",{ns:"page"})+":"}
                    labelFor="fecha-baja"
                    intent="danger"
                >
                <DateInput minDate={new Date(1900, 1 ,1)}
                    {...jsDateFormatter}
                    intent="primary"
                    onChange={escogeFechaBaja}
                    value={this.state.fechaBaja}
                />
                </FormGroup>
            </>
          :null}
         
          <FormGroup
              label={this.props.i18next.t("juridicas.nacionalidad",{ns:"page"})+":"}
              labelFor="nacionalidad"
          >
            <Select
                options={this.state.paises}
                className="pixelAlrededor"
                intent="primary"
                onChange={seleccionarPais}
                value={this.state.paisSeleccionado}
            />
            <Button 
                alignText="right"
                icon="add"
                className="iconoIzquierda"
                onClick={()=>{this.setState({modalPais:true})}}
            />
        </FormGroup>
       
        <FormGroup
            label={this.props.i18next.t("juridicas.observaciones",{ns:"page"})+":"}
            labelFor="observaciones"
        >
          <TextArea 
              fill={true}
              intent="primary"
              onChange={cambiarTextoObservaciones}
              value={this.state.observaciones}
          />
        </FormGroup>
        <br/>
        {this.props.empresaSeleccionada?
             <Button
             alignText="left"
             icon="floppy-disk"
             intent="danger"
             onClick={()=>{this.handleGuardarDatos()}}
            >
                {this.props.i18next.t("guardar",{ns:"global"})}
            </Button>
        :null}
       

        </Card>
        </div>
        </div>
        </>
        )
    }
}

export default InformacionGeneralNuevo