/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Spinner, Dialog, Card, Elevation, FormGroup, Button, Callout } from "@blueprintjs/core"
import pantallaInicio from '../../../Assets/LOGO REGISTRADO PEQUEÑO.png'
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';


import styles from '../stylesPrint';

class ImprimirInformacionEstructuraSocietaria extends Component {
    
    state={
       
        cargando:true,
        distribuciones:[],
        distribucionesActuales:[],
        variaciones:[],
        transmisiones:[],
        importeTotalObligaciones:0,
        imprimirActivado:false,
        modalImprimir:false,
        cargandoImprimir:false
    }

    valTitulo=0
    distribucionesActuales=[]
    dist=[]

  
    // obtenerTipoAdministracion=async()=>{

        
    //     let capital=this.props.empresaPrincipal
    //     // .query({
    //     //     query: gql`
    //     //     {
    //     //         personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){ID_TIPO_SOCIEDAD,TIPO_SOCIEDAD{DESCRIPCION},ID_TIPO_CAPITAL_SOCIAL,TIPO_CAPITAL_SOCIAL{DESCRIPCION},FECHA_FINALIZACION}
    //     //     }
    //     //     `,fetchPolicy:'network-only'
    //     // }).then(result=>{ 
    //     //     return result.data.personasJuridicas[0]
    //     // })

    //     return capital

    // }
    
    obtenerCapital=async()=>{
     

           //obtencion de las varaciones
        let variaciones=await this.props.client 
        .query({
            query:gql`{capSocialVariaciones(ORDEN:"FECHA,ID_VARIACION,ASC"){
                ID_VARIACION,
                FECHA,
                ID_TIPO_VARIACION,
                CAMBIO_VALOR_TITULO,
                CAMBIO_NUMERO_TITULOS}}
                `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialVariaciones
        })
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                ID_TRANSMISION,
                FECHA,
                ORIGEN,
                FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                DESTINO,
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
                TIPO_ACCION,
                NUMERO_TITULOS,
                NUMERACION_TITULOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
       
        let numTitulos=0
        let socios=[]

        this.props.capSocialInicialDistribucion.forEach(socio=>{
            numTitulos+=socio.NUMERO_TITULOS
            socios.push(socio)
        })
        for(let socio of socios){
            let nombre=''
            if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(persona=>{
                if(persona.ID_PERSONA===socio.ID_PERSONA){
                    nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELIDO2:''} ${persona.NOMBRE}`
                }
            })
            }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                this.props.personasJuridicas.find(persona=>{
                    if(persona.ID_PERSONA===socio.ID_PERSONA){
                        nombre=persona.RAZON_SOCIAL
                    }
                })
            }
            socio.nombre=nombre
        }
        //agrupacion del array de socios por ID_PERSONA
        let sociosAgrupados = [];
        socios.reduce(function(res, value) {
            if (!res[value.ID_PERSONA]) {
                res[value.ID_PERSONA] = { ID_PERSONA: value.ID_PERSONA,NOMBRE:value.nombre, NUMERO_TITULOS: 0 };
                sociosAgrupados.push(res[value.ID_PERSONA])
            }
            res[value.ID_PERSONA].NUMERO_TITULOS += value.NUMERO_TITULOS;
            return res;
        }, {});

        let numeroTit=0
        let valTitulo=0
        for(let variacion of variaciones){
            if(variacion.ID_TIPO_VARIACION===1){
                let distribucion=await this.props.client
                .query({
                    query: gql`
                    {capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_PERSONA,VARIACION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
                    `,fetchPolicy:'network-only'
                }).then(result=>{
                    return result.data.capSocialVariacionesDistribucion
                })

                for(let dist of distribucion){
                    console.log(dist)
                    let nombre='';
                    numeroTit+=dist.VARIACION
                    if(dist.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                            this.props.personasFisicas.find(persona=>{
                            if(persona.ID_PERSONA===dist.ID_PERSONA){
                                nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                            }
                        })
                    }else if(dist.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                        this.props.personasJuridicas.find(persona=>{
                            if(persona.ID_PERSONA===dist.ID_PERSONA){
                                nombre=persona.RAZON_SOCIAL
                            }
                        })
                    }
                    dist.nombre= nombre
                }
                variacion.distribucion= distribucion
            }else{
                valTitulo+=variacion.CAMBIO_VALOR_TITULO
            }
        }
        let sociosAgrupadosActuales=JSON.parse(JSON.stringify(sociosAgrupados))
       
        //por cada transmision sumamos los titulos.
        for(let transmision of transmisiones){
            await this.obtenerNombreMiembros(transmision.FK_DESTINO)
            await this.obtenerNombreMiembros(transmision.FK_ORIGEN)

            let existe=sociosAgrupadosActuales.find(socio=>{
                return socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA})
        //si no existe el socios lo insertamos.
        if(!existe){
            sociosAgrupadosActuales.push({
                "ID_PERSONA":transmision.FK_DESTINO.ID_PERSONA,
                "NOMBRE":transmision.FK_DESTINO.nombre,
                "NUMERO_TITULOS":transmision.NUMERO_TITULOS            
            })
        }else{
            sociosAgrupadosActuales.find(socio=>{
                if(socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                    socio.NUMERO_TITULOS+=transmision.NUMERO_TITULOS
                }
            })
            //si existe ya le sumamos el numero de titulos al origen
        }
           //descontamos los titulos al origen de la transmision.
           sociosAgrupadosActuales.find(socio=>{
            if(socio.ID_PERSONA===transmision.FK_ORIGEN.ID_PERSONA){
                socio.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
            }
        })
    
        }

        if(this.props.capSocialInicial.length>0){
            this.props.capSocialInicial.forEach(capital=>{
                this.setState({
                    numTitInicial:numTitulos,
                    existeCapitaSocial:true,
                    valorTitulolInicial:capital.VALOR_TITULO,
                    fechaCapitalInicial:capital.FECHA_VALIDEZ,
                    valorCapitalInicial:parseFloat(capital.VALOR_TITULO*numTitulos).toFixed(2),
                    distribuciones:sociosAgrupados,
                    distribucionesActuales:sociosAgrupadosActuales,
                    nTitulosFinal:parseInt(numeroTit)+parseInt(numTitulos),
                    valorFinal:parseFloat(parseFloat(valTitulo).toFixed(2))+parseFloat(parseFloat(capital.VALOR_TITULO).toFixed(2)),
                    variaciones:variaciones,
                    transmisiones:transmisiones
                })
            })
        }
        let capital=await this.props.client
        .query({
            query: gql`
            {
                capSocialActual{Valor,numTitulos,importeTotal}
            }
            `,fetchPolicy:'network-only'
        }).then(result=>{ 
            return result.data.capSocialActual
        })
        

        return capital
        

    }

    // obtenerCapital=async()=>{
     


        
    //     let numTitulos=0
    //     let socios=[]


    //     //obtenemos el capital social distribucion inicial
    //     await this.props.client 
    //     .query({
    //         query: gql`
    //             {capSocialInicialDistribucion{ID_PERSONA,NUMERO_TITULOS,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
    //         `,fetchPolicy:'network-only'              
    //     }).then(result=>{ 
    //         result.data.capSocialInicialDistribucion.forEach(socio=>{
    //             numTitulos+=socio.NUMERO_TITULOS    
    //             socios.push(socio)
    //         })
    //     })

    //     //buscamos los nombres de los socios
    //     for(let socio of socios){
            
    //         let nombre=""
    //         //Si es persona fisica...
    //         if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

    //             nombre=await this.props.client
    //             .query({
    //                 query: gql` 
    //                     {personasFisicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
    //                 `,fetchPolicy:'network-only'
    //             }).then(result=>{ 
    //                 return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //             })

    //         //Si es persona juridica
    //         }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
    //             nombre=await this.props.client
    //             .query({
    //                 query: gql`
    //                     {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
    //                 `,fetchPolicy:'network-only'
    //             }).then(result=>{ 
    //                 return result.data.personasJuridicas[0].RAZON_SOCIAL
    //             })

    //         }
    //         socio.nombre=nombre

    //     }

        
    //     //agrupacion del array de socios por ID_PERSONA
    //     let sociosAgrupados = [];
    //     socios.reduce(function(res, value) {
    //         if (!res[value.ID_PERSONA]) {
    //             res[value.ID_PERSONA] = { ID_PERSONA: value.ID_PERSONA,NOMBRE:value.nombre, NUMERO_TITULOS: 0 };
    //             sociosAgrupados.push(res[value.ID_PERSONA])
    //         }
    //         res[value.ID_PERSONA].NUMERO_TITULOS += value.NUMERO_TITULOS;
    //         return res;
    //     }, {});
        

    //     //obtencion de las varaciones
    //     let variaciones=await this.props.client 
    //     .query({
    //         query:gql`{capSocialVariaciones(ORDEN:"FECHA,ID_VARIACION,ASC"){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,fetchPolicy:'network-only'
    //     }).then(result=>{ 
    //         return result.data.capSocialVariaciones
    //     })

    //     let nTitulos=0
    //     let valorTitulo=0

    //     for(let variacion of variaciones){

    //         //variacion de incremento o decremento del número de títulos.

    //         if(variacion.ID_TIPO_VARIACION===1){
    //             //obtener las variaciones distribucion.
    //             let distribucion=await this.props.client
    //             .query({
    //                 query: gql`
    //                 {capSocialVariacionesDistribucion(FILTROS:{ID_VARIACION:${variacion.ID_VARIACION}}){ID_PERSONA,VARIACION,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
    //                 `,fetchPolicy:'network-only'
    //             }).then(result=>{
    //                 return result.data.capSocialVariacionesDistribucion
    //             })
    //             console.log(distribucion)

    //             for(let dis of distribucion){

    //                 //buscamos la persona de la variacion.
    //                 let nombre=""
    //                 nTitulos+=dis.VARIACION
    //                 //Si es persona fisica...
    //                 if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){

    //                     nombre=await this.props.client
    //                     .query({
    //                         query: gql` 
    //                             {personasFisicas(FILTROS:{ID_PERSONA:${dis.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
    //                         `,fetchPolicy:'network-only'
    //                     }).then(result=>{ 
    //                         return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //                     })

    //                 //Si es persona juridica
    //                 }else if(dis.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
    //                     nombre=await this.props.client
    //                     .query({
    //                         query: gql`
    //                             {personasJuridicas(FILTROS:{ID_PERSONA:${dis.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
    //                         `,fetchPolicy:'network-only'
    //                     }).then(result=>{ 
    //                         return result.data.personasJuridicas[0].RAZON_SOCIAL
    //                     })

    //                 }
    //                 dis.nombre=nombre
    //             }
    //             variacion.distribucion=distribucion
    //         }else{

    //             //variación del valor del título.
    //             valorTitulo+=variacion.CAMBIO_VALOR_TITULO
    //         }

    //     }

    //     //OBTENCION DE LAS TRANSMISIONES.
    //     let transmisiones=await this.props.client.query({
    //         query:gql`{capSocialTransmisiones{
    //             ID_TRANSMISION,
    //             FECHA,
    //             ORIGEN,
    //             FK_ORIGEN{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             DESTINO,
    //             FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
    //             TIPO_ACCION,
    //             NUMERO_TITULOS,
    //             NUMERACION_TITULOS
    //         }}`,fetchPolicy:'network-only'
    //     }).then(async(result)=>{
    //         return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
    //     })

    

    //     let sociosAgrupadosActuales=JSON.parse(JSON.stringify(sociosAgrupados))

    //     //por cada transmision sumamos los titulos.
    //     for(let transmision of transmisiones){
    //         await this.obtenerNombreMiembros(transmision.FK_ORIGEN)
    //         await this.obtenerNombreMiembros(transmision.FK_DESTINO)

    //         let existe=await sociosAgrupadosActuales.find(socio=>{return socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA})
    //         console.log(transmision)
    //         //si no existe el socios lo insertamos.
    //         if(!existe){
    //             sociosAgrupadosActuales.push({
    //                 "ID_PERSONA":transmision.FK_DESTINO.ID_PERSONA,
    //                 "NOMBRE":transmision.FK_DESTINO.nombre,
    //                 "NUMERO_TITULOS":transmision.NUMERO_TITULOS
    //             })
    //             console.log(sociosAgrupados)
    //         }else{

    //             await sociosAgrupadosActuales.find(socio=>{
    //                 if(socio.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
    //                     socio.NUMERO_TITULOS+=transmision.NUMERO_TITULOS
    //                 }
    //             })
    //             //si existe ya le sumamos el numero de titulos al origen
    //         }

    //         //descontamos los titulos al origen de la transmision.
    //         await sociosAgrupadosActuales.find(socio=>{
    //             if(socio.ID_PERSONA===transmision.FK_ORIGEN.ID_PERSONA){
    //                 socio.NUMERO_TITULOS-=transmision.NUMERO_TITULOS
    //             }
    //         })
    //     }

    //     console.log(sociosAgrupadosActuales)

    //     //CAP SOCIAL INICIAL. Obtención de la fecha y el valor inical del capital socila
    //     await this.props.client
    //     .query({
    //         query: gql`
    //             {capSocialInicial{FECHA_VALIDEZ,VALOR_TITULO}}
    //         `,fetchPolicy:'network-only'
    //     }).then(async result=>{ 

    //         //si tenemos capita social inicial.
    //         if(result.data.capSocialInicial.length>0){

    //             await this.setState({

    //                 numTitInicial:numTitulos,
    //                 existeCapitaSocila:true,
    //                 valorTitulolInicial:result.data.capSocialInicial[0].VALOR_TITULO,
    //                 fechaCapitalInicial:result.data.capSocialInicial[0].FECHA_VALIDEZ,
    //                 valorCapitalInicial:parseFloat(result.data.capSocialInicial[0].VALOR_TITULO*numTitulos).toFixed(2),
    //                 distribuciones:sociosAgrupados,
    //                 distribucionesActuales:sociosAgrupadosActuales,
    //                 nTitulosFinal:parseInt(nTitulos)+parseInt(numTitulos),
    //                 valorFinal:parseFloat(parseFloat(valorTitulo).toFixed(2))+parseFloat(parseFloat(result.data.capSocialInicial[0].VALOR_TITULO).toFixed(2)),
    //                 variaciones:variaciones,
    //                 transmisiones:transmisiones

    //             })

                
    //         }
    //     })
        

    //     let capital=await this.props.client
    //     .query({
    //         query: gql`
    //         {
    //             capSocialActual{Valor,numTitulos,importeTotal}
    //         }
    //         `,fetchPolicy:'network-only'
    //     }).then(result=>{ 
    //         return result.data.capSocialActual
    //     })
        

    //     return capital

    // }

    obtenerVariaciones=async()=>{

        let variaciones=await this.props.client.query({
            query:gql`{capSocialVariaciones(FILTROS:{FECHA:{DESDE:"${moment().format("YYYY")}-01-01",HASTA:"${moment().format("YYYY")}-12-31"}}){ID_VARIACION,FECHA,ID_TIPO_VARIACION,CAMBIO_VALOR_TITULO,CAMBIO_NUMERO_TITULOS}}`,
            fetchPolicy:'network-only'
        }).then(result=>{
            return result.data.capSocialVariaciones
        })

        return variaciones
    }

    obtenerNombreMiembros=(persona)=>{
        
        if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            this.props.personasFisicas.find(miembro=>{
                if(miembro.ID_PERSONA===persona.ID_PERSONA){
                    return persona.nombre= `${miembro.APELLIDO1} ${miembro.APELIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                }
            })
 
         //Si es persona juridica
         }else if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===2){
             this.props.personasJuridicas.find(miembro=>{
                 if(miembro.ID_PERSONA===persona.ID_PERSONA){
                     return persona.nombre= miembro.RAZON_SOCIAL
                 }
         })
    }
    }    

    obtenerPactos=async()=>{

        let pactos=await this.props.client.query({
            query:gql`{sociosPactosReservados{
                ID_PACTO,
                FECHA,
                TEXTO,
                SOCIOS
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.sociosPactosReservados))
        })
        let dataPersonas= await this.props.client 
        .query({
            query: gql`
                {personas{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}
            `,fetchPolicy:'network-only'             
        }).then(async result=>{ 
            return JSON.parse(JSON.stringify(result.data.personas))
        })
        

        for(let pacto of pactos){
            let personas=[]

            let miembros=pacto.SOCIOS.split(",")
            for(let miembro of miembros){
               
                let existe=personas.find(persona=>{ return persona.ID_PERSONA===parseInt(miembro)})
                  
                if(!existe){  
                 
                    let personasVal=[]
                    dataPersonas.forEach(datap=>{
                        if(datap.ID_PERSONA===parseInt(miembro)){
                            personasVal.push(datap)
                        }
                    })
                    
                    for(let persona of personasVal){
                           
                                let nombre=''
                                if(persona.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                                    this.props.personasFisicas.find(miembro=>{
                                        if(miembro.ID_PERSONA===persona.ID_PERSONA){
                                            nombre=`${miembro.APELLIDO1} ${miembro.APELLIDO2?miembro.APELLIDO2:''} ${miembro.NOMBRE}`
                                        }
                                    })
                                }else if(persona.TIPO_PERSONA.ID_TIPOS_PERSONA===2){
                                    this.props.personasJuridicas.find(miembro=>{
                                        if(miembro.ID_PERSONA===persona.ID_PERSONA){
                                            nombre=persona.RAZON_SOCIAL
                                        }
                                    })
                                }
                                persona.nombre=nombre
                                personas.push(persona)

                                                   
                        }    
                }
            }
            pacto.miembros=personas
         }

            return pactos
        }     

    obtenerObligaciones=async()=>{

         //reiniciamos el valor de las obliaciones
         this.setState({importeTotalObligaciones:0})

         let obligaciones=await this.props.client
         .query({
             query: gql`
                 {obligacionesEmitidas{
                     ID_OBLIGACION,
                     NUMERO,
                     SERIES,
                     IMPORTE_NOMINAL,
                     CONVERTIBLES
                 }}
             `,fetchPolicy:'network-only'
         }).then(result=>{ 
             return result.data.obligacionesEmitidas
         })
         let totalObligaciones=0
         let i=0
         //calculamos el importe total de las obligaciones.
         for(let obligacion of obligaciones){
            totalObligaciones+=(obligacion.NUMERO*obligacion.IMPORTE_NOMINAL)
            i++
            if(i===obligaciones.length){
                this.setState({importeTotalObligaciones:parseFloat(totalObligaciones).toFixed(2)})
            }
         }
        
     
        return obligaciones

    }   

    cargarSocios=async()=>{

       //relleno  de la lista de socios.
    
    //    return  await this.props.client
    //     .query({
    //         query: gql`
    //             {capSocialInicialDistribucion(DISTINCT:true){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
    //         `,fetchPolicy:'network-only'
    //     }).then(async result=>{ 
        let transmisiones=await this.props.client.query({
            query:gql`{capSocialTransmisiones{
                FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            }}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
        })
        let otrosDatos=await this.props.client
        .query({
            query: gql`
            {sociosOtrosDatos{
                ID_SOCIO,
                PRESTACIONES_ACCESORIAS,
                EXCLUIDO,
                VENTAJAS,
                }
            }
            `,fetchPolicy:'network-only'
        }).then(async result=>{
            return result.data.sociosOtrosDatos
        })         


            let socios=[]
            for(let socio of this.props.capSocialInicialDistribucion){
                let nombreSocio=""
                let id=0

                //Si es persona fisica...
                if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.props.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===socio.ID_PERSONA){
                            id=persona.ID_PERSONA
                            nombreSocio= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                            socios.push({"id":id,"nombre":nombreSocio})
                        }
                    })
                    // await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasFisicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}
                    //     `,fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     id=result.data.personasFisicas[0].ID_PERSONA
                    //     nombreSocio=`${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
                    //     socios.push({"id":id,"nombre":nombreSocio})
                    // })

                //Si es persona juridica
                }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.props.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===socio.ID_PERSONA){
                            id=persona.ID_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "nombre": nombreSocio})
                        }
                    })
                    // await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${socio.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     id=result.data.personasJuridicas[0].ID_PERSONA
                    //     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
                    //     socios.push({"id":id,"nombre":nombreSocio})
                    // })

                }

            }

            // let transmisiones=await this.props.client.query({
            //     query:gql`{capSocialTransmisiones{
            //         FK_DESTINO{ID_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA}}
            //     }}`,fetchPolicy:'network-only'
            // }).then(async(result)=>{
            //     return JSON.parse(JSON.stringify(result.data.capSocialTransmisiones))
            // })
           
            for(let transmision of transmisiones){
                let nombreSocio=""
                let id=0
                //Si es persona fisica...
                if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===1){
                    this.props.personasFisicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            id=persona.ID_PERSONA
                            nombreSocio= `${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
                            socios.push({"id":id,"nombre":nombreSocio})
                        }
                    })
                //Si es persona juridica
                }else if(transmision.FK_DESTINO.TIPO_PERSONA.ID_TIPO_PERSONA===2){
                    this.props.personasJuridicas.find(persona=>{
                        if(persona.ID_PERSONA===transmision.FK_DESTINO.ID_PERSONA){
                            id=persona.ID_PERSONA
                            nombreSocio=persona.RAZON_SOCIAL
                            socios.push({"id":id, "nombre": nombreSocio})
                        }
                    })
                    // await this.props.client
                    // .query({
                    //     query: gql`
                    //         {personasJuridicas(FILTROS:{ID_PERSONA:${transmision.FK_DESTINO.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL}}
                    //     `,fetchPolicy:'network-only'
                    // }).then(result=>{ 
                    //     id=result.data.personasJuridicas[0].ID_PERSONA
                    //     nombreSocio=result.data.personasJuridicas[0].RAZON_SOCIAL
                    //     socios.push({"id":id,"nombre":nombreSocio})
                    //     console.log(nombreSocio)

                    // })

                }

            }


            let socs= socios.filter((e, i) => {
                return socios.findIndex((x) => {
                return x.id ===  e.id}) === i;
            });

            for(let soc of socs){

                   //Obtenemos los socios otros datos.
                    // await this.props.client
                    // .query({
                    //     query: gql`
                    //     {sociosOtrosDatos(FILTROS:{ID_SOCIO:${soc.id}}){
                    //         ID_SOCIO,
                    //         PRESTACIONES_ACCESORIAS,
                    //         EXCLUIDO,
                    //         VENTAJAS,
                    //         }
                    //     }
                    //     `,fetchPolicy:'network-only'
                    // }).then(async result=>{ 
                        if(otrosDatos.length>0){
                            otrosDatos.find(datos=>{
                                if(datos.ID_SOCIO===soc.id){
                                    console.log(datos)
                                    soc.datosSocios= datos

                                }
                            })
                        }
                
            }
            console.log(socs)
            return socs
        
    }

    cargarDatos= async()=>{
        let tipoAdministracion=this.props.empresaPrincipal
        let pactos=await this.obtenerPactos()
        let obligaciones=await this.obtenerObligaciones()
        let socios=await this.cargarSocios()
        this.setState({
            tipoAdministracion:tipoAdministracion,
            pactosReservados:pactos,
            obligaciones:obligaciones,
            socios:socios
        })
    }

    componentDidMount(){
        this.setState({cargando:true}, async() =>{
            await this.obtenerCapital()
            await this.cargarDatos()
            this.setState({ cargando:false})           
        })    
    }
    

    generarImprimir=async()=>{
        this.setState({
            cargandoImprimir:true,
            modalImprimir:true
        }, async() =>{
            this.setState({cargandoImprimir:false}, () =>{
                ReactDOM.render(this.ImprimirES(), document.getElementById('generarImprimir-pdf'))
            })
        })
    }
    cargarDist=()=>{
        let dist=[]
        this.state.variaciones.forEach((variacion,index) => {             
            if(variacion.ID_TIPO_VARIACION===2){
                let anterior=this.valTitulo
                this.valTitulo=parseFloat(this.valTitulo)+parseFloat(variacion.CAMBIO_VALOR_TITULO)
                dist.push("-Con fecha "+moment(variacion.FECHA).format("DD-MM-YYYY")+ " el valor del título pasó de "+parseFloat(anterior).toFixed(2)+" a "+ parseFloat(this.valTitulo).toFixed(2)) 
                
            }else if(variacion.ID_TIPO_VARIACION===1){
                for(let dis of variacion.distribucion){
                    //aumentamos en el array la variacion.
                    this.distribucionesActuales.find((soc,index)=>{
                        if(soc.ID_PERSONA===dis.ID_PERSONA){
                            this.distribucionesActuales[index].NUMERO_TITULOS+=dis.VARIACION
                        }
                        return soc.ID_PERSONA===dis.ID_PERSONA
                    })
                    dist.push(`Con fecha ${moment(variacion.FECHA).format("DD-MM-YYYY")} se ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} el número de títulos en un número de ${Math.abs(dis.VARIACION)} títulos según la siguiente distribución: 
                                 -${dis.nombre} ${(parseInt(dis.VARIACION)>0?"aumentó":"decrementó")} en un total de ${Math.abs(dis.VARIACION)}`)
                    
                }
                
            }                   
        })
        return dist
    }

    ImprimirES=()=>{
        let distribucion= this.cargarDist();
        return(
            <PDFViewer style={{width: '100%', height: '100%'}}>
                <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.body}>
                        <View fixed style={styles.row}>
                        <Image
                            style={styles.imageHead}
                            src={this.props.logotipo?this.props.logotipo:pantallaInicio}
                            />
                        </View>
                        <View fixed style={styles.rowHead}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
                        </View>
                        <View fixed style={styles.rowHeadTwo}>
                            <Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                            <Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
                        </View>
                        <View style={styles.rowTitleOne}>
                            <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.informacion",{ns:"page"})}</Text>
                        </View>                   
                            
                            <View>
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.forma",{ns:"page"})}</Text>
                                </View>
                                {this.state.tipoAdministracion?this.state.tipoAdministracion.map((admin, index)=>{
                                   // console.log(admin.TIPO_SOCIEDAD)
                                   return(
                                    <View key={index}>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.tiposociedad",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{admin.TIPO_SOCIEDAD?admin.TIPO_SOCIEDAD.DESCRIPCION:''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.tipoparticipaciones",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{admin.TIPO_CAPITAL_SOCIAL?admin.TIPO_CAPITAL_SOCIAL.DESCRIPCION:''}</Text>
                                        </View>
                                        {admin.ID_TIPO_SOCIEDAD===20?
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.fechafinalizacion",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{admin.FECHA_FINALIZACION}</Text>
                                            </View>
                                        :<></>}
                                    </View>    
                                   )                              
                                }):<></>}
                            
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.capitalsocialinicial",{ns:"page"})}</Text>
                                </View> 
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.fechacapitalsocial",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.fechaCapitalInicial?moment(this.state.fechaCapitalInicial).format("DD-MM-YYYY"):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.valortitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorTitulolInicial).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.numerotitulos",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.numTitInicial}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.valorcapitalsocial",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.valorCapitalInicial} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.distribucionsocio",{ns:"page"})}:</Text>
                                </View>    
                                    {this.state.distribuciones.map((distribucion, index)=>{
                                        return(
                                            <View key={index} style={styles.row}>
                                                 <Text style={{flexDirection:'column', fontSize:8, fontFamily:'Open Sans Bold', width:'30%', marginLeft: 100}}>{distribucion.NOMBRE}:</Text>
                                                 <Text style={styles.textData}>{distribucion.NUMERO_TITULOS}, {parseFloat(this.state.valorTitulolInicial*distribucion.NUMERO_TITULOS).toFixed(2)} €  </Text>
                                            </View>
                                        )
                                    })}
                               <View style={styles.rowTitleOne}>
                    				<Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.variaciones",{ns:"page"})}</Text>
                				</View>
                                    {distribucion?distribucion.map((dist, index)=>{
                                    return(
                                        <View key={index} style={styles.row}>
                                            <Text style={{flexDirection:'row', fontSize: 8, fontFamily:'Open Sans', width:'100%'}}>{dist}</Text>
                                        </View>
                                    )
                                }):<></>}

                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.capitalsocialactual",{ns:"page"})}</Text>
                                </View> 
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.valortitulo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{parseFloat(this.state.valorFinal).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.numerotitulos",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.nTitulosFinal}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.valorcapitalsocial",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{(parseFloat(parseFloat(this.state.valorFinal).toFixed(2))*parseInt(this.state.nTitulosFinal)).toFixed(2)} €</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.distribucionsocio",{ns:"page"})}:</Text>
                                </View>  
                                {this.state.distribucionesActuales.map((dist,index)=>{
                                    return(
                                    <View style={styles.row} key={index}>
                                        <Text style={{flexDirection:'column', fontSize:8, fontFamily:'Open Sans Bold', width:'30%', marginLeft: 100}}>{dist.NOMBRE}:</Text>
                                        <Text style={styles.textData}>{dist.NUMERO_TITULOS}, {parseFloat(this.state.valorFinal*dist.NUMERO_TITULOS).toFixed(2)} € </Text>
                                    </View>
                                    )
                                })}
                                 <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.transmisiones",{ns:"page"})}</Text>
                                </View> 
                                {this.state.transmisiones?this.state.transmisiones.map((transmision, index)=>{
                                    return(
                                        <View key={index}>
                                            <Text style={{flexDirection:'row', fontSize: 8, fontFamily:'Open Sans', width:'100%'}}>{`- Con fecha ${moment(transmision.FECHA).format("DD-MM-YYYY")} ${transmision.FK_ORIGEN.nombre} trasmitió ${transmision.NUMERO_TITULOS} titulos con numeración ${transmision.NUMERACION_TITULOS} a ${transmision.FK_DESTINO.nombre}
                                        `}</Text>
                                        </View>
                                    )
                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.pactosreservados",{ns:"page"})}</Text>
                                </View> 
                                {this.state.pactosReservados?this.state.pactosReservados.map((pacto, index)=>{
                                    console.log(pacto)
                                    return(
                                        <View key={index}>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.pactosreservadosfecha",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{moment(pacto.FECHA).format("DD-MM-YYYY")}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.texto",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{pacto.TEXTO?pacto.TEXTO:''}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.socios",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{pacto.miembros.map(p=>{return p.nombre}).join(',')}</Text>
                                            </View>
                                        </View>
                                    )
                                }):<></>}
                                 <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.obligacionesemitidas",{ns:"page"})}</Text>
                                </View> 
                                {this.state.obligaciones?this.state.obligaciones.map((obligacion, index)=>{
                                    return(
                                        <View key={index}> 
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.series",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{obligacion.SERIES}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.numero",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{obligacion.NUMERO}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.importenominal",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{obligacion.IMPORTE_NOMINAL} €</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.importetotal",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{parseFloat(obligacion.IMPORTE_NOMINAL*obligacion.NUMERO).toFixed(2)}€</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.convertibles",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{obligacion.CONVERTIBLES?this.props.i18next.t("yes",{ns:"global"}).toUpperCase():this.props.i18next.t(this.props.i18next.t("no",{ns:"global"}).toUpperCase(),{ns:"global"}).toUpperCase()}</Text>
                                            </View>
                                        </View>
                                    )
                                }):<></>}
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.totalobligaciones",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.importeTotalObligaciones} €</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{this.props.i18next.t("imprimirestructurasocietaria.otrosdatos",{ns:"page"})}</Text>
                                </View> 
                                {this.state.socios?this.state.socios.map((socio, index)=>{
                                    console.log(socio)
                                    return(
                                        <View key={index}> 
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.socio",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{socio.nombre}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.ventajas",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{socio.datosSocios?socio.datosSocios.VENTAJAS:''}</Text>
                                            </View>
                                            <View style={styles.row}>
                                                <Text style={styles.labelData}>{this.props.i18next.t("imprimirestructurasocietaria.excluido",{ns:"page"})}:</Text>
                                                <Text style={styles.textData}>{socio.datosSocios && socio.datosSocios.EXCLUIDO?this.props.i18next.t("yes",{ns:"global"}).toUpperCase():this.props.i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                            </View>
                                            
                                        </View>
                                    )
                                }):<></>}                                                 
                            </View>  
                    
                </View>

            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
            />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
        </Page>
        </Document>
    </PDFViewer> 
    )
}



    render() {
        
        document.title=this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("imprimirestructurasocietaria.title",{ns:"page"})

        this.valTitulo=this.state.valorTitulolInicial
        this.distribucionesActuales=this.state.distribucionesActuales
    

        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimir:false
            })
        }

        return (
            <>
            {this.state.cargando===true?
                <Cargando/>
            :
            <>
                 <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id='generarImprimir-pdf'>
                            </div>
                            :
                                <Spinner />
                        }
                </Dialog>        
              
        
            <div>
                <Card interactive={true} elevation={Elevation.TWO} id="informe-anual">
                <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("imprimirestructurasocietaria.title",{ns:"page"})}</Callout>
                    <FormGroup
                        label={this.props.i18next.t("generatedocument",{ns:"global"})+":"}
                        labelFor="informacion"
                        inline={true}
                    >
                        {!this.state.cargando
                        ?
                        <Button className="bp3-minimal" icon="print" active={this.state.imprimirActivado} onClick={()=>this.generarImprimir()}>{this.props.i18next.t("imprimirestructurasocietaria.button",{ns:"page"})}</Button>
                        :
                            <></>
                        }

                    </FormGroup>
                </Card>
            </div>
            </>
            }
          </>    
        )
    }
}

export default withApollo(ImprimirInformacionEstructuraSocietaria)