import React, { Component } from 'react';
import {Elevation, Callout, Card, Tabs, Tab, Navbar, ButtonGroup, AnchorButton,Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, Intent, Spinner } from "@blueprintjs/core"
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import InformacionGeneralNuevo from "../Pantallas/Elementos/InformacionGeneralNuevo"
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { withApollo } from 'react-apollo';
import gql from "graphql-tag";
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

import styles from './stylesPrint';

class FondosInversion extends Component {

    state={
        modalNuevoAbierto: false,
        nuevoActivado:true,
        editarActivado: false,
        borrarActivado: false,
        buscarActivado:false,
        homologado: false,
        fondoSeleccionado:null,
        datosPersonaJuridica:null,
        datosContactos:null,
        clientes: [
        ],
        empresaSeleccionada:null,
        empresas:[],
        cargando:false,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirG:false,
        modalImprimirG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
        cargandoGrid:false
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    obtenerNombresFondos=async(fondo)=>{
        // eslint-disable-next-line array-callback-return
        this.state.personasJuridicas.find((persona)=>{
            if (persona.ID_PERSONA===fondo.ID_FONDO_INVERSION) {
                fondo.nombre=`${persona.RAZON_SOCIAL}`
                fondo.ID_PERSONA=`${persona.ID_PERSONA}`
            }
        }) 
        
    }

    cargarPersonas=async()=>{

        this.setState({cargando:true})

        let fondos=await this.props.client
        .query({
            query: gql`
                {fondosInversiones{ID_FONDO_INVERSION}}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            return JSON.parse(JSON.stringify(result.data.fondosInversiones))
        })

        for(let fondo of fondos){
            await this.obtenerNombresFondos(fondo)
        }   
        this.setState({
            clientes:fondos,
            cargando:false
        })
        let personas=[]
        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }
        
        this.setState({empresas:personas})
    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     
    generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true,
            fondoSeleccionado:null,
            cargandoGrid:true
        }, async () => {
             this.setState({cargandoImprimirG:false}, () => {
                 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
             })
        })
    }

    
    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("fondosinversion.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("fondosinversion.fondos",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.clientes?this.state.clientes.map((cliente, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{cliente.nombre?cliente.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }
    //Carga de las empresas
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            this.setState({cargandoForm: false})
        }); 
    }

    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("fondosinversion.title",{ns:"page"})
        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            //console.log("abrir modal");
            this.setState({
                modalNuevoAbierto: true,
                nuevoActivado:true,
                editarActivado:false,
                borrarActivado:false,
                buscarActivado:false,
                cargandoGrid:true
            })
        }

        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //console.log("abrir modal");
            if(!this.state.fondoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalEditarPersonaJuridica: true,
                nuevoActivado:false,
                editarActivado:true,
                borrarActivado:false,
                buscarActivado:false,
                cargandoGrid:true
        
            })
        }

        const abrirmodalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            //console.log("abrir modal");
            if(!this.state.fondoSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            this.setState({
                modalBorrarAbierto: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:true,
                buscarActivado:false,
                cargandoGrid:true
            })
        }

        const columns = [
            {
            dataField: 'nombre',
            text: i18next.t("name",{ns:"global"})
            }
        ];

        const handleOnSelect2=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            empresaSeleccionada: row
        });
        }
        return true;
        }
        
        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
        fondoSeleccionado: row
        });
        }
        return true;
        }
        
        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                modalEmpresas:false,
                cargandoGrid:false,
                fondoSeleccionado:null
            })
        }

        
        const guardarPersonaJuridica=async ()=>{


             
 
            

            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.setState({modalNuevoAbierto:false})
            if(this.informacion.state.personaCreada){
                //creamos el fondo inversion.
                await this.props.client.mutate({
                    mutation:gql`mutation{crearFondoInversion(VALORES:{ID_FONDO_INVERSION:${this.informacion.state.personaCreada}}){ID_FONDO_INVERSION}}`
        
                }).then(async result=>{
                
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
                    await this.cargarPersonas()

                    cerrarModal()       
               
                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
              
            }
           
        }

        const abrirModalEmpresas=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalEmpresas:true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:false,
                buscarActivado:true,
                cargandoGrid:true
            
            })
        }

        const seleccionarEmpresa=async()=>{

            if(!this.state.empresaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            //comprobar que no esta en la lista de fondos.
            let existe=this.state.clientes.find(cliente=>{return cliente.ID_FONDO_INVERSION===this.state.empresaSeleccionada.ID_PERSONA})
            if(existe){
                alert(i18next.t("fondosinversion.valid",{ns:"page"}))
                return
            }
            //creamos el fondo inversion.
            await this.props.client.mutate({
                mutation:gql`mutation{crearFondoInversion(VALORES:{ID_FONDO_INVERSION:${this.state.empresaSeleccionada.ID_PERSONA}}){ID_FONDO_INVERSION}}`
    
            }).then(async result=>{
               
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                cerrarModal()       
                await this.cargarPersonas()

                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
        }

        const columns2 = [
        {
        dataField: 'label',
        text: i18next.t("name",{ns:"global"})
        }
        ];

        const eliminarFondo=()=>{
            
            //creamos el fondo inversion.
            this.props.client.mutate({
                mutation:gql`mutation{eliminarFondoInversion(ID_FONDO_INVERSION:${this.state.fondoSeleccionado.ID_FONDO_INVERSION}){ID_FONDO_INVERSION}}`
    
            }).then(async result=>{
               
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                cerrarModal()       
                await this.cargarPersonas()
                this.setState({
                    fondoSeleccionado:null,
                    cargandoGrid:false
                })
           
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirG:false,
                cargandoGrid:false
            })
        }

        const cerrarModal2 = async () => {
            this.setState({
                modalEditarPersonaJuridica:false,
                cargandoGrid:false,
                fondoSeleccionado:null
            },()=>{
                this.cargarPersonas()
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("propcompany",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalNuevoAbierto}
                    className="datos-empresa-dialog"
                >
                    <Tabs id="datos-empresa"
                        animate={this.animacion} 
                        onChange={this.cambiarPestaña} 
                        selectedTabId={this.state.pestaña}
                    >
                        <Tab id="informacion-general" title="Información general" panel={ <InformacionGeneralNuevo   ref={(input) => { this.informacion= input; }} i18next={i18next}/> } />
                    </Tabs>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                        <Button
                            alignText="left"
                            icon="floppy-disk"
                            text={i18next.t("guardar",{ns:"global"})}
                            intent="danger"
                            onClick={guardarPersonaJuridica}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                    <Tooltip content={i18next.t("close",{ns:"global"})} position="top">
                        <Button
                            alignText="left"
                            icon="cross"
                            text={i18next.t("close",{ns:"global"})}
                            intent="danger"
                            onClick={cerrarModal}
                            className="iconoIzquierda bp3-minimal"
                        />
                    </Tooltip>
                        
                    </div>
                </div>  
                </Dialog>
                <Dialog transitionDuration={400}
                        autoFocus={true}
                        enforceFocus={true}
                        icon="warning-sign"
                        onClose={cerrarModal}
                        title={i18next.t("delete",{ns:"global"})}
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        isCloseButtonShown={true}
                        usePortal={true}
                        isOpen={this.state.modalBorrarAbierto}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <h2>{i18next.t("fondosinversion.questiondel",{ns:"page"})}</h2>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                                <AnchorButton
                                    intent={Intent.PRIMARY}
                                    onClick={eliminarFondo}
                                >
                                    {i18next.t("yes",{ns:"global"})}
                                </AnchorButton>
                            </Tooltip>
                            <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                                <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                            </Tooltip>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("agregar",{ns:"global"})+" "+i18next.t("fondosinversion.empresa",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalEmpresas}
                >
                    <div className={Classes.DIALOG_BODY}>
                    <div className="pixelAlrededorGrande">
                    <Card>
                    <BootstrapTable
                    keyField='ID_PERSONA'
                    data={ this.state.empresas }
                    columns={ columns2 }
                    selectRow={ const_table.selectRow(handleOnSelect2) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    </Card>
                    </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <AnchorButton intent={Intent.DANGER} onClick={seleccionarEmpresa}>
                            {i18next.t("aceptar",{ns:"global"})}
                        </AnchorButton>
                        <AnchorButton intent={Intent.DANGER} onClick={cerrarModal}>
                            {i18next.t("cancelar",{ns:"global"})}
                        </AnchorButton>
                    </div>
                    </div>
                </Dialog>
           
            <Dialog
                transitionDuration={400}
                autoFocus={true}
                enforceFocus={true}
                icon="info-sign"
                onClose={cerrarModal2}
                title={i18next.t("propcompany",{ns:"global"})}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isCloseButtonShown={true}
                usePortal={true}
                isOpen={this.state.modalEditarPersonaJuridica}
                className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.fondoSeleccionado?this.state.fondoSeleccionado:null}
                i18next={i18next}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text={i18next.t("close",{ns:"global"})}
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
            <div className="pixelAlrededorGrande tablaSesenta">
                <Card>
                    <Callout className="bp3-intent-danger">{i18next.t("fondosinversion.title",{ns:"page"})}</Callout>
                    {this.state.cargandoGrid?
                        <Cargando/>
                    :
                    <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado}  onClick={abrirModalEditar} />
                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado} onClick={abrirmodalBorrar} />
                                <Button className="bp3-minimal" icon="geosearch" text={i18next.t("agregar",{ns:"global"})+" "+i18next.t("fondosinversion.empresa",{ns:"page"})} onClick={abrirModalEmpresas} active={this.state.buscarActivado} />
                                <NavbarDivider />
                                <Tooltip 
                                    content={i18next.t("print",{ns:"global"})} 
                                    position="right" 
                                >
                                    <Button 
                                        className="bp3-minimal" 
                                        icon="print" 
                                        text={i18next.t("print",{ns:"global"})} 
                                        onClick={()=>this.generarImprimirG()}
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                        
                    </Navbar>
                    <BootstrapTable
                    keyField='ID_FONDO_INVERSION'
                    data={ this.state.clientes }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    </>
                    }
                    <br />
                </Card>
            </div>
            <div className="pixelAlrededorGrande tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("fondosinversion.titleley",{ns:"page"})}</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/l/2003/11/04/35/con", "_blank")}>{i18next.t("fondosinversion.articulo",{ns:"page"})}</AnchorButton>
                    <br />
                </Card>
            </div>
            </>
            }
            </>
        )
    }
}

export default withApollo(FondosInversion)