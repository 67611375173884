/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Elevation, Callout, Card,Popover,Navbar, ButtonGroup,FormGroup,Alignment, Button, NavbarDivider, Dialog, Classes, Tooltip, AnchorButton, Intent, Spinner } from "@blueprintjs/core"
import Select from "react-select"
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import InformacionGeneralNuevo from  "../Pantallas/Elementos/InformacionGeneralNuevo"
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import styles from './stylesPrint';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

class SociosGestores extends Component {

    state={
        modalNuevoAbierto: false,
        editarActivado: false,
        borrarActivado: false,
        homologado: false,
        abierto: false,
        value: null,
        enabled: true,
        personasSeleccionada:null,
        sociosGestores:[], 
        modalBusqueda:false,
        personas:[],
        tablaActas:false,
        socioSeleccionado:null,
        cargando:false,
        cargandoForm:false,
        datosEmpresa:null,
        cargandoImprimirG:false,
        modalImprimirG: false,
        logotipo:null,
        personasFisicas:null,
        personasJuridicas:null,
        cargandoGrid: false
    }

    cambiarPestaña = (navbarTabId) => this.setState({ pestaña: navbarTabId });

    
    obtenerNombresocios=async(socio)=>{
        if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
            //obtenemos el nombre
            this.state.personasFisicas.find((persona)=>{
                if (persona.ID_PERSONA===socio.ID_PERSONA) {
                    socio.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
                }
            }) 

        }else if(socio.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){

            this.state.personasJuridicas.find((persona)=>{
                if (persona.ID_PERSONA===socio.ID_PERSONA) {
                    socio.nombre=`${persona.RAZON_SOCIAL}`
                }
            }) 

        }
        
    }


    cargarSociosGestores=async()=>{

        this.setState({cargando:true})

        let socios=await this.props.client.query({
            query:gql`{sociosGestores{ID_SOCIO_GESTOR,PERSONA{ID_PERSONA,ID_TIPO_PERSONA,TIPO_PERSONA{ID_TIPO_PERSONA,DESCRIPCION}}}}`,
            fetchPolicy:'network-only'
        }).then((result)=>{
            return JSON.parse(JSON.stringify(result.data.sociosGestores))
        })

        if(!socios || socios.length===0){
            this.setState({sociosGestores:[]})
            this.setState({cargando:false})
            return
        }

        let index=0
        for(let socio of socios){
            socios[index].ID_PERSONA=socios[index].ID_SOCIO_GESTOR
            await this.obtenerNombresocios(socio)
            index++
        }

        this.setState({
            sociosGestores:socios,
            cargando:false
        })

    }

    obtenerLogoEmpresa=async()=>{
        //obtencion logo de la empresa
         await this.props.client
         .query({
             query: gql`
                 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
             `,fetchPolicy:'network-only'             
         }).then(result=>{ 
             if(result.data.adjuntos.length>0){
    
                 var request = require('request').defaults({ encoding: null,withCredentials:true });
    
                 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                     if (!error && response.statusCode === 200) {
                     let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                     this.setState({logotipo:data})
                     }
                 });
    
             }
         })
     
     }

     
    generarImprimirG= async() => {
        this.setState({
            cargandoImprimirG:true,
            modalImprimirG:true,
            cargandoGrid:true
        }, async () => {
             this.setState({cargandoImprimirG:false}, () => {
                 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
             })
        })
    }

    
    ImprimirG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("sociosgestores.title",{ns:"page"}).toUpperCase()}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("sociosgestores.socios",{ns:"page"}).toUpperCase()}</Text>
                </View>
                    {this.state.sociosGestores?this.state.sociosGestores.map((socio, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{socio.nombre?socio.nombre:''}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )

    cargarDatos=async()=> {
        let datosEmpresa= await this.cargarEmpresa()
        let personasFisicas=await this.cargarPersonasFisicas()
        let personasJuridicas=await this.cargarPersonasJuridicas()
        
        this.setState({
            datosEmpresa:datosEmpresa,
            personasFisicas: personasFisicas,
            personasJuridicas: personasJuridicas,
        })

    }

    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
                }

            }
            
        })

       return datosEmpresa
   
   }

    cargarPersonasFisicas= async () => {
        let personasFisicas=this.props.client
        .query({
            query: gql` 
            {personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{ 
         return result.data.personasFisicas;
        })
        
        return personasFisicas;
       
    }
    
    cargarPersonasJuridicas= async () => {
        let personasJuridicas=this.props.client
        .query({
            query: gql`
            {personasJuridicas{ID_PERSONA,RAZON_SOCIAL,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}}}
            `,
            fetchPolicy: 'network-only'

       }).then(result=>{
         return result.data.personasJuridicas;
        })
        
        return personasJuridicas;
        
    }

    cargarPersonas=async ()=>{

        let personas=[]

        for(let persona of this.state.personasFisicas){
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        }

        for(let persona of this.state.personasJuridicas){
            personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
        }

        this.setState({personas:personas})
    }

        
   
    componentDidMount(props){
        this.setState({
            cargandoForm: true
        },async () => {
            await this.obtenerLogoEmpresa()
            await this.cargarDatos()
            await this.cargarPersonas()
            await this.cargarSociosGestores()
            this.setState({cargandoForm: false})
        }); 
    }

    
    
    render() {

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("sociosgestores.title",{ns:"page"})

        const abrirModalBusqueda = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                personasSeleccionada:null,
                modalBusqueda: true,
                nuevoActivado:false,
                editarActivado:false,
                borrarActivado:false,
                busquedaActivado:true,
                cargandoGrid:true
            })
        }

        const abrirModalBorrar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }
            
            if(!this.state.socioSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({
                modalBorrarAbierto: true,
                nuevaPresonaActivado:false,
                editarPersonaActivado:false,
                borrarActivado:true,
                busquedaActivado:false,
                cargandoGrid:true
            })
        }

        const columns = [
            {
            dataField: 'nombre',
            text: i18next.t("name",{ns:"global"})
            }
        ];
        
        const cerrarModal = () => {
            this.setState({
                modalNuevoAbierto: false,
                modalEditarAbierto: false,
                modalBorrarAbierto: false,
                modalBusqueda:false,
                socioSeleccionado: null,
                cargandoGrid:false
            })
        }

        
        const handleSeleccionarPersona=(value)=>{

            this.setState({personasSeleccionada:value})

        }

        
        const anadirPersona=async()=>{

            //comporbar que no este ya insertada
            let existe=this.state.sociosGestores.find(socio=>{return socio.ID_SOCIO_GESTOR===this.state.personasSeleccionada.value})
         
            if(existe){
                alert(i18next.t("yaexiste",{ns:"global"}))
                return
            }
            
            await this.props.client.mutate({
                mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.state.personasSeleccionada.value}}){ID_SOCIO_GESTOR}}`
            }).then(async result=>{
             
                alert(i18next.t("insertcorrecto",{ns:"global"}))
         
                await this.cargarSociosGestores()

                
            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            cerrarModal()
        }

        const eliminarSocio = () => {

            this.props.client.mutate({
                mutation:gql`mutation{eliminarSocioGestor(ID_SOCIO_GESTOR:${this.state.socioSeleccionado.ID_SOCIO_GESTOR}){ID_SOCIO_GESTOR}}`
            }).then(result=>{
             
                alert(i18next.t("deletecorrecto",{ns:"global"}))
                this.setState({
                    socioSeleccionado:null,
                    cargandoGrid:false
                })
                this.cargarSociosGestores()

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

            cerrarModal()
        };

        const handleOnSelect=(row, isSelect) => {
        if (isSelect) {
        this.setState({
            socioSeleccionado: row
        });
        }
        return true;
        }

        const crearPersona = async () => {
            await this.datosPersona.crearPersona();
            if (this.datosPersona.state.idPersonaCreada) {
                
                await this.cargarPersonas();
                //creamos el socios gestor/*
                await this.props.client.mutate({
                    mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.datosPersona.state.idPersonaCreada}}){ID_SOCIO_GESTOR}}`
                }).then(async result=>{
                 
                    alert(i18next.t("insertcorrecto",{ns:"global"}))
             
                    await this.cargarSociosGestores()

                }).catch(err=>{
                    alert(i18next.t("error",{ns:"global"}))
                })
                //cerrar el modal
                cerrarModal2();
            }
        };
      
        const actualizarPersona = async () => {
            await this.datosPersona.actualizarPersona();
            if (this.datosPersona.state.idPersonaActualizada) {
                await this.cargarPersonas();
                await this.setState({modalPersonaAbierto:false})
                await this.cargarSociosGestores()
            }
        };

        const abrirPopover = () => {
            
            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                this.setState({popoverAbierto: false})
                return
            }else{
                this.setState({popoverAbierto: true})
            }

        }
      
        const Botonera = () => {
            return(
                <>
                    <Button className="bp3-minimal" icon="new-link" text={i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                    <br />
                    <Button className="bp3-minimal" icon="git-new-branch" text={i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
                </>
            )
        }

        const abrirModalNuevaPersona=()=>{

            //abrir modal de persona física
            this.setState({modalPersonaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({borrarActivado:false})
            this.setState({busquedaActivado:false})
            this.setState({socioSeleccionado:null,cargandoGrid:true})

        }

        const abrirModalNuevaPersonaJurídica=()=>{

            //abrir modal de persona jurídica
            this.setState({modalNuevaJuridicaAbierto:true})
            this.setState({popoverAbierto:false})
            this.setState({nuevaPresonaActivado:true})
            this.setState({editarPersonaActivado:false})
            this.setState({borrarActivado:false})
            this.setState({busquedaActivado:false})
            this.setState({socioSeleccionado:null,cargandoGrid:true})

        }

        const abrirModalEditarPersona=()=>{

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.socioSeleccionado){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }

            this.setState({nuevaPresonaActivado:false,cargandoGrid:true})
            this.setState({borrarActivado:false})
            this.setState({editarPersonaActivado:true})
                //si es personas física
            if(this.state.socioSeleccionado.PERSONA.ID_TIPO_PERSONA===1){
                this.setState({modalPersonaAbierto:true})

                //si es persona jurídica
            }else if(this.state.socioSeleccionado.PERSONA.ID_TIPO_PERSONA===2){
                this.setState({modalEditarPersonaJuridica:true})
            }


        }

        const guardarPersonaJuridica=async ()=>{

            
            let result=await this.informacion.handleGuardarDatos()
            if(!result)return
            await this.cargarPersonas()
            
            await this.props.client.mutate({
                mutation:gql`mutation{crearSocioGestor(VALORES:{ID_SOCIO_GESTOR:${this.informacion.state.personaCreada}}){ID_SOCIO_GESTOR}}`
            }).then(async result=>{
             
                alert(i18next.t("insertcorrecto",{ns:"global"}))
                await this.cargarSociosGestores()
                
            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })
            
            await this.setState({modalNuevaJuridicaAbierto:false})

        }

        const cerrarModal2 = async () => {
            await this.cargarPersonas()
            if(this.state.personaSeleccionada && this.state.personaSeleccionada.tipo===2){
                let personaSel=this.state.personas.find(persona=>{return persona.value===this.state.personaSeleccionada.value})
                this.setState({personaSeleccionada:personaSel})
                this.setState({modalNuevaJuridicaAbierto:false})
            }
            this.cargarSociosGestores()
            this.setState({
                modalEditarPersonaJuridica:false,
                modalPersonaAbierto:false,
                cargandoGrid:false,
                socioSeleccionado:null
            })
        }

        const cerrarModalImprimir= () => {
            this.setState({
                modalImprimirG:false,
                cargandoGrid:false,
                socioSeleccionado:null
            })
        }

        return (
            <>
            {this.state.cargandoForm===true?
                <Cargando/>
            :
            <>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title="Imprimir socios gestores"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="warning-sign"
                    onClose={cerrarModal}
                    title={i18next.t("delete",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBorrarAbierto}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <h2>{i18next.t("sociosgestores.questiondel",{ns:"page"})}</h2>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("delete",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={eliminarSocio}
                            >
                                {i18next.t("yes",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("no",{ns:"global"})}</Button>
                        </Tooltip>
                    </div>
                </div>
            </Dialog>
                 <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("sociosgestores.personas",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalBusqueda}
                >
                    <div className="pixelAlrededor">
                        <FormGroup
                            label={i18next.t("sociosgestores.personas",{ns:"page"})+":"}
                            labelFor="personas"
                            fill={true}
                            intent="danger"
                            helperText={!this.state.personasSeleccionada?i18next.t("selectempty",{ns:"global"})+i18next.t("sociosgestores.personas",{ns:"page"}):""}
                        >
                        <div style={!this.state.personasSeleccionada?{border:"1px solid red"}:{}}>
                            <Select
                                isClearable
                                isSearchable
                                name="socios"
                                onChange={handleSeleccionarPersona}
                                options={this.state.personas}
                                value={this.state.personasSeleccionada}
                            />
                            </div>
                        </FormGroup>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={i18next.t("agregar",{ns:"global"})} position="top">
                            <AnchorButton
                                intent={Intent.PRIMARY}
                                onClick={anadirPersona}
                            >
                                {i18next.t("agregar",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <Dialog
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal2}
                    title={i18next.t("propiedadpersona",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    className="dialog-ancho"
                    isOpen={this.state.modalPersonaAbierto}
                >
                    <DatosPersonasFisicas
                        datosPersona={
                        this.state.socioSeleccionado
                            ? this.state.socioSeleccionado.ID_SOCIO_GESTOR
                            : null
                        }
                        ref={input => {
                        this.datosPersona = input;
                        }}
                        i18next={i18next}
                    />
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                        <Button onClick={cerrarModal2}>{i18next.t("close",{ns:"global"})}</Button>
                    </Tooltip>
                    <Tooltip
                        content={
                        this.state.nuevaPresonaActivado
                            ? i18next.t("agregar",{ns:"global"})
                            : i18next.t("edit",{ns:"global"})
                        }
                        position="top"
                    >
                        <AnchorButton
                        intent={Intent.PRIMARY}
                        onClick={
                            this.state.nuevaPresonaActivado
                            ? crearPersona
                            : actualizarPersona
                        }
                        >
                        {this.state.nuevaPresonaActivado
                            ? i18next.t("agregar",{ns:"global"})
                            : i18next.t("edit",{ns:"global"})}
                        </AnchorButton>
                    </Tooltip>
                    </div>
                </div>
        </Dialog>
        <Dialog
            transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title={i18next.t("propcompany",{ns:"global"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
            >
            <DatosPersonasJuridicas
                animacion={this.animacion}
                cambiarPestaña={this.cambiarPestaña}
                pestaña={this.state.pestaña}
                empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado:null}
                i18next={i18next}
            />
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS} />
                <ButtonGroup>
                    <Button 
                        alignText="right"
                        icon="log-out"
                        intent="danger"
                        text={i18next.t("close",{ns:"global"})}
                        onClick={cerrarModal2}
                    />
                </ButtonGroup>
            </div>
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title={i18next.t("propcompany",{ns:"global"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={i18next}/>  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text={i18next.t("guardar",{ns:"global"})}
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
            <div className="tablaSesenta pixelAlrededor">
                <Card>
                    <Callout className="bp3-intent-danger">{i18next.t("sociosgestores.title",{ns:"page"})}</Callout>
                    {this.state.cargandoGrid?
                        <Cargando/>
                    :
                    <>
                    <Navbar>
                        <Navbar.Group>
                            <ButtonGroup align={Alignment.LEFT}>
                                <Popover content={<Botonera />} transitionDuration={400} target={<Button className="bp3-minimal" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                                <Button className="bp3-minimal" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarPersonaActivado}  onClick={abrirModalEditarPersona} />
                                <Button className="bp3-minimal" icon="delete" text={i18next.t("delete",{ns:"global"})} active={this.state.borrarActivado}  onClick={abrirModalBorrar}/>
                                <Button className="bp3-minimal" icon="geosearch" text={i18next.t("agregar",{ns:"global"})+" "+i18next.t("sociosgestores.personas",{ns:"page"})} active={this.state.busquedaActivado} onClick={abrirModalBusqueda} />
                                <NavbarDivider />
                                <Tooltip 
                                    content={i18next.t("print",{ns:"global"})}
                                    position="right" 
                                >
                                    <Button 
                                        className="bp3-minimal" 
                                        icon="print" 
                                        text={i18next.t("print",{ns:"global"})} 
                                        onClick={()=>this.generarImprimirG()}
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Navbar.Group>
                    </Navbar>
                    <BootstrapTable
                    keyField='ID_SOCIO_GESTOR'
                    data={ this.state.sociosGestores }
                    columns={ columns }
                    selectRow={ const_table.selectRow(handleOnSelect) }
                    pagination={ paginationFactory(const_table.options) }
                    headerClasses="header-class"
                    rowClasses="row-class"
                    hover
                    condensed
                    noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                    />
                    </>
                    }
                    <br />
                </Card>
            </div>
            <div className="pixelAlrededor tablaCuarenta">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{i18next.t("sociosgestores.titleley",{ns:"page"})}</Callout>
                    <br />
                    <AnchorButton onClick={()=> window.open("https://www.boe.es/eli/es/l/2014/11/12/22/con", "_blank")}>{i18next.t("sociosgestores.articulo",{ns:"page"})}</AnchorButton>
                    <br />
                </Card>
            </div>
            </>
            }
            </>
        )
    }
}
export default withApollo(SociosGestores)