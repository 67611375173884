import React, { Component } from 'react';
import {Spinner, Tab, Tabs, Card, Elevation, Navbar, ButtonGroup, Alignment, Button, Dialog,  Classes, Tooltip, AnchorButton, Collapse } from "@blueprintjs/core"
import Denunciante from "../Pantallas/Elementos/Denunciante"
import Denunciado from "../Pantallas/Elementos/Denunciado"
import DatosDenuncia from "../Pantallas/Elementos/DatosDenuncia"
import Instruccion from "../Pantallas/Elementos/Instruccion"
import Diligencias from "../Pantallas/Elementos/Diligencias"
import Desestimacion from "../Pantallas/Elementos/Desestimacion"
import Resolucion from "../Pantallas/Elementos/Resolucion"
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import iconoFooter from '../../Assets/lc.png';
import { Cargando } from "./Cargando";
import ReactDOM from 'react-dom';
import { 
    PDFViewer,
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import gql from "graphql-tag";
import { withApollo } from 'react-apollo';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './stylesPrint';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";

class Denuncias extends Component {

    state = {
        nuevoActivado: false,
        editarActivado: false,
        modalAbierto:false,
        denunciaSeleccionada:null,
        denuncias: [
        ],
        idUsuarioActual:null,
        abierto: false,   
        telefonoInvalido:false,
        dniInvalido:false,
        correoInvalido:false,
        denunciaActualizada:null,
        pasoGuia:0,
        steps: [
            {
                target: '#contenedor-denuncias',
                content: "La siguiente pantalla se útiliza para registrar las diferentes denuncias que ocurran en la empresa.",
                selector: '#contenedor-denuncias',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:0, 
            },  
            {
                target: '#btn-crear-denuncia',
                content: "Para introducir una nueva denuncia haga clic en 'Nuevo'.",
                selector: '#btn-crear-denuncia',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:1, 
            },  
            {
                target: '#bp3-tab-title_denuncias_denunciante',
                content: "En la pestaña 'Denunciante', debe insertar los datos de la persona denunciantes: nombre y apellidos, documento identificativo, teléfono, correo electrónico, indicador de de si el denunciante es testigo directo o no y la relación de la empresa.",
                selector: '#bp3-tab-title_denuncias_denunciante',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:2, 
            },  
            {
                target: '#btn-agregar-relacion',
                content: "Si la relación no existiese en el deplegable, lo podremos crear.",
                selector: '#btn-agregar-relacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:3, 
            },  
            {
                target: '#bp3-tab-title_denuncias_denunciado',
                content: "La pestaña 'Denunciado', contiene los datos del denunciado: nombre y apellidos y relación con la empresa.",
                selector: '#bp3-tab-title_denuncias_denunciado',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:4, 
            },  
            {
                target: '#bp3-tab-title_denuncias_datos-denuncia',
                content: "La pestaña 'Datos de la denuncia', contiene los datos de la misma: fecha de la denuncia, fecha en la que ocurrieron los hechos, medios de recepción, fecha de información al denunciado (si se ha informado), medio de información al denunciado y hechos denunciados.",
                selector: '#bp3-tab-title_denuncias_datos-denuncia',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:5, 
            },  
            {
                target: '#bp3-tab-title_denuncias_instruccion',
                content: "La pestaña 'Instruccion', sirve para llevar un registro de la instrucción de la denuncia",
                selector: '#bp3-tab-title_denuncias_instruccion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:6, 
            }, 
            {
                target: '#boleano-comenzar-instruccion',
                content: "Cuando comience la instrucción debe marcar la casilla '¿Comenzada la instrucción?'. Rellene entoces la fecha de comienzo de la instrucción y el estado de la misma.",
                selector: '#boleano-comenzar-instruccion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:7, 
            }, 
            {
                target: '#btn-crear-estado',
                content: "Si el estado de la instrucción no existiese, podremos crearlo.",
                selector: '#btn-crear-estado',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:8, 
            }, 
            {
                target: '#botones-riesgos-instruccion',
                content: "Puede agregar o quitar los riesgos relacionados con la denuncia. Rellene también el motivo y las conclusiones de la denuncia.",
                selector: '#botones-riesgos-instruccion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:9, 
            },
            {
                target: '#bp3-tab-title_denuncias_diligencias',
                content: "La pestaña 'Diligencias' sirve para llevar un registro de las diligencias acometidas durante la instrucción de la denuncia. Esta pestaña estará habilitada, una vez que comienze la instrucción de la denuncia y se guarden los cambios.",
                selector: '#bp3-tab-title_denuncias_diligencias',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:10, 
            },
            {
                target: '#btn-crear-diligencia',
                content: "Utilize el botón 'Nuevo' para crear una nueva diligencia.",
                selector: '#btn-crear-diligencia',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:11, 
            },
            {
                target: '#modal-diligencias',
                content: "Rellene los datos correspondientes y pulse 'Aceptar'.",
                selector: '#modal-diligencias',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:12, 
            },
            {
                target: '#booleano-desestimada',
                content: "Si la denuncia se ha desestimada, vaya a la pestaña 'Desestimación' y marque la casilla 'Dar la denuncia por desestimada'.",
                selector: '#booleano-desestimada',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:13, 
            },
            {
                target: '#datos-desestimacion',
                content: "Rellene entoces la fecha de desestimación, la fecha y el medio por el que se ha informado al denunciante y el motivo de la desestimación.",
                selector: '#datos-desestimacion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:14, 
            },
            {
                target: '#boleano-resolucion',
                content: "Si la denuncia ha sido resuelta correctamente, vaya a la pestaña 'Resolución' y marque la casilla 'Dar la denuncia por resuelta'.",
                selector: '#boleano-resolucion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:15, 
            },
            {
                target: '#datos-resolucion',
                content: "Rellene entoces la fecha de resolución, la persona responsable de la misma, los riesgos afectados y las decisiones tomadas.",
                selector: '#datos-resolucion',
                position: 'auto',
                disableBeacon: true,
                type: 'hover',
                isFixed: true,
                stepIndex:16, 
            },
            
        ],
        modalImprimirDG:false,
        modalImprimirD:false,
        cargandoImprimirDG:false,
        cargandoImprimirD:false,
        logotipos:null,
        datosEmpresa:[],
        personas:null,

    }

    cargarPersonas=async()=>{
        
        let personas=[]

        await this.props.client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2}}`
        }).then(async(result)=>{
            result.data.personasFisicas.forEach(async(persona)=>{
             
                personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`}) 

            })
            
        })

        await this.props.client.query({
            query:gql`{personasJuridicas{ID_PERSONA,RAZON_SOCIAL}}`
        }).then(async(result)=>{
            //console.log(result)
            result.data.personasJuridicas.forEach(async(persona)=>{
             
                personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL}) 

            })
            
        })
        return personas

    }
    obtenerLogoEmpresa=async()=>{

        //obtencion logo de la empresa
        await this.props.client 
        .query({
            query: gql`
                {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
            `,fetchPolicy:'network-only'             
        }).then(result=>{ 
            if(result.data.adjuntos.length>0){

                var request = require('request').defaults({ encoding: null,withCredentials:true });

                request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
                    if (!error && response.statusCode === 200) {
                    let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
                    this.setState({logotipo:data})
                    }
                });

            }
        })
    
    }
    cargarEmpresa= async() => {
         
        //DATOS JURIDICOS
        let datosEmpresa= await this.props.client
        .query({
            query: gql`
            {
                personasJuridicas(FILTROS:{EMPRESA_PRINCIPAL:true}){RAZON_SOCIAL,WEB_CANAL_DENUNCIAS}
            }
            `,
            fetchPolicy: 'network-only'

        }).then(result=>{
            //si tenemos razon social.
            if(result.data.personasJuridicas.length>0){
                return {
                    "razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL,
                    "canalDenuncia":result.data.personasJuridicas[0].WEB_CANAL_DENUNCIAS
                }

            }
            
        })

       return datosEmpresa
   
   }
   cargarDatos=async()=> {
    let datosEmpresa= await this.cargarEmpresa() 
    let personas= await this.cargarPersonas()
   
    this.setState({
        datosEmpresa:datosEmpresa,
        personas:personas
    })

}
    cargarDenuncias=async()=>{
        let denuncias=await this.props.client
        .query({
            query: gql`
            {denuncias(ORDEN:"ID_DENUNCIA,ASC"){
                ID_DENUNCIA,
                DENUNCIANTE_NOMBRE,
                DENUNCIANTE_DOCUMENTO,
                DENUNCIANTE_TELEFONO,
                DENUNCIANTE_EMAIL,
                DENUNCIANTE_TESTIGO_DIRECTO,
                DENUNCIANTE_RELACION_EMPRESA,
                DENUNCIADO_NOMBRE,
                DENUNCIADO_RELACION_EMPRESA,
                RELACION_EMPRESA_DENUNCIANTE{ID_RELACION_EMPRESA,DESCRIPCION},
                RELACION_EMPRESA_DENUNCIADO{ID_RELACION_EMPRESA,DESCRIPCION},
                FECHA_HECHOS,
                HECHOS_DENUNCIADOS,
                FECHA_DENUNCIA,
                ID_MEDIO_RECEPCION,
                DENUNCIADO_INFORMADO_FECHA,
                DENUNCIADO_INFORMADO_MEDIO,
            }}
            `,
            fetchPolicy:'network-only'
        }).then(async result=>{ 
           return result.data.denuncias
        })    
        let denunciaInstruccion=await this.props.client
        .query({
            query: gql`
            {denunciasInstruccion{
                ID_DENUNCIA,
                FECHA_ALTA,
                MOTIVO_DENUNCIA,
                ID_ESTADO_INSTRUCCION,
                ESTADO_INSTRUCCION{DESCRIPCION},
                RIESGOS_RELACIONADOS,
                CONCLUSIONES
            }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            
            return result.data.denunciasInstruccion

        })
        let denunciaDesestimacion=await this.props.client
        .query({
            query: gql`
            {denunciasDesestimadas{
                ID_DENUNCIA,
                MOTIVO,
                FECHA_DESESTIMACION,
                NOTIFICACION_FECHA,
                NOTIFICACION_MEDIO,
            }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            
            return result.data.denunciasDesestimadas

        })
        let denunciaResolucion=await this.props.client
        .query({
            query: gql`
            {denunciasResolucion{
                ID_DENUNCIA,
                DECISIONES_TOMADAS,
                ID_RESPONSABLE,
                FECHA_RESOLUCION,
                RIESGOS_AFECTADOS
            }}
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 
            
            return result.data.denunciasResolucion

        })
            console.log(denuncias)
            let denunciasVal=[]

            for(let denuncia of denuncias){
                let instVal=[]
                // eslint-disable-next-line array-callback-return
                denunciaInstruccion.find(instruccion=>{
                   if(instruccion.ID_DENUNCIA===denuncia.ID_DENUNCIA)
                   instVal.push(instruccion)
                })  
                let desestVal=[]                   
                // eslint-disable-next-line array-callback-return
                denunciaDesestimacion.find(desestimada=>{
                    if(desestimada.ID_DENUNCIA===denuncia.ID_DENUNCIA){
                        desestVal.push(desestimada)
                    }
                })
                let resolVal=[]
                // eslint-disable-next-line array-callback-return
                denunciaResolucion.find(resolucion=>{
                    if(resolucion.ID_DENUNCIA===denuncia.ID_DENUNCIA){
                        resolVal.push(resolucion)
                    }
                }) 
                
                let instruccion=(instVal.length>0?true:false)
                let desestimada=(desestVal.length>0?true:false)
                let resolucion=(resolVal.length>0?true:false)
                let estadoIns=(instruccion?instVal[0].ESTADO_INSTRUCCION.DESCRIPCION:"")
                denunciasVal.push(

                    {
                        datosDenuncia:{
                            id:denuncia.ID_DENUNCIA,
                            idRelacion:denuncia.RELACION_EMPRESA_DENUNCIANTE.ID_RELACION_EMPRESA,
                            relacionLabel:denuncia.RELACION_EMPRESA_DENUNCIANTE.DESCRIPCION,
                            idRelacionDenunciado:denuncia.RELACION_EMPRESA_DENUNCIADO.ID_RELACION_EMPRESA,
                            labelRelacionDenunciado:denuncia.RELACION_EMPRESA_DENUNCIADO.DESCRIPCION,
                            fechaInformado:denuncia.DENUNCIADO_INFORMADO_FECHA,
                            fechaDenuncia:denuncia.FECHA_DENUNCIA,
                            fechaHechos: denuncia.FECHA_HECHOS,
                            denunciante: denuncia.DENUNCIANTE_NOMBRE,
                            denunciado:denuncia.DENUNCIADO_NOMBRE,
                            dni:denuncia.DENUNCIANTE_DOCUMENTO,
                            hechosDenunciados:denuncia.HECHOS_DENUNCIADOS,
                            instruccion: instruccion,
                            testigo:denuncia.DENUNCIANTE_TESTIGO_DIRECTO,
                            telefono:denuncia.DENUNCIANTE_TELEFONO,
                            correo:denuncia.DENUNCIANTE_EMAIL,
                            estadoInstruccion: estadoIns,
                            desestimada: desestimada,
                            resuelta: resolucion,
                            medioRecepcion: denuncia.ID_MEDIO_RECEPCION,
                            medioInformacion: denuncia.DENUNCIADO_INFORMADO_MEDIO

                        },
                        datosInstruccion:(instVal.length>0?instVal[0]:null),
                        datosDesestimacion:(desestVal.length>0?desestVal[0]:null),
                        datosResolucion:(resolVal.length>0?resolVal[0]:null)
                        
                    }
                    
                )
            }
        
            console.log(denunciasVal)
            this.setState({denuncias:denunciasVal})
    
    }
    obtenerUsuario=async()=>{
        await this.props.client
        .query({
            query: gql`
            {obtenerUsuarioActual}
            `,fetchPolicy:'nnetwork-only'
        }).then(result=>{ 
            this.setState({idUsuarioActual:result.data.obtenerUsuarioActual}) 
        })
    }

    componentDidMount(){
        this.setState({cargando:true}, async () =>{
            await this.cargarDenuncias()
            await this.obtenerUsuario()
            await this.cargarDatos()
            await this.obtenerLogoEmpresa()
            this.setState({cargando:false})
        })
    }

    componentWillUpdate(props){

        if(props!==this.props){
            //buscamos la denuncia.
            if(this.state.denuncias.length>0){
                let denSel=this.state.denuncias.find(denuncia=>{
                    return denuncia.datosDenuncia.id===props.idDenuncia
                })

                this.setState({denunciaSeleccionada:denSel})
                if(props.datosDenunciaAbierto){
                    this.setState({modalAbierto:true})
                }
            }           
        }
    }

    recargar=async()=>this.setState(this.state)

    abrirWebPestañaCanalDenuncias = () => {
      let win = window.open (this.state.datosEmpresa.canalDenuncia, '_blank')
      win.focus()
    }

    generarDenunciasG=async()=>{
        this.setState({
            cargandoImprimirDG:true,
            modalImprimirDG:true
        }, async() =>{
            this.setState({cargandoImprimirDG:false}, () =>{
                ReactDOM.render(this.ImprimirDenunciasG(), document.getElementById('generarDG-pdf'))
            })
        })
    }

    generarImprimirD=async() =>{
        this.setState({
            cargandoImprimirD:true,
            modalImprimirD:true
        },async () => {
           
            this.setState({cargandoImprimirD:false}, ()=>{
                ReactDOM.render(this.ImprimirDenuncia(), document.getElementById('generarD-pdf'))
            })
        })
    }

    ImprimirDenunciasG=()=>(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" orientation='landscape' style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHeadL}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("denuncias.canaldenuncia",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("denuncias.denuncias",{ns:"page"})}</Text>
                </View>
                    {this.state.denuncias?this.state.denuncias.map((denuncia, index)=>{
                        return(
                            <View key={index}>
                               {index ===0 && (
                                <View style={styles.colHead}>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("denuncias.fechadenuncia",{ns:"page"})}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{i18next.t("denuncias.fechahechos",{ns:"page"})}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("denuncias.denunciante",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("denuncias.instruccion",{ns:"page"}).toUpperCase()}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'20%'}}>{i18next.t("denuncias.estadoinstruccion",{ns:"page"})}</Text>
                                   <Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{i18next.t("denuncias.desestimada",{ns:"page"})}</Text>
                                    <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'10%'}}>{i18next.t("denuncias.resuelta",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                               )}
                                <View style={styles.colHead}>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{denuncia.datosDenuncia?moment(denuncia.datosDenuncia.fechaDenuncia).format('DD-MM-YYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{denuncia.datosDenuncia?moment(denuncia.datosDenuncia.fechaHechos).format('DD-MM-YYYY'):''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{denuncia.datosDenuncia?denuncia.datosDenuncia.denunciante:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{denuncia.datosDenuncia.instruccion?i18next.t("yes",{ns:"global"}).toUpperCase():i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'20%'}}>{denuncia.datosDenuncia?denuncia.datosDenuncia.estadoInstruccion:''}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{denuncia.datosDenuncia.desestimada?i18next.t("yes",{ns:"global"}).toUpperCase():i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                    <Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'10%'}}>{denuncia.datosDenuncia.resuelta?i18next.t("yes",{ns:"global"}).toUpperCase():i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                </View>                               
                            
                        </View>
                        )
                    }):<></>}      
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
   
    ImprimirDenuncia=()=>{
        let personaRes='';
        if(this.state.denunciaSeleccionada.datosResolucion){
        personaRes=this.state.personas.find(persona=>{
            return persona.value===this.state.denunciaSeleccionada.datosResolucion.ID_RESPONSABLE
            
        })        
       }
       
        
    return(
        <PDFViewer style={{width: '100%', height: '100%'}}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.body}>
                <View fixed style={styles.row}>
                  <Image
                    style={styles.imageHead}
                    src={this.state.logotipo?this.state.logotipo:pantallaInicio}
                    />
                </View>
                <View fixed style={styles.rowHead}>
                    <Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
                </View>
                <View fixed style={styles.rowHeadTwo}>
                    <Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
                    <Text style={styles.text}>{i18next.t("denuncias.canaldenuncia",{ns:"page"})}</Text>
                </View>
                <View style={styles.rowTitleOne}>
                    <Text style={styles.labelTitle}>{i18next.t("denuncias.denuncias",{ns:"page"})}</Text>
                </View>
                    {this.state.denunciaSeleccionada.datosDenuncia?
                            <View >
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{i18next.t("denuncias.datosdenunciante",{ns:"page"})}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.nombreyapellido",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.denunciante:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.documentoidentificatorio",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.dni:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.telefono",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.telefono:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.correo",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.correo:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.testigodirecto",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia.testigo?i18next.t("yes",{ns:"global"}).toUpperCase():i18next.t("no",{ns:"global"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.relacionempresa",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.relacionLabel:''}</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{i18next.t("denuncias.datosdenunciado",{ns:"page"})}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.nombreyapellido",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.denunciado:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.relacionempresa",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.labelRelacionDenunciado:''}</Text>
                                </View>
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{i18next.t("denuncias.datosdenuncia",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.fechadenuncia",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?moment(this.state.denunciaSeleccionada.datosDenuncia.fechaDenuncia).format('DD-MM-YYY'):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.fechahechos",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?moment(this.state.denunciaSeleccionada.datosDenuncia.fechaHechos).format('DD-MM-YYY'):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.mediorecepcion",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.medioRecepcion:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.fechadenunciado",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia.fechaInformado?moment(this.state.denunciaSeleccionada.datosDenuncia.fechaInformado).format('DD-MM-YYY'):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.medioinformacion",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.medioInformacion:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.hechosdenunciado",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDenuncia?this.state.denunciaSeleccionada.datosDenuncia.hechosDenunciados:''}</Text>
                                </View>
                            </View>
                    :<></>}
                     {this.state.denunciaSeleccionada.datosInstruccion?
                            <View>
                                <View style={styles.rowTitleOne}>
                                    <Text style={styles.labelTitle}>{i18next.t("denuncias.instruccion",{ns:"page"}).toUpperCase()}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.fechainstruccion",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosInstruccion.FECHA_ALTA?moment(this.state.denunciaSeleccionada.datosInstruccion.FECHA_ALTA).format('DD-MM-YYYY'):''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.estadoinstruccion",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosInstruccion?this.state.denunciaSeleccionada.datosInstruccion.ESTADO_INSTRUCCION.DESCRIPCION:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.riesgosrelacionados",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosInstruccion.RIESGOS_RELACIONADOS?this.state.denunciaSeleccionada.datosInstruccion.RIESGOS_RELACIONADOS:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.motivodenuncia",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosInstruccion?this.state.denunciaSeleccionada.datosInstruccion.MOTIVO_DENUNCIA:''}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.labelData}>{i18next.t("denuncias.conclusiones",{ns:"page"})}:</Text>
                                    <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosInstruccion?this.state.denunciaSeleccionada.datosInstruccion.CONCLUSIONES:''}</Text>
                                </View>
                            </View>
                    :<></>}
                                           {/* ACA IRIA LA TABLA DE DILIGENCIAS                      */}
                            {this.state.denunciaSeleccionada.datosDesestimacion?
                                    <View>
                                        <View style={styles.rowTitleOne}>
                                            <Text style={styles.labelTitle}>{i18next.t("denuncias.desestimaciondenuncia",{ns:"page"})}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.fechadesestimacion",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDesestimacion?moment(this.state.denunciaSeleccionada.datosDesestimacion.FECHA_DESESTIMACION).format('DD-MM-YYYY'):''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.fechanotificacion",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDesestimacion?moment(this.state.denunciaSeleccionada.datosDesestimacion.NOTIFICACION_FECHA).format('DD-MM-YYYY'):''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.medionotificacion",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDesestimacion?this.state.denunciaSeleccionada.datosDesestimacion.NOTIFICACION_MEDIO:''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.motivodesestimacion",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosDesestimacion?this.state.denunciaSeleccionada.datosDesestimacion.MOTIVO:''}</Text>
                                        </View>
                                    </View>
                            :<></>}
                            {this.state.denunciaSeleccionada.datosResolucion?
                                    <View>
                                        <View style={styles.rowTitleOne}>
                                            <Text style={styles.labelTitle}>{i18next.t("denuncias.resoluciondenuncia",{ns:"page"})}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.fecharesolucion",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosResolucion?moment(this.state.denunciaSeleccionada.datosResolucion.FECHA_RESOLUCION).format('DD-MM-YYYY'):''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.personaresponsable",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{personaRes?personaRes.label:''}</Text>
                                        </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.riesgosafectados",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosResolucion.RIESGOS_AFECTADOS?this.state.denunciaSeleccionada.datosResolucion.RIESGOS_AFECTADOS:''}</Text>
                                         </View>
                                        <View style={styles.row}>
                                            <Text style={styles.labelData}>{i18next.t("denuncias.decisionestomadas",{ns:"page"})}:</Text>
                                            <Text style={styles.textData}>{this.state.denunciaSeleccionada.datosResolucion?this.state.denunciaSeleccionada.datosResolucion.DECISIONES_TOMADAS:''}</Text>
                                        </View>
                                    </View>
                            :<></>}
            </View>
    
            <Text
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
                fixed
              />
            <Image
                    style={styles.icono}
                    src={iconoFooter}
                    fixed
                    />
            <Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
          </Page>
        </Document>
      </PDFViewer>
    )
}   

    render() {

        const {steps} = this.state;

        document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+const_table.capitalize(i18next.t("denuncias.canaldenuncia",{ns:"page"}).toLowerCase())

        const handleOnSelect=(row, isSelect) => {
            if (isSelect) {
                this.setState({
                    denunciaSeleccionada: row
                })
                
            }
            //console.log(row);
            return true; // return true or dont return to approve current select action
        }

        function dateFormatter(cell, row) {
            return (
                moment(cell).format("DD/MM/YYYY")
            );
        }
        const yes=i18next.t("yes", { ns: "global" }).toUpperCase();
        const no=i18next.t("no", { ns: "global" }).toUpperCase();
        function boolFormatter(cell, row) {
            let rpta=no;
            rpta=(cell)?yes:no;
            return (
                rpta
            );
        }

        const columns = [{
            dataField: 'datosDenuncia.fechaDenuncia',
            text: const_table.capitalize(i18next.t("denuncias.fechadenuncia",{ns:"page"}).toLowerCase()),
            formatter: dateFormatter
            }, {
            dataField: 'datosDenuncia.fechaHechos',
            text: const_table.capitalize(i18next.t("denuncias.fechahechos",{ns:"page"}).toLowerCase()),
            formatter: dateFormatter
            }, {
            dataField: 'datosDenuncia.denunciante',
            text: const_table.capitalize(i18next.t("denuncias.denunciante",{ns:"page"}))
            }, {
            dataField: 'datosDenuncia.instruccion',
            text: const_table.capitalize(i18next.t("denuncias.instruccion",{ns:"page"})),
            formatter:  boolFormatter
            }, {
            dataField: 'datosDenuncia.desestimada',
            text: const_table.capitalize(i18next.t("denuncias.desestimada",{ns:"page"}).toLowerCase()),
            formatter:  boolFormatter
            }, {
            dataField: 'datosDenuncia.estadoInstruccion',
            text: const_table.capitalize(i18next.t("denuncias.estadoinstruccion",{ns:"page"}).toLowerCase())
            }, {
            dataField: 'datosDenuncia.resuelta',
            text: i18next.t("denuncias.resuelta",{ns:"page"}),
            formatter:  boolFormatter
            }
        ];

        const abrirModalNuevo = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            this.setState({
                modalAbierto: true,
                nuevoActivado: true,
                editarActivado: false,
                denunciaSeleccionada: null,
                pestaña:"denunciante"
            })

        }
        const abrirModalEditar = () => {

            //si no tiene permisos para editar la pantalla
            if(this.props.editable===false){
                alert(i18next.t("sinpermiso",{ns:"global"}))
                return
            }

            if(!this.state.denunciaSeleccionada){
                alert(i18next.t("noseleccionado",{ns:"global"}))
                return
            }
            
            this.setState({modalAbierto: true})
            this.setState({editarActivado: true})
            this.setState({nuevoActivado: false})
            this.setState({pestaña:"denunciante"})

        }

        const handleClick = () => {
            this.setState({ abierto: !this.state.abierto });
        }

        /*const validarDni=(value)=>{

            var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
            var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
            var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
            var str = value.toString().toUpperCase();
          
            if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
          
            var nie = str
                .replace(/^[X]/, '0')
                .replace(/^[Y]/, '1')
                .replace(/^[Z]/, '2');
          
            var letter = str.substr(-1);
            var charIndex = parseInt(nie.substr(0, 8)) % 23;
          
            if (validChars.charAt(charIndex) === letter) return true;
          
            return false;
        }*/

        const anadirNuevo = async(value) => {

            //pestaña denunciante
            if(!this.tabDenunciante.state.nombreApellidos){
                this.setState({pestaña:"denunciante"})
                return
            }

            if(!this.tabDenunciante.state.dni){
                this.setState({pestaña:"denunciante"})
                return
            }else{

                //if(!validarDni(this.tabDenunciante.state.dni)){
                 //   this.setState({dniInvalido:true})
                 //   this.setState({pestaña:"denunciante"})
                //    return
                //}else{
                    this.setState({dniInvalido:false})
                //}
            }

            if(!this.tabDenunciante.state.telefono){
                this.setState({pestaña:"denunciante"})
                return
            }else{

                //validacion del telefono.
                let patron=/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/
                if(!patron.test(this.tabDenunciante.state.telefono)){
                    this.setState({telefonoInvalido:true})
                    this.setState({pestaña:"denunciante"})
                    return
                }else{
                    this.setState({telefonoInvalido:false})
                }

            }

            if(this.tabDenunciante.state.correo){
                //validacion del correo
                let patron=/^[\w]+@{1}[\w]+\.[a-z]{2,3}$/
                if(!patron.test(this.tabDenunciante.state.correo)){
                    this.setState({correoInvalido:true})
                    this.setState({pestaña:"denunciante"})
                    return
                }else{
                    this.setState({correoInvalido:false})
                }

            }

            if(!this.tabDenunciante.state.relacionEmpresaSeleccionada){
                this.setState({pestaña:"denunciante"})
                return
            }

            //pestaña denunciado 

            if(!this.tabDenunciado.state.nombreApellidosDenunciado){
                this.setState({pestaña:"denunciado"})
                return
            }

            if(!this.tabDenunciado.state.relacionEmpresaDenunciado){
                this.setState({pestaña:"denunciado"})
                return
            }

            if(!this.tabDatosDenuncia.state.medioRecepcionSeleccionado){
                this.setState({pestaña:"datos-denuncia"})
                return
            }

            if(!this.tabDatosDenuncia.state.hechosDenunciados){
                this.setState({pestaña:"datos-denuncia"})
                return
            }

            if(this.tabInstruccion.state.comenzada && !this.tabInstruccion.state.estadoInstruccionSeleccionado ){
                this.setState({pestaña:"instruccion"})
                return
            }

            if(this.tabInstruccion.state.comenzada && !this.tabInstruccion.state.motivoDenuncia){
                this.setState({pestaña:"instruccion"})
                return
            }

            if(this.tabDesestimacion.state.desestimada && !this.tabDesestimacion.state.motivoDesestimacion){
                this.setState({pestaña:"desestimacion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.personaResponsableSeleccionada){
                this.setState({pestaña:"resolucion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.decisionesTomadas){
                this.setState({pestaña:"resolucion"})
                return
            }

            //Creacion de la denuncia.
            await this.props.client.mutate({
                mutation:gql`mutation{crearDenuncia(VALORES:{
                        DENUNCIANTE_NOMBRE:"${this.tabDenunciante.state.nombreApellidos}",
                        DENUNCIANTE_DOCUMENTO:"${this.tabDenunciante.state.dni}",
                        DENUNCIANTE_TELEFONO:"${this.tabDenunciante.state.telefono}",
                        DENUNCIANTE_EMAIL:${this.tabDenunciante.state.correo?'"'+this.tabDenunciante.state.correo+'"':null},
                        DENUNCIANTE_TESTIGO_DIRECTO:${this.tabDenunciante.state.testigo},
                        DENUNCIANTE_RELACION_EMPRESA:${this.tabDenunciante.state.relacionEmpresaSeleccionada.value},
                        DENUNCIADO_NOMBRE:"${this.tabDenunciado.state.nombreApellidosDenunciado}",
                        DENUNCIADO_RELACION_EMPRESA:${this.tabDenunciado.state.relacionEmpresaDenunciado.value},
                        FECHA_HECHOS:"${moment(this.tabDatosDenuncia.state.fechaHechos).format('YYYY-MM-DD')}",
                        HECHOS_DENUNCIADOS:"""${this.tabDatosDenuncia.state.hechosDenunciados}""",
                        FECHA_DENUNCIA:"${moment(this.tabDatosDenuncia.state.fechaDenuncia).format('YYYY-MM-DD')}",
                        ID_MEDIO_RECEPCION:${this.tabDatosDenuncia.state.medioRecepcionSeleccionado.value},
                        DENUNCIADO_INFORMADO_FECHA:${this.tabDatosDenuncia.state.bolFechaInformacion?'"'+moment(this.tabDatosDenuncia.state.fechaInformacion).format('YYYY-MM-DD')+'"':null},
                        DENUNCIADO_INFORMADO_MEDIO:${this.tabDatosDenuncia.state.medioInformacionSeleccionado?this.tabDatosDenuncia.state.medioInformacionSeleccionado.value:null}
                    }){
                        ID_DENUNCIA,
                        DENUNCIANTE_NOMBRE,
                        DENUNCIANTE_DOCUMENTO,
                        DENUNCIANTE_TELEFONO,
                        DENUNCIANTE_EMAIL,
                        DENUNCIANTE_TESTIGO_DIRECTO,
                        DENUNCIANTE_RELACION_EMPRESA,
                        DENUNCIADO_NOMBRE,
                        DENUNCIADO_RELACION_EMPRESA,
                        FECHA_HECHOS,
                        HECHOS_DENUNCIADOS,
                        FECHA_DENUNCIA,
                        ID_MEDIO_RECEPCION,
                        DENUNCIADO_INFORMADO_FECHA,
                        DENUNCIADO_INFORMADO_MEDIO
                    }}`
            }).then( async result=>{
             

                    let datosDenuncia=result.data.crearDenuncia
                    console.log(datosDenuncia)
                    let error=false
                    //insertmos en el historial (NO SE PARA QUE SE USA).
                   await this.props.client.mutate({
                        mutation:gql`mutation{crearDenunciaH(VALORES:{
                                ID_DENUNCIA:${datosDenuncia.ID_DENUNCIA},
                                DENUNCIANTE_NOMBRE:"${datosDenuncia.DENUNCIANTE_NOMBRE}",
                                DENUNCIANTE_DOCUMENTO:"${datosDenuncia.DENUNCIANTE_DOCUMENTO}",
                                DENUNCIANTE_TELEFONO:"${datosDenuncia.DENUNCIANTE_TELEFONO}",
                                DENUNCIANTE_EMAIL:"${datosDenuncia.DENUNCIANTE_EMAIL}",
                                DENUNCIANTE_TESTIGO_DIRECTO:${datosDenuncia.DENUNCIANTE_TESTIGO_DIRECTO},
                                DENUNCIANTE_RELACION_EMPRESA:${datosDenuncia.DENUNCIANTE_RELACION_EMPRESA},
                                DENUNCIADO_NOMBRE:"${datosDenuncia.DENUNCIADO_NOMBRE}",
                                DENUNCIADO_RELACION_EMPRESA:${datosDenuncia.DENUNCIADO_RELACION_EMPRESA},
                                FECHA_HECHOS:"${moment(datosDenuncia.FECHA_HECHOS).format('YYYY-MM-DD HH:mm:ss')}",
                                HECHOS_DENUNCIADOS:"""${datosDenuncia.HECHOS_DENUNCIADOS}""",
                                FECHA_DENUNCIA:"${moment(datosDenuncia.FECHA_DENUNCIA).format('YYYY-MM-DD HH:mm:ss')}",
                                ID_MEDIO_RECEPCION:${datosDenuncia.ID_MEDIO_RECEPCION},
                                DENUNCIADO_INFORMADO_FECHA:${datosDenuncia.DENUNCIADO_INFORMADO_FECHA?'"'+datosDenuncia.DENUNCIADO_INFORMADO_FECHA+'"':null},
                                DENUNCIADO_INFORMADO_MEDIO:${datosDenuncia.DENUNCIADO_INFORMADO_MEDIO},
                                FECHA:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                ID_USUARIO:${this.state.idUsuarioActual},
                            }){ID_DENUNCIA}}`
                    })

                    //INSERCCION EN DENUNCIAS_INSTRUCCION
                    if(this.tabInstruccion && this.tabInstruccion.state.comenzada){
                       await this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaInstruccion(VALORES:{
                                    ID_DENUNCIA:${datosDenuncia.ID_DENUNCIA},
                                    FECHA_ALTA:${this.tabInstruccion.state.fechaInstruccion?'"'+moment(this.tabInstruccion.state.fechaInstruccion,"DD-MM-YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')+'"':null},
                                    MOTIVO_DENUNCIA:"""${this.tabInstruccion.state.motivoDenuncia}""",
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccion.state.estadoInstruccionSeleccionado?this.tabInstruccion.state.estadoInstruccionSeleccionado.value:null},
                                    RIESGOS_RELACIONADOS:${this.tabInstruccion.state.riesgosSeleccionados.length>0?'"'+this.tabInstruccion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    CONCLUSIONES:${this.tabInstruccion.state.conclusiones?'"""'+this.tabInstruccion.state.conclusiones+'"""':null},
                                }){ID_DENUNCIA,FECHA_ALTA,MOTIVO_DENUNCIA,ID_ESTADO_INSTRUCCION,RIESGOS_RELACIONADOS,CONCLUSIONES}}`
                        }).then(async result=>{

                            let datosDenunciaInstruccion=result.data.crearDenunciaInstruccion
                            await this.props.client.mutate({
                                mutation:gql`mutation{crearDenunciaInstruccionH(VALORES:{
                                        ID_DENUNCIA:${datosDenunciaInstruccion.ID_DENUNCIA},
                                        FECHA_ALTA:"${datosDenunciaInstruccion.FECHA_ALTA}",
                                        MOTIVO_DENUNCIA:"""${datosDenunciaInstruccion.MOTIVO_DENUNCIA}""",
                                        ID_ESTADO_INSTRUCCION:${datosDenunciaInstruccion.ID_ESTADO_INSTRUCCION},
                                        RIESGOS_RELACIONADOS:"${datosDenunciaInstruccion.RIESGOS_RELACIONADOS}",
                                        CONCLUSIONES:${datosDenunciaInstruccion.CONCLUSIONES?'"""'+datosDenunciaInstruccion.CONCLUSIONES+'"""':null},
                                        FECHA:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                        ID_USUARIO:${this.state.idUsuarioActual}
                                    }){ID_DENUNCIA}}`
                            })

                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                        })

                    }

                    if(error)return

                    //INSERCCION EN DENUNCIAS_DESESTIMADA
                    if(this.tabDesestimacion && this.tabDesestimacion.state.desestimada){

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaDesestimada(VALORES:{
                                    ID_DENUNCIA:${datosDenuncia.ID_DENUNCIA},
                                    FECHA_DESESTIMACION:"${moment(this.tabDesestimacion.state.fechaDesestimacion).format('YYYY-MM-DD')}",
                                    MOTIVO:"""${this.tabDesestimacion.state.motivoDesestimacion}""",
                                    NOTIFICACION_MEDIO:${this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado?this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado.value:null},
                                    NOTIFICACION_FECHA:"${moment(this.tabDesestimacion.state.fechaInformacion).format('YYYY-MM-DD')}",
                                }){ID_DENUNCIA,FECHA_DESESTIMACION,MOTIVO,,NOTIFICACION_MEDIO,NOTIFICACION_FECHA}}`
                        }).then(async result=>{

                            let datosDenunciaDesestimada=result.data.crearDenunciaDesestimada
                            await this.props.client.mutate({
                                mutation:gql`mutation{crearDenunciaDesestimadaH(VALORES:{
                                    ID_DENUNCIA:${datosDenunciaDesestimada.ID_DENUNCIA},
                                    FECHA_DESESTIMACION:"${moment(datosDenunciaDesestimada.FECHA_DESESTIMACION).format('YYYY-MM-DD')}",
                                    MOTIVO:"""${datosDenunciaDesestimada.MOTIVO}""",
                                    NOTIFICACION_MEDIO:${datosDenunciaDesestimada.NOTIFICACION_MEDIO},
                                    NOTIFICACION_FECHA:"${moment(datosDenunciaDesestimada.NOTIFICACION_FECHA).format('YYYY-MM-DD')}",
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual},
                                }){ID_DENUNCIA}}`
                            })

                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                        })

                    }

                    if(error)return

                     //INSERCCION EN DENUNCIAS_RESOLUCION
                     if(this.tabResolucion && this.tabResolucion.state.resuelta){

                        await this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaResolucion(VALORES:{
                                    ID_DENUNCIA:${datosDenuncia.ID_DENUNCIA},
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                }){ID_DENUNCIA,FECHA_RESOLUCION,DECISIONES_TOMADAS,RIESGOS_AFECTADOS,ID_RESPONSABLE}}`
                        }).then(async result=>{

                            let datosDenunciaResolucion=result.data.crearDenunciaResolucion
                            await this.props.client.mutate({
                                mutation:gql`mutation{crearDenunciaResolucionH(VALORES:{
                                    ID_DENUNCIA:${datosDenunciaResolucion.ID_DENUNCIA},
                                    FECHA_RESOLUCION:"${moment(datosDenunciaResolucion.FECHA_RESOLUCION).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${datosDenunciaResolucion.DECISIONES_TOMADAS}""",
                                    RIESGOS_AFECTADOS:"${datosDenunciaResolucion.RIESGOS_AFECTADOS}",
                                    ID_RESPONSABLE:${datosDenunciaResolucion.ID_RESPONSABLE}
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD')}"
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_DENUNCIA}}`
                            })

                        }).catch(err=>{
                            error=true
                            alert(i18next.t("error",{ns:"global"}))
                        })

                    }
                    if(error)return

                    alert(i18next.t("insertcorrecto",{ns:"global"}))

                    cerrarModal()
                    await this.cargarDenuncias()

            }).catch(err=>{
                alert(i18next.t("error",{ns:"global"}))
            })

        }


        const actualizarDenuncia = async() => {

            //pestaña denunciante
            if(!this.tabDenunciante.state.nombreApellidos){
                this.setState({pestaña:"denunciante"})
                return
            }

            if(!this.tabDenunciante.state.dni){
                this.setState({pestaña:"denunciante"})
                return
            }else{

                //if(!validarDni(this.tabDenunciante.state.dni)){
                //    this.setState({dniInvalido:true})
                //    this.setState({pestaña:"denunciante"})
                //    return
                //}else{
                    this.setState({dniInvalido:false})
                //}
            }

            if(!this.tabDenunciante.state.telefono){
                this.setState({pestaña:"denunciante"})
                return
            }else{

                //validacion del telefono.
                let patron=/^[\d]{3}[-]*([\d]{2}[-]*){2}[\d]{2}$/
                if(!patron.test(this.tabDenunciante.state.telefono)){
                    this.setState({telefonoInvalido:true})
                    this.setState({pestaña:"denunciante"})
                    return
                }else{
                    this.setState({telefonoInvalido:false})
                }

            }

            if(this.tabDenunciante.state.correo){
                //validacion del correo
                let patron=/^[\w]+@{1}[\w]+\.[a-z]{2,3}$/
                if(!patron.test(this.tabDenunciante.state.correo)){
                    this.setState({correoInvalido:true})
                    this.setState({pestaña:"denunciante"})
                    return
                }else{
                    this.setState({correoInvalido:false})
                }

            }

            if(!this.tabDenunciante.state.relacionEmpresaSeleccionada){
                this.setState({pestaña:"denunciante"})
                return
            }

            //pestaña denunciado 
            if(!this.tabDenunciado.state.nombreApellidosDenunciado){
                this.setState({pestaña:"denunciado"})
                return
            }

            if(!this.tabDenunciado.state.relacionEmpresaDenunciado){
                this.setState({pestaña:"denunciado"})
                return
            }

            if(!this.tabDatosDenuncia.state.medioRecepcionSeleccionado){
                this.setState({pestaña:"datos-denuncia"})
                return
            }

            if(!this.tabDatosDenuncia.state.hechosDenunciados){
                this.setState({pestaña:"datos-denuncia"})
                return
            }

            if(this.tabInstruccion.state.comenzada && !this.tabInstruccion.state.estadoInstruccionSeleccionado ){
                this.setState({pestaña:"instruccion"})
                return
            }

            if(this.tabInstruccion.state.comenzada && !this.tabInstruccion.state.motivoDenuncia){
                this.setState({pestaña:"instruccion"})
                return
            }

            if(this.tabDesestimacion.state.desestimada && !this.tabDesestimacion.state.motivoDesestimacion){
                this.setState({pestaña:"desestimacion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.personaResponsableSeleccionada){
                this.setState({pestaña:"resolucion"})
                return
            }

            if(this.tabResolucion.state.resuelta && !this.tabResolucion.state.decisionesTomadas){
                this.setState({pestaña:"resolucion"})
                return
            }
            
            //Actualizacion de la denuncia.
            await this.props.client.mutate({
                mutation:gql`mutation{actualizarDenuncia(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                        DENUNCIANTE_NOMBRE:"${this.tabDenunciante.state.nombreApellidos}",
                        DENUNCIANTE_DOCUMENTO:"${this.tabDenunciante.state.dni}",
                        DENUNCIANTE_TELEFONO:"${this.tabDenunciante.state.telefono}",
                        DENUNCIANTE_EMAIL:${this.tabDenunciante.state.correo?'"'+this.tabDenunciante.state.correo+'"':null},
                        DENUNCIANTE_TESTIGO_DIRECTO:${this.tabDenunciante.state.testigo},
                        DENUNCIANTE_RELACION_EMPRESA:${this.tabDenunciante.state.relacionEmpresaSeleccionada.value},
                        DENUNCIADO_NOMBRE:"${this.tabDenunciado.state.nombreApellidosDenunciado}",
                        DENUNCIADO_RELACION_EMPRESA:${this.tabDenunciado.state.relacionEmpresaDenunciado.value},
                        FECHA_HECHOS:"${moment(this.tabDatosDenuncia.state.fechaHechos).format('YYYY-MM-DD')}",
                        HECHOS_DENUNCIADOS:"""${this.tabDatosDenuncia.state.hechosDenunciados}""",
                        FECHA_DENUNCIA:"${moment(this.tabDatosDenuncia.state.fechaDenuncia).format('YYYY-MM-DD')}",
                        ID_MEDIO_RECEPCION:${this.tabDatosDenuncia.state.medioRecepcionSeleccionado.value},
                        DENUNCIADO_INFORMADO_FECHA:${this.tabDatosDenuncia.state.bolFechaInformacion?'"'+moment(this.tabDatosDenuncia.state.fechaInformacion).format('YYYY-MM-DD')+'"':null},
                        DENUNCIADO_INFORMADO_MEDIO:${this.tabDatosDenuncia.state.medioInformacionSeleccionado?this.tabDatosDenuncia.state.medioInformacionSeleccionado.value:null}
                    }){ID_DENUNCIA}}`
            }).then(async result=>{
              
            
                //insertmos en el historial (NO SE PARA QUE SE USA).
                await this.props.client.mutate({
                    mutation:gql`mutation{actualizarDenunciaH(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                            DENUNCIANTE_NOMBRE:"${this.tabDenunciante.state.nombreApellidos}",
                            DENUNCIANTE_DOCUMENTO:"${this.tabDenunciante.state.dni}",
                            DENUNCIANTE_TELEFONO:"${this.tabDenunciante.state.telefono}",
                            DENUNCIANTE_EMAIL:${this.tabDenunciante.state.correo?'"'+this.tabDenunciante.state.correo+'"':null},
                            DENUNCIANTE_TESTIGO_DIRECTO:${this.tabDenunciante.state.testigo},
                            DENUNCIANTE_RELACION_EMPRESA:${this.tabDenunciante.state.relacionEmpresaSeleccionada.value},
                            DENUNCIADO_NOMBRE:"${this.tabDenunciado.state.nombreApellidosDenunciado}",
                            DENUNCIADO_RELACION_EMPRESA:${this.tabDenunciado.state.relacionEmpresaDenunciado.value},
                            FECHA_HECHOS:"${moment(this.tabDatosDenuncia.state.fechaHechos).format('YYYY-MM-DD')}",
                            HECHOS_DENUNCIADOS:"""${this.tabDatosDenuncia.state.hechosDenunciados}""",
                            FECHA_DENUNCIA:"${moment(this.tabDatosDenuncia.state.fechaDenuncia).format('YYYY-MM-DD')}",
                            ID_MEDIO_RECEPCION:${this.tabDatosDenuncia.state.medioRecepcionSeleccionado.value},
                            DENUNCIADO_INFORMADO_FECHA:${this.tabDatosDenuncia.state.bolFechaInformacion?'"'+moment(this.tabDatosDenuncia.state.fechaInformacion).format('YYYY-MM-DD')+'"':null},
                            DENUNCIADO_INFORMADO_MEDIO:${this.tabDatosDenuncia.state.medioInformacionSeleccionado?this.tabDatosDenuncia.state.medioInformacionSeleccionado.value:null}
                            FECHA:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                            ID_USUARIO:${this.state.idUsuarioActual},
                        }){ID_DENUNCIA}}`
                })

                //INSERCCION EN DENUNCIAS_INSTRUCCION
                if(this.tabInstruccion && this.tabInstruccion.state.comenzada){

                    //si no existe la denuncia instruccion
                    
                    if(this.tabInstruccion.state.noexiste){

                        this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaInstruccion(VALORES:{
                                    ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},
                                    FECHA_ALTA:${this.tabInstruccion.state.fechaInstruccion?'"'+moment(this.tabInstruccion.state.fechaInstruccion).format('YYYY-MM-DD HH:mm:ss')+'"':null},
                                    MOTIVO_DENUNCIA:"""${this.tabInstruccion.state.motivoDenuncia}""",
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccion.state.estadoInstruccionSeleccionado?this.tabInstruccion.state.estadoInstruccionSeleccionado.value:null},
                                    RIESGOS_RELACIONADOS:${this.tabInstruccion.state.riesgosSeleccionados.length>0?'"'+this.tabInstruccion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    CONCLUSIONES:${this.tabInstruccion.state.conclusiones?'"""'+this.tabInstruccion.state.conclusiones+'"""':null},
                                }){ID_DENUNCIA}}`
                        }).then(result=>{

                            this.props.client.mutate({
                                mutation:gql`mutation{crearDenunciaInstruccionH(VALORES:{
                                    ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},
                                    FECHA_ALTA:${this.tabInstruccion.state.fechaInstruccion?'"'+moment(this.tabInstruccion.state.fechaInstruccion).format('YYYY-MM-DD HH:mm:ss')+'"':null},
                                    MOTIVO_DENUNCIA:"""${this.tabInstruccion.state.motivoDenuncia}""",
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccion.state.estadoInstruccionSeleccionado?this.tabInstruccion.state.estadoInstruccionSeleccionado.value:null},
                                    RIESGOS_RELACIONADOS:${this.tabInstruccion.state.riesgosSeleccionados.length>0?'"'+this.tabInstruccion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    CONCLUSIONES:${this.tabInstruccion.state.conclusiones?'"""'+this.tabInstruccion.state.conclusiones+'"""':null},
                                    FECHA:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_DENUNCIA}}`
                            })

                        })


                    }else{

                        await this.props.client.mutate({
                            mutation:gql`mutation{actualizarDenunciaInstruccion( 
                                ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                    FECHA_ALTA:${this.tabInstruccion.state.fechaInstruccion?'"'+moment(this.tabInstruccion.state.fechaInstruccion).format('YYYY-MM-DD HH:mm:ss')+'"':null},
                                    MOTIVO_DENUNCIA:"""${this.tabInstruccion.state.motivoDenuncia}""",
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccion.state.estadoInstruccionSeleccionado.value},
                                    RIESGOS_RELACIONADOS:${this.tabInstruccion.state.riesgosSeleccionados.length>0?'"'+this.tabInstruccion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    CONCLUSIONES:${this.tabInstruccion.state.conclusiones?'"""'+this.tabInstruccion.state.conclusiones+'"""':null},
                                }){ID_DENUNCIA}}`
                        }).then(async result=>{

                            await this.props.client.mutate({
                                mutation:gql`mutation{actualizarDenunciaInstruccionH(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                    FECHA_ALTA:${this.tabInstruccion.state.fechaInstruccion?'"'+moment(this.tabInstruccion.state.fechaInstruccion).format('YYYY-MM-DD HH:mm:ss')+'"':null},
                                    MOTIVO_DENUNCIA:"""${this.tabInstruccion.state.motivoDenuncia}""",
                                    ID_ESTADO_INSTRUCCION:${this.tabInstruccion.state.estadoInstruccionSeleccionado.value},
                                    RIESGOS_RELACIONADOS:${this.tabInstruccion.state.riesgosSeleccionados.length>0?'"'+this.tabInstruccion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    CONCLUSIONES:${this.tabInstruccion.state.conclusiones?'"""'+this.tabInstruccion.state.conclusiones+'"""':null},
                                    FECHA:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_DENUNCIA}}`
                            })

                        })

                    }

                }

        
                //INSERCCION EN DENUNCIAS_DESESTIMADA
                if(this.tabDesestimacion && this.tabDesestimacion.state.desestimada){

                    if(this.tabDesestimacion.state.noexiste){

                            this.props.client.mutate({
                        mutation:gql`mutation{crearDenunciaDesestimada(VALORES:{
                            ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id}
                            FECHA_DESESTIMACION:"${moment(this.tabDesestimacion.state.fechaDesestimacion).format('YYYY-MM-DD')}",
                            MOTIVO:"""${this.tabDesestimacion.state.motivoDesestimacion}""",
                            NOTIFICACION_MEDIO:${this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado?this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado.value:null},
                            NOTIFICACION_FECHA:"${moment(this.tabDesestimacion.state.fechaInformacion).format('YYYY-MM-DD')}"
                            }){ID_DENUNCIA}}`

                    }).then(result=>{

                        this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaDesestimadaH(VALORES:{
                                ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id}
                                FECHA_DESESTIMACION:"${moment(this.tabDesestimacion.state.fechaDesestimacion).format('YYYY-MM-DD')}",
                                MOTIVO:"""${this.tabDesestimacion.state.motivoDesestimacion}""",
                                NOTIFICACION_MEDIO:${this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado?this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado.value:null},
                                NOTIFICACION_FECHA:"${moment(this.tabDesestimacion.state.fechaInformacion).format('YYYY-MM-DD')}"
                                FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                ID_USUARIO:${this.state.idUsuarioActual},
                            }){ID_DENUNCIA}}`
                        })

                    })

                    }else{

                        this.props.client.mutate({
                            mutation:gql`mutation{actualizarDenunciaDesestimada(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                FECHA_DESESTIMACION:"${moment(this.tabDesestimacion.state.fechaDesestimacion).format('YYYY-MM-DD')}",
                                MOTIVO:"""${this.tabDesestimacion.state.motivoDesestimacion}""",
                                NOTIFICACION_MEDIO:${this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado?this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado.value:null},
                                NOTIFICACION_FECHA:"${moment(this.tabDesestimacion.state.fechaInformacion).format('YYYY-MM-DD')}"
                            }){ID_DENUNCIA}}`
                        }).then(result=>{
                            this.props.client.mutate({
                                mutation:gql`mutation{actualizarDenunciaDesestimadaH(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                    FECHA_DESESTIMACION:"${moment(this.tabDesestimacion.state.fechaDesestimacion).format('YYYY-MM-DD')}",
                                    MOTIVO:"""${this.tabDesestimacion.state.motivoDesestimacion}""",
                                    NOTIFICACION_MEDIO:${this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado?this.tabDesestimacion.state.medioInformacionDenuncianteSeleccionado.value:null},
                                    NOTIFICACION_FECHA:"${moment(this.tabDesestimacion.state.fechaInformacion).format('YYYY-MM-DD')}"
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}",
                                    ID_USUARIO:${this.state.idUsuarioActual},
                                }){ID_DENUNCIA}}`
                            })
                        })

                    }
                
                }

                //INSERCCION EN DENUNCIAS_RESOLUCION
                if(this.tabResolucion && this.tabResolucion.state.resuelta){

                    if(this.tabResolucion.state.noexiste){

                        this.props.client.mutate({
                            mutation:gql`mutation{crearDenunciaResolucion(VALORES:{
                                    ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                }){ID_DENUNCIA}}`
                        }).then(result=>{

                            this.props.client.mutate({
                                mutation:gql`mutation{crearDenunciaResolucionH(VALORES:{
                                    ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}"
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_DENUNCIA}}`
                            })

                        })

                    }else{


                        this.props.client.mutate({
                            mutation:gql`mutation{actualizarDenunciaResolucion(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:"""${this.tabResolucion.state.decisionesTomadas}""",
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                }){ID_DENUNCIA}}`
                        }).then(result=>{

                            this.props.client.mutate({
                                mutation:gql`mutation{actualizarDenunciaResolucionH(ID_DENUNCIA:${this.state.denunciaSeleccionada.datosDenuncia.id},VALORES:{
                                    FECHA_RESOLUCION:"${moment(this.tabResolucion.state.fechaResolucion).format('YYYY-MM-DD')}",
                                    DECISIONES_TOMADAS:${this.tabResolucion.state.decisionesTomadas?'"""'+this.tabResolucion.state.decisionesTomadas+'"""':null},
                                    RIESGOS_AFECTADOS:${this.tabResolucion.state.riesgosSeleccionados.length>0?'"'+this.tabResolucion.state.riesgosSeleccionados.map(ries=>{return ries.id.split("_").pop()}).join(",")+'"':null},
                                    ID_RESPONSABLE:${this.tabResolucion.state.personaResponsableSeleccionada.value}
                                    FECHA_REGISTRO:"${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}"
                                    ID_USUARIO:${this.state.idUsuarioActual}
                                }){ID_DENUNCIA}}`
                            })

                        })

                    }

                }
                cerrarModal()
                await this.cargarDenuncias()

                if(this.props.externo){
                    this.props.cargarDenuncias()
                }
                
                alert(i18next.t("updatecorrecto",{ns:"global"}))

            }).catch(err=>{
                console.log(err)
                alert(i18next.t("error",{ns:"global"}))
            })

            //if(this.props.cargarDenuncias)this.props.cargarDenuncias()
        }

        const cerrarModal = () => {
            this.setState({
                modalAbierto: false,
            })
            if(this.props.externo){
                this.props.cerrarModalDenuncias()
            }
        }

        const cambiarPestaña=(value)=>{
           this.setState({pestaña:value})
        }

        const handleJoyrideCallback=async(data)=>{
        

            const { action, index, status, type } = data;
      
            if ([EVENTS.STEP_AFTER].includes(type)) {
      
              let paso=index+ (action === ACTIONS.PREV ? -1 : 1)
      
              //paso en el que nos encontremos.
              // eslint-disable-next-line default-case
              switch(paso){
                case 0:
                    await this.setState({denunciaSeleccionada:null})
                break;
                case 1:
                    await this.setState({modalAbierto:false})
                break;
                case 2:
                    await this.setState({modalAbierto:true})
                break;
                case 3:
                    await this.setState({pestaña:"denunciante"})
                break;
                case 4:
                    await this.setState({pestaña:"denunciado"})
                break;
                case 5:
                    await this.setState({pestaña:"datos-denuncia"})
                break;
                case 6:
                    await this.setState({pestaña:"instruccion"})
                break;
                case 7:
                    await this.tabInstruccion.setState({comenzada:false})
                break;
                case 8:
                    await this.tabInstruccion.setState({comenzada:true})
                break;
                case 9:
                    await this.setState({pestaña:"instruccion"})
                break;
                case 10:
                    await this.setState({pestaña:"diligencias"})
                break;
                case 11:
                    await this.tabDiligencias.setState({
                        modalAbierto: false,
                        nuevoActivado:false,
                    })
                break;
                case 12:
                    await this.tabDiligencias.setState({
                        modalAbierto: true,
                        nuevoActivado:true,
                    })
                    await this.setState({pestaña:"diligencias"})
                break;
                case 13:
                    await this.tabDiligencias.setState({
                        modalAbierto: false,
                        nuevoActivado:false,
                    })
                    await this.setState({pestaña:"desestimacion"})
                    await this.tabDesestimacion.setState({desestimada:false})

                break;
                case 14:
                    await this.setState({pestaña:"desestimacion"})
                    await this.tabDesestimacion.setState({desestimada:true})
                break;
                case 15:
                    await this.setState({pestaña:"resolucion"})
                    await this.tabResolucion.setState({resuelta:false})
                break;
                case 16:
                    await this.tabResolucion.setState({resuelta:true})
                break;
              }
      
              await this.setState({ pasoGuia: paso });         
      
            }
      
            //si termina la guia.
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                localStorage.setItem("ayuda",false)
                await this.setState({modalAbierto:false})

            }
      
        } 
        const cerrarModalImprimir=()=>{
            this.setState({
                modalImprimirD:false,
                modalImprimirDG:false
            })
        }

        return (
            <>  
            {this.state.cargando===true?
                <Cargando/>
            :
            <>    
                {this.props.externo?
            
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.propiedaddeladenuncia",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                    className="dialogo-denuncias"
                >
                    <div className="pixelAlrededor">
                        <Tabs id="denuncias"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={false}
                        >
                            <Tab id="denunciante" title={i18next.t("denuncias.denunciante",{ns:"page"})} panel={  <Denunciante ref={(input) => { this.tabDenunciante= input; }} telefonoInvalido={this.state.telefonoInvalido} correoInvalido={this.state.correoInvalido} dniInvalido={this.state.dniInvalido} datosDenuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDenuncia:null} /> } />
                            <Tab id="denunciado" title={i18next.t("denuncias.denunciado",{ns:"page"})} panel={ <Denunciado ref={(input) => { this.tabDenunciado= input; }} datosDenuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDenuncia:null}/> } />
                            <Tab id="datos-denuncia" title={i18next.t("denuncias.datosdenuncia",{ns:"page"})} panel={ <DatosDenuncia ref={(input) => { this.tabDatosDenuncia= input; }} datosDenuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDenuncia:null}/> } />
                            <Tab id="instruccion" title={i18next.t("denuncias.instruccion",{ns:"page"})} panel={ <Instruccion ref={(input) => { this.tabInstruccion= input; }} datosInstruccion={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosInstruccion:null}/> } />
                            <Tab id="diligencias" title={i18next.t("denuncias.diligencias",{ns:"page"})} panel={ <Diligencias denuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDenuncia.id:null} ref={(input) => { this.tabDiligencias= input; }}  deshabilitado={this.state.denunciaSeleccionada && this.state.denunciaSeleccionada.datosInstruccion?(Object.keys(this.state.denunciaSeleccionada.datosInstruccion).length>0?false:true):true}/> } />
                            <Tab id="desestimacion" title={i18next.t("denuncias.desestimacion",{ns:"page"})} panel={ <Desestimacion ref={(input) => { this.tabDesestimacion= input; }} datosDesestimada={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDesestimacion:null}  /> } />
                            <Tab id="resolucion" title={i18next.t("denuncias.resolucion",{ns:"page"})} panel={ <Resolucion  ref={(input) => { this.tabResolucion= input; }}  datosResolucion={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosResolucion:null} /> } />
                        </Tabs>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent="primary"
                                onClick={this.state.nuevoActivado?anadirNuevo:actualizarDenuncia} 
                            >
                               {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                :  
                <>

                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("denuncias.imprimirdenuncia",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirDG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirDG?
                            <div style={{ width: '100%', height: '500px'}} id='generarDG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("denuncias.imprimirdetalledenuncia",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirD}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirD?
                            <div style={{ width: '100%', height: '500px'}} id='generarD-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                <Joyride
                    run={localStorage.getItem("ayuda")==="true"?true:false}
                    callback={handleJoyrideCallback}
                    showProgress={true}
                    stepIndex={this.state.pasoGuia}
                    steps={steps}
                    disableScrollParentFix={true}
                    continuous ={true}
                    locale={{ back: 'Volver', close: 'Cerrar', last: 'Último', next: 'Siguiente', skip: 'Saltar' }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            primaryColor: '#E31E24',
                        }
                    }}
                />
                <Dialog transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="info-sign"
                    onClose={cerrarModal}
                    title={i18next.t("denuncias.propiedaddeladenuncia",{ns:"page"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalAbierto}
                    onOpened={async()=>{if(localStorage.getItem("ayuda")==="true")await this.setState(this.state)}}
                    className="dialogo-denuncias"
                >
                    <div id="contenedor-modal-denuncias" className="pixelAlrededor">
                        <Tabs id="denuncias"
                            animate={this.animacion} 
                            onChange={cambiarPestaña} 
                            selectedTabId={this.state.pestaña}
                            renderActiveTabPanelOnly={false}
                        >
                            <Tab id="denunciante" title={i18next.t("denuncias.denunciante",{ns:"page"})} panel={  <Denunciante ref={(input) => { this.tabDenunciante= input; }} telefonoInvalido={this.state.telefonoInvalido} correoInvalido={this.state.correoInvalido} dniInvalido={this.state.dniInvalido} datosDenuncia={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosDenuncia:null}:null} /> } />
                            <Tab id="denunciado" title={i18next.t("denuncias.denunciado",{ns:"page"})} panel={ <Denunciado ref={(input) => { this.tabDenunciado= input; }} datosDenuncia={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosDenuncia:null}:null}/> } />
                            <Tab id="datos-denuncia" title={i18next.t("denuncias.datosdenuncia",{ns:"page"})} panel={ <DatosDenuncia ref={(input) => { this.tabDatosDenuncia= input; }} datosDenuncia={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosDenuncia:null}:null}/> } />
                            <Tab id="instruccion" title={i18next.t("denuncias.instruccion",{ns:"page"})} panel={ <Instruccion ref={(input) => { this.tabInstruccion= input; }} datosInstruccion={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosInstruccion:null}:null}/> } />
                            <Tab id="diligencias" title={i18next.t("denuncias.diligencias",{ns:"page"})} panel={ <Diligencias recargar={this.recargar} denuncia={this.state.denunciaSeleccionada?this.state.denunciaSeleccionada.datosDenuncia.id:null} ref={(input) => { this.tabDiligencias= input; }}  deshabilitado={this.state.denunciaSeleccionada && this.state.denunciaSeleccionada.datosInstruccion?(Object.keys(this.state.denunciaSeleccionada.datosInstruccion).length>0?false:true):localStorage.getItem("ayuda")==="true"?false:true}/> } />
                            <Tab id="desestimacion" title={i18next.t("denuncias.desestimacion",{ns:"page"})} panel={ <Desestimacion ref={(input) => { this.tabDesestimacion= input; }} datosDesestimada={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosDesestimacion:null}:null}  /> } />
                            <Tab id="resolucion" title={i18next.t("denuncias.resolucion",{ns:"page"})} panel={ <Resolucion  ref={(input) => { this.tabResolucion= input; }}  datosResolucion={this.state.denunciaSeleccionada?{...this.state.editarActivado?this.state.denunciaSeleccionada.datosResolucion:null}:null} /> } />
                        </Tabs>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
                            <Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
                        </Tooltip>
                        <Tooltip content={this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})} position="top">
                            <AnchorButton
                                intent="primary"
                                onClick={this.state.nuevoActivado?anadirNuevo:actualizarDenuncia} 
                            >
                               {this.state.nuevoActivado?i18next.t("agregar",{ns:"global"}):i18next.t("edit",{ns:"global"})}
                            </AnchorButton>
                        </Tooltip>
                    </div>
                </div>
                </Dialog>
                <div id="contenedor-denuncias">
                    <div className="tablaSetenta pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO} >
                            <Navbar>
                                <Navbar.Group>
                                    <ButtonGroup align={Alignment.LEFT}>
                                        <Button className="bp3-minimal" id="btn-crear-denuncia" icon="add" text={i18next.t("new",{ns:"global"})} active={this.state.nuevoActivado} onClick={abrirModalNuevo} />
                                        <Button className="bp3-minimal" id="btn-editar-denuncia" icon="edit" text={i18next.t("edit",{ns:"global"})} active={this.state.editarActivado} onClick={abrirModalEditar}  />
                                        <Tooltip content={i18next.t("print",{ns:"global"})} position="right" >
                                                <Button className="bp3-minimal" icon="print" text={i18next.t("printgral",{ns:"global"})} onClick={()=>this.generarDenunciasG()}/>
                                         </Tooltip>
                                         <Tooltip content={i18next.t("print",{ns:"global"})} position="right" >
                                                <Button className="bp3-minimal" icon="print" text={i18next.t("print",{ns:"global"})}   onClick={()=>this.generarImprimirD()} disabled/>
                                         </Tooltip>
                                         <Tooltip content={i18next.t("denuncias.accesocanal",{ns:"page"})} position="right" >
                                                <Button className="bp3-minimal" icon="export" text={i18next.t("denuncias.accesocanal",{ns:"page"})}   onClick={()=>this.abrirWebPestañaCanalDenuncias()}/>
                                         </Tooltip>
                                    </ButtonGroup>
                                </Navbar.Group>
                            </Navbar>
                            <BootstrapTable
                                keyField='datosDenuncia.id'
                                data={ this.state.denuncias }
                                columns={ columns }
                                selectRow={ const_table.selectRow(handleOnSelect) }
                                pagination={ paginationFactory(const_table.options) }
                                headerClasses="header-class"
                                rowClasses="row-class"
                                hover
                                condensed
                                noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
                            />
                        </Card>
                    </div>
                    <div className="tablaTreinta pixelAlrededor">
                        <Card interactive={true} elevation={Elevation.TWO} >
                        <Button icon="git-repo" onClick={handleClick}>
                            {i18next.t("denuncias.informaciondenuncia",{ns:"page"})}
                        </Button>

                        <Collapse isOpen={this.state.abierto}>
                            <div className="pixelAlrededor">
                                <Card> 
                                    <p>{i18next.t("denuncias.parrafo1",{ns:"page"})}</p>
                                    <p>{i18next.t("denuncias.parrafo2",{ns:"page"})}</p>
                                    <p>{i18next.t("denuncias.parrafo3",{ns:"page"})}</p>
                                    <p>{i18next.t("denuncias.parrafo4",{ns:"page"})}</p>
                                    <p>{i18next.t("denuncias.parrafo5",{ns:"page"})}</p>
                                    <p>{i18next.t("denuncias.parrafo6",{ns:"page"})}</p>
                                </Card>
                            </div>
                        </Collapse>
                        </Card>
                    </div>
                </div>
                </>
                }
            </>
        }
       </>  
            
        )
    }
}
export default withTranslation()(withApollo(Denuncias));