/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react";
import {
  Card,
  Elevation,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Dialog,
  FormGroup,
  Navbar,
  NavbarHeading,
  ButtonGroup,
  Button,
  InputGroup,
  Alignment,
  Callout,
  Spinner
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import gql from "graphql-tag";
import { NuevoTituloSociosActual } from "./NuevoTituloSociosActual";
import { withApollo } from "react-apollo";
import pantallaInicio from "../../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { CSVLink } from "react-csv";
import { Cargando } from "../Cargando"
import iconoFooter from '../../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import ReactDOM from 'react-dom'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "../BootstrapTable";
import styles from '../stylesPrint';


class EstadoCapitalSocial extends React.Component {
  _isMounted = false;

  state = {
	fecha: "",
	fechaSeleccionada: new Date(),
	valorTitulo: 0,
	numTitulos: 0,
	capitalSocialInicial: "0.00",
	existeCapitaSocial: false,
	abrirModalGuardarDatos: false,
	abrirModalDistribucion: false,
	capitalSocial: [
	],
	distribucionSeleccionada:null,
	nuevoActivado:true,
	editarActivado:false,
	borrarActivado:false,
	tiposParticipacion:null,
	tipoCapital:null,
	cargandoCsv:true,
	datosCsv:[],
	datosImpresion:[],
	valorTituloValido:true,
	cargando: false,
	cargandoGrid: false,
	imprimirActivado:false,
	modalImprimir:false,
	cargandoImprimir:false,
	personasFisicas:null,
	personasJuridicas:null,
	capitalSocActual:null,
	cargandoSaveCapAct: false
  };

  obtenerPotencias=(n)=>{
	let array = [];
	let binaryRepresentation = parseInt(n, 10).toString(2);
	binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
	for(let i = binaryRepresentation.length - 1; i >= 0; i--){
		if(parseInt(binaryRepresentation[i]) === 1){
			array[Math.pow(2, i)]=true
		}

	}

	return array
  }
  cargarDatosCsv=async()=>{

    this.setState({cargandoCsv:true})
    let datos=[]


	// let capitalActual=await this.props.client
	// .query({
    //   query:gql`{capSocialActualDistribucion(CAMPOS:"ID_DISTRIBUCION,TIPO_CAPITAL,ID_PERSONA,TIPO_TITULO,NUMERO_TITULOS,NUMERACION_TITULOS"){
    //     ID_DISTRIBUCION,
    //     ID_PERSONA,
    //     PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
	// 	TIPO_TITULO,
	// 	TIPO_CAPITAL,
    //     NUMERO_TITULOS,
    //     NUMERACION_TITULOS
    //   }}`,fetchPolicy:'network-only'
    // }).then(async(result)=>{
    //     return result.data.capSocialActualDistribucion
	// })
	let capitalActual= this.state.capitalSocActual
    let index=1
    
    for(let capital of capitalActual){
      //buscamos el nombre de la persona
      
      if(capital.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===1){
			this.state.personasFisicas.find(persona=>{
				if(persona.ID_PERSONA===capital.ID_PERSONA){
					capital.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
					capital.GRAVAMENES=persona.GRAVAMENES
            		capital.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`
				}
			})
        // nombre=await this.props.client
        //   .query({
        //       query: gql` 
        //           {personasFisicas(ORDEN:"APELLIDO1,ASC",FILTROS:{ID_PERSONA:${capital.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
        //       `,fetchPolicy:'network-only'
        //   }).then(result=>{ 
		// 	capital.NACIONALIDAD=result.data.personasFisicas[0].NACIONALIDAD?result.data.personasFisicas[0].NACIONALIDAD.DESCRIPCION:""
		// 	capital.GRAVAMENES=result.data.personasFisicas[0].GRAVAMENES
        //     return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
        //   })

      //Si es persona juridica
      }else if(capital.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA===2){
		this.state.personasJuridicas.find(persona=>{
			if(persona.ID_PERSONA===capital.ID_PERSONA){
				capital.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
				capital.GRAVAMENES=persona.GRAVAMENES
				capital.nombre= persona.RAZON_SOCIAL
			}
		})		

        //   nombre=await this.props.client
        //   .query({
        //       query: gql`
        //           {personasJuridicas(FILTROS:{ID_PERSONA:${capital.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
        //       `,fetchPolicy:'network-only'
        //   }).then(result=>{ 
		// 		capital.NACIONALIDAD=result.data.personasJuridicas[0].NACIONALIDAD?result.data.personasJuridicas[0].NACIONALIDAD.DESCRIPCION:""
		// 		capital.GRAVAMENES=result.data.personasJuridicas[0].GRAVAMENES
		// 		return result.data.personasJuridicas[0].RAZON_SOCIAL
        //   })

        }
        
        let tiposKeys=Object.keys(this.obtenerPotencias(capital.TIPO_TITULO))
        let tipos=""

        tiposKeys.forEach(t=>{

            let tipoSel=null
            if(this.state.tipoCapital===1){
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
            }else{
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
            }
            if(tipos===""){
              tipos+=tipoSel.DESCRIPCION
            }else{

              tipos+=","+tipoSel.DESCRIPCION

            }

        })
        capital.tipo=tipos

        datos.push({
			"Nº":index,
			"Tipo":capital.TIPO_CAPITAL==="I"?"Inicial":"Actual",
			"Socio":capital.nombre,
			"Nacionalidad":capital.NACIONALIDAD,
			"Tipo de titulo":tipos,
			"Número de titulos":capital.NUMERO_TITULOS,
			"%":parseFloat((capital.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
			"Numeración":""+capital.NUMERACION_TITULOS.replace("-"," a "),
			"Gravamenes":capital.GRAVAMENES
        })

		// datosImpresion.push({
		// 	"Nº":index,
		// 	"Tipo":capital.TIPO_CAPITAL==="I"?"Inicial":"Actual",
		// 	"Socio":nombre,
		// 	"Nacionalidad":capital.NACIONALIDAD,
		// 	"Tipo de titulo":tipos,
		// 	"Número de titulos":capital.NUMERO_TITULOS,
		// 	"%":parseFloat((capital.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2),
		// 	"Numeración":""+capital.NUMERACION_TITULOS.replace("-"," a "),
		// 	"Gravamenes":capital.GRAVAMENES
		// })

        index++;
	}
	
	console.log(capitalActual)
    this.setState({datosCsv:datos,cargandoCsv:false,datosImpresion:capitalActual})

  }
  cargarCapital=async()=>{

    let capital=await this.props.client.query({
        query:gql`{capSocialActualDistribucion{
          ID_DISTRIBUCION,
          ID_PERSONA,
          PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},
          TIPO_TITULO,
          TIPO_CAPITAL,
          NUMERO_TITULOS,
          NUMERACION_TITULOS
        }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{		
       return result.data.capSocialActualDistribucion
		
	})
	
	
	
	
    for(let cap of capital){
      //buscamos el nombre de la persona
      
      if(cap.ID_TIPO_PERSONA===1){
		  this.state.personasFisicas.find(persona=>{
				if(persona.ID_PERSONA===cap.ID_PERSONA){				
					cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:''
					cap.GRAVAMENES=persona.GRAVAMENES
					cap.nombre=`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:''} ${persona.NOMBRE}`
				}
			})
		}else if(cap.ID_TIPO_PERSONA===2){
			this.state.personasJuridicas.find(persona=>{
				if(persona.ID_PERSONA===cap.ID_PERSONA){
					cap.NACIONALIDAD=persona.NACIONALIDAD?persona.NACIONALIDAD.DESCRIPCION:""
					cap.GRAVAMENES=persona.GRAVAMENES
				    cap.nombre= persona.RAZON_SOCIAL
				}
			})

		}				

    // for(let cap of capital){
    //   //buscamos el nombre de la persona
    //   let nombre=""
    //   if(cap.ID_TIPO_PERSONA===1){

    //     nombre=await this.props.client
    //       .query({
    //           query: gql` 
    //               {personasFisicas(FILTROS:{ID_PERSONA:${cap.ID_PERSONA}}){ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
    //           `,fetchPolicy:'network-only'
    //       }).then(result=>{ 
	// 		cap.NACIONALIDAD=result.data.personasFisicas[0].NACIONALIDAD?result.data.personasFisicas[0].NACIONALIDAD.DESCRIPCION:""
	// 		cap.GRAVAMENES=result.data.personasFisicas[0].GRAVAMENES
    //         return `${result.data.personasFisicas[0].APELLIDO1} ${result.data.personasFisicas[0].APELLIDO2?result.data.personasFisicas[0].APELLIDO2:""} ${result.data.personasFisicas[0].NOMBRE}`
    //       })

      //Si es persona juridica
    //   }else if(cap.ID_TIPO_PERSONA===2){

    //       nombre=await this.props.client
    //       .query({
    //           query: gql`
    //               {personasJuridicas(FILTROS:{ID_PERSONA:${cap.ID_PERSONA}}){ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
    //           `,fetchPolicy:'network-only'
    //       }).then(result=>{ 
	// 			cap.NACIONALIDAD=result.data.personasJuridicas[0].NACIONALIDAD?result.data.personasJuridicas[0].NACIONALIDAD.DESCRIPCION:""
	// 			cap.GRAVAMENES=result.data.personasJuridicas[0].GRAVAMENES
	// 			return result.data.personasJuridicas[0].RAZON_SOCIAL
    //       })

    
       
        let tiposKeys=Object.keys(this.obtenerPotencias(cap.TIPO_TITULO))
        let tipos=""

        tiposKeys.forEach(t=>{

            let tipoSel=null
            if(this.state.tipoCapital===1){
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_ACCION===parseInt(t)})
            }else{
              tipoSel=this.state.tiposParticipacion.find(tipo=>{return tipo.ID_TIPO_PARTICIPACION===parseInt(t)})
            }
            if(tipos===""){
              tipos+=tipoSel.DESCRIPCION
            }else{

              tipos+=","+tipoSel.DESCRIPCION

            }

        })
		cap.tipo=tipos
		
    }


	//console.log(capital)
    this.setState({capitalSocial:capital})
	this.cargarDatosCsv()

  }
  cargarCapitalActual=async()=>{
	let capitalActual=await this.props.client
	.query({
      query:gql`{capSocialActualDistribucion{
        ID_DISTRIBUCION,
        ID_PERSONA,
        PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}}
		TIPO_TITULO,
		TIPO_CAPITAL,
        NUMERO_TITULOS,
        NUMERACION_TITULOS
      }}`,fetchPolicy:'network-only'
    }).then(async(result)=>{
        return result.data.capSocialActualDistribucion
	})
	return capitalActual
  }
  cargarPersonasFisicas=async()=>{
	let personasFisicas= await this.props.client
	.query({
		query: gql` 
			{personasFisicas{ID_PERSONA,NOMBRE,APELLIDO1,APELLIDO2,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		`,fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.personasFisicas
	})
	return personasFisicas
  }
  cargarPersonasJuridicas=async()=>{
	let personasJuridicas=await this.props.client
	.query({
		query: gql`
			{personasJuridicas{ID_PERSONA,RAZON_SOCIAL,GRAVAMENES,NACIONALIDAD{DESCRIPCION}}}
		`,fetchPolicy:'network-only'
	}).then(result=>{ 
		return result.data.personasJuridicas
	})
	return personasJuridicas
  }
  cargarDatos=async()=>{
	  let personasFisicas= await this.cargarPersonasFisicas()
	  let personasJuridicas= await this.cargarPersonasJuridicas()
	  let capitalActual= await this.cargarCapitalActual()
	  this.setState({
		  personasFisicas:personasFisicas,
		  personasJuridicas:personasJuridicas,
		  capitalSocActual:capitalActual
	  })
  }

  cargarNumTitulos=async()=>{
	let numTitulos = 0;
  
	//obtencion de los titulos del capital social.
	let capital= this.state.capitalSocActual
	// await this.props.client
	// .query({
	//   query: gql`
	// 	{
	// 	  capSocialActualDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS"){
	// 		ID_PERSONA
	// 		NUMERO_TITULOS
	// 		PERSONA {TIPO_PERSONA{ID_TIPO_PERSONA}}}}
	//   `,fetchPolicy:'network-only'
	// })
	// .then(result => {
	// 	return result.data.capSocialActualDistribucion
	// })	
	  capital.forEach(socio => {
		numTitulos += socio.NUMERO_TITULOS;
	  });
	
	 this.setState({ numTitulos: numTitulos });

  }

  
	tipoCapital=async()=>{
		let personas= this.props.empresaPrincipal
	// 	 await this.props.client
	//   .query({
	// 	  query: gql`
	// 	  {
	// 		  personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
	// 	  }
	// 	  `,
	// 	  fetchPolicy:'network-only'
	//   }).then(result=>{ 
	// 		return result.data.personasJuridicas  
	//   })
		//   let tiposAccion= await this.props.client
		//   .query({
		// 	query:gql`{tiposAccion{
		// 		ID_TIPO_ACCION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`
		//   }).then(async(result)=>{
		// 	  return result.data.tiposAccion
		//   })
		//  let tiposParticipacion= await this.props.client
		//   .query({
		// 	query:gql`{tiposParticipacion{
		// 		ID_TIPO_PARTICIPACION,
		// 		DESCRIPCION,
		// 		INCOMPATIBLE,
		// 	}}`,fetchPolicy:'network-only'
		//   }).then(async(result)=>{
		// 	  return result.data.tiposParticipacion
		//   })
		 for(let persona of personas){
			if(persona.ID_TIPO_CAPITAL_SOCIAL===1){
				this.setState({
					tiposParticipacion:this.props.tiposAccion
				})
			}else{
				this.setState({
					tiposParticipacion:this.props.tiposParticipacion
				})
			}
			this.setState({tipoCapital: persona.ID_TIPO_CAPITAL_SOCIAL})
		 }
		  
	//   this.setState({tipoCapital:result.data.personasJuridicas[0].ID_TIPO_CAPITAL_SOCIAL})
	//   if(this.state.tipoCapital===1){

	// 	await this.props.client.query({
	// 	  query:gql`{tiposAccion{
	// 		  ID_TIPO_ACCION,
	// 		  DESCRIPCION,
	// 		  INCOMPATIBLE,
	// 	  }}`
	// 	}).then(async(result)=>{
	// 		this.setState({tiposParticipacion:result.data.tiposAccion})
	// 	})

	//   }else{

	// 	await this.props.client.query({
	// 	  query:gql`{tiposParticipacion{
	// 		  ID_TIPO_PARTICIPACION,
	// 		  DESCRIPCION,
	// 		  INCOMPATIBLE,
	// 	  }}`,fetchPolicy:'network-only'
	// 	}).then(async(result)=>{
	// 		this.setState({tiposParticipacion:result.data.tiposParticipacion})
	// 	})

	//   }
	}
	cargarFecha= async() =>{
		//CAP SOCIAL ACTUAL
		this.props.client
		.query({
			query: gql`
			{
				CapSocialActualRegs {
				FECHA_VALIDEZ
				VALOR_TITULO
				}
			}
			`,fetchPolicy:'network-only'
		})
		.then(result => {
			//si tenemos capita social inicial.
			if (result.data.CapSocialActualRegs.length > 0) {
			this.setState({
				existeCapitaSocial: true,
				valorTitulo: Intl.NumberFormat('es-ES').format(result.data.CapSocialActualRegs[0].VALOR_TITULO),
				fechaSeleccionada: result.data.CapSocialActualRegs[0].FECHA_VALIDEZ
			});
			}else{

				//CAP SOCIAL INICIAL
				// this.props.client
				// .query({
				// 	query: gql`
				// 	{
				// 		capSocialInicial {
				// 		FECHA_VALIDEZ
				// 		VALOR_TITULO
				// 		}
				// 	}
				// 	`,fetchPolicy:'network-only'
				// })
				// .then(result => {
					//si tenemos capita social inicial.
					if (this.props.capSocialInicial.length > 0) {
						this.props.capSocialInicial.forEach(capital=>{
							this.setState({
								valorTitulo: Intl.NumberFormat('es-ES').format(capital.VALOR_TITULO),
								fechaSeleccionada: capital.FECHA_VALIDEZ
							});
						})
					
					}
			
			}
		});   
	}
	generarImprimir=async()=>{
		this.setState({
			cargandoImprimir:true,
			modalImprimir:true}, async ()=>{
				this.setState({cargandoImprimir:false}, () =>{
					ReactDOM.render(this.ImprimirCap(), document.getElementById('generarImprimir-pdf'))
				})
			})
	}

	ImprimirCap=()=>(
	<PDFViewer style={{width: '100%', height: '100%'}}>
		<Document>
		<Page size="A4" orientation="landscape" style={styles.page}>
			<View style={styles.body}>
				<View fixed style={styles.row}>
				<Image
					style={styles.imageHeadL}
					src={this.props.logotipo?this.props.logotipo:pantallaInicio}
					/>
				</View>
				<View fixed style={styles.rowHead}>
					<Text style={styles.labelHead}>{this.props.i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
					<Text style={styles.text}>{this.props.datosEmpresa?this.props.datosEmpresa.razonSocial:""}</Text>
				</View>
				<View fixed style={styles.rowHeadTwo}>
					<Text style={styles.labelHead}>{this.props.i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
					<Text style={styles.text}>{this.props.i18next.t("estructurasocietaria.title",{ns:"page"}).toUpperCase()}</Text>
				</View>
				<View style={styles.rowTitleOne}>
					<Text style={styles.labelTitle}>{this.props.i18next.t("capitalsocialactual.title",{ns:"page"}).toUpperCase()}</Text>
				</View>
					{this.state.datosImpresion?this.state.datosImpresion.map((dato, index)=>{ 
						let inicial="INICIAL";
						let actual="ACTUAL"
						return(
							<View key={index}>
							{index ===0 && (
							<View style={styles.colHead}>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>Nº</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>{this.props.i18next.t("capitalsocialactual.type",{ns:"page"}).toUpperCase()}</Text> 
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("capitalsocialactual.socio",{ns:"page"}).toUpperCase()}</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("capitalsocialactual.nacionalidad",{ns:"page"}).toUpperCase()}</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'25%'}}>{this.props.i18next.t("capitalsocialactual.tipo",{ns:"page"}).toUpperCase()}</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'10%'}}>{this.props.i18next.t("capitalsocialactual.numtitulos",{ns:"page"}).toUpperCase()}</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'5%'}}>%</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', borderRightWidth: 1, fontSize:8, fontFamily: 'Open Sans Bold', borderRightStyle: 'solid', borderRightColor: '#000000', width:'15%'}}>{this.props.i18next.t("capitalsocialactual.numtitulos",{ns:"page"}).toUpperCase()}</Text>
								<Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'10%'}}>{this.props.i18next.t("capitalsocialactual.gravamenes",{ns:"page"}).toUpperCase()}</Text>
                                                               
							</View>
							)}
								<View style={styles.colHead}>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{index+1}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{dato.TIPO_CAPITAL==="I"?`${inicial}`:`${actual}`}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.nombre?dato.nombre:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NACIONALIDAD?dato.NACIONALIDAD:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'25%'}}>{dato.tipo?dato.tipo:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'10%'}}>{dato.NUMERO_TITULOS?dato.NUMERO_TITULOS:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'5%'}}>{parseFloat((dato.NUMERO_TITULOS/this.state.numTitulos)*100).toFixed(2)}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',borderRightWidth: 1,fontSize:8,fontFamily: 'Open Sans',borderRightStyle: 'solid',borderRightColor: '#000000',width:'15%'}}>{dato.NUMERACION_TITULOS?dato.NUMERACION_TITULOS:''}</Text>
									<Text style={{textAlign:'center',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'10%'}}>{dato.GRAVAMENES?dato.GRAVAMENES:''}</Text>
								</View>                               
							
						</View>
						)
					}):<></>}      
			</View>

			<Text
				style={styles.footerPage}
				render={({ pageNumber, totalPages }) => this.props.i18next.t("page",{ns:"global"})+' '+pageNumber+' '+this.props.i18next.t("de",{ns:"global"})+' '+totalPages}
				fixed
			/>
			<Image
					style={styles.icono}
					src={iconoFooter}
					fixed
					/>
			<Text fixed style={styles.footer}>{this.props.i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {this.props.i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
		</Page>
		</Document>
	 </PDFViewer>
	)
  componentDidMount=async()=>{

	this.setState({cargando: true}, async ()=> {
		this._isMounted = true;
		await this.cargarDatos()
		await this.tipoCapital()
		await this.cargarCapital()
		await this.cargarNumTitulos()

	//si el componente ha terminado de montarse.
	if (this._isMounted) {
		await this.cargarFecha()
		//cogemos la fecha y el valor del titulo del cap social actual y si no existen
		//lo cogemos del inicial

		
	}

	this.setState({cargando: false})
   })
  }


  cambiarIndiceMostrar=async(indice)=>{
	   this.setState({
		   indiceRegistros:indice,
		   cargandoGrid:true
		},async()=>{
			await this.cargarCapital()
			this.setState({cargandoGrid:false})
		})
	  
   }

	
  render() {

	document.title =this.props.i18next.t("lexcontrol",{ns:"global"})+" | "+this.props.i18next.t("capitalsocialactual.title",{ns:"page"})
	
const numTit=this.state.numTitulos;
  function tipoFormatter(cell, row) {
  return (
	(cell === "I") ? "Inicial": "Actual"
  );
  }

  function porcFormatter(cell, row) {
	return (
	  ((row.NUMERO_TITULOS/numTit)*100).toFixed(2)
	);
}
function gravFormatter(cell, row) {
	return (
	  (cell)?cell:""
	);
}

  const columns = [
  {
  dataField: 'TIPO_CAPITAL',
  text: this.props.i18next.t("capitalsocialactual.type",{ns:"page"}),
  formatter: tipoFormatter
  },{
	dataField: 'nombre',
	text: this.props.i18next.t("capitalsocialactual.socio",{ns:"page"})
	},{
		dataField: 'NACIONALIDAD',
		text: this.props.i18next.t("capitalsocialactual.nacionalidad",{ns:"page"})
		},{
			dataField: 'tipo',
			text: this.props.i18next.t("capitalsocialactual.tipo",{ns:"page"})
			},{
				dataField: 'NUMERO_TITULOS',
				text: this.props.i18next.t("capitalsocialactual.numerotitulos",{ns:"page"})
				},{
					dataField: '',
					text: '%',
					formatter: porcFormatter
					},{
						dataField: 'NUMERACION_TITULOS',
						text: this.props.i18next.t("capitalsocialactual.numtitulos",{ns:"page"})
						},{
							dataField: 'GRAVAMENES',
							text: this.props.i18next.t("capitalsocialactual.gravamenes",{ns:"page"}),
							formatter: gravFormatter
							}
  ];

	const formatearMiles=(numero)=>{
		return numero.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); 
	}

	const cambiarValorTitulo = ev => {
	    //solo permitir en el input numeros,comas y puntos.
		let pattern=/^[0-9,.]*$/
		if(pattern.test(ev.currentTarget.value)===false)return

		//comprobacion que sea un número válido
		let pattern2=/^\d{1,3}(\.\d{3})*(\,\d{2})?$/
		if(pattern2.test(formatearMiles(ev.currentTarget.value.replace(/\./g, '')))){
			this.setState({valorTituloValido:true})
		}else{
			this.setState({valorTituloValido:false})
		}

		this.setState({valorTitulo:formatearMiles(ev.currentTarget.value.replace(/\./g, '')) })
	};

	const cerrarModal = () => {
	  this.setState({
		abrirModalGuardarDatos: false,
		abrirModalDistribucion: false,
		modalBorrarAbierto:false
	  },async ()=>{
		this.setState({cargando: true}, async ()=> {
			this._isMounted = true;
			await this.cargarDatos()
			await this.tipoCapital()
			await this.cargarCapital()
			await this.cargarNumTitulos()
	
		//si el componente ha terminado de montarse.
		if (this._isMounted) {
			await this.cargarFecha()
			//cogemos la fecha y el valor del titulo del cap social actual y si no existen
			//lo cogemos del inicial
	
			
		}
	
		this.setState({cargando: false})
	   })
	  });
	};

	const escogeFecha = nuevaFecha => {
	  this.setState({
		fechaSeleccionada: moment(nuevaFecha).format("YYYY-MM-DD")
	  });
	};



	const abrirModalGuardar = rowIndex => {

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
			return
		}

	  this.setState({ abrirModalGuardarDatos: true });
	};

	const abrirModalDistribucion =async() => {
	  
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
		  return
	  }

	   //CAP SOCIAL INICIAL
	   let capInicial= this.props.capSocialInicial
	//    await this.props.client
	//    .query({
	// 	   query: gql`
	// 	   {
	// 		   capSocialInicial {
	// 		   FECHA_VALIDEZ
	// 		   VALOR_TITULO
	// 		   }
	// 	   }
	// 	   `,fetchPolicy:'network-only'
	//    })
	//    .then(result => {
	// 	   //si tenemos capita social inicial.
	// 	   if (result.data.capSocialInicial.length > 0) {
	// 		 return true;
	// 	   }
	// 	   return false;
	//    });

	   if(!capInicial){
		  alert(this.props.i18next.t("capitalsocialactual.valid2",{ns:"page"}))
		  return
	   }

	  if(this.state.capitalSocial.length===0){
		alert(this.props.i18next.t("capitalsocialactual.valid1",{ns:"page"}))
		return
	  }

	  this.setState({ 
		distribucionSeleccionada:null,
		nuevoActivado:true,
		editarActivado:false,
		borrarActivado:false,
		abrirModalDistribucion: true
	  });
	};

	const abrirModalDistribucionEditar=()=>{

		//si no tiene permisos para editar la pantalla
		if(this.props.editable===false){
			alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
			return
		}

	  if(!this.state.distribucionSeleccionada){
		alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
		return
	  }

	  this.setState({ 
		abrirModalDistribucion: true,
		nuevoActivado:false,
		editarActivado:true,
		borrarActivado:false
	  });

	}

	const abrirModalDistribucionBorrar=()=>{

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		  alert(this.props.i18next.t("sinpermiso",{ns:"global"}))
		  return
	  }

	  if(!this.state.distribucionSeleccionada){
		alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
		return
	  }

	  this.setState({ 
		modalBorrarAbierto: true,
		nuevoActivado:false,
		editarActivado:false,
		borrarActivado:true
	  });

	}

	const getMomentFormatter = (format) => {
	  // note that locale argument comes from locale prop and may be undefined
	  return {
		formatDate: (date, locale) =>
		  moment(date)
			.locale(locale)
			.format(format),
		parseDate: (str, locale) =>
		  moment(str, format)
			.locale(locale)
			.toDate(),
		placeholder: format
	  };
	};

	const guardarCambiosCapitalSocial = () => {
	  let query = "";
	  if(!this.state.valorTituloValido)return
	  //si no existe el capital social lo creamos
	  if (!this.state.existeCapitaSocial) {
		query = gql`mutation{crearCapSocialActual(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  } else {
		// de lo contrario, lo actualizariamos
		query = gql`mutation{actualizarCapSocialActual(VALORES:{FECHA_VALIDEZ:"${
		  moment(this.state.fechaSeleccionada).format("YYYY-MM-DD")
		}",VALOR_TITULO:${this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.')}}){FECHA_VALIDEZ}}`;
	  }
	  //guardar el capital social.
	  this.props.client.mutate({
		mutation: query
	  });

	  alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
	  
	  this.setState({existeCapitaSocial:true})

	  cerrarModal();
	};

	const aceptar = async() => {
		this.setState({cargandoSaveCapAct:true});
	  		if(!this.nuevoTitulo.state.socioSeleccionado) {
				this.setState({cargandoSaveCapAct:false});
				return
			}
			let tipoTitulo=0
			for (let key in this.nuevoTitulo.state.activadoCheckbox) {
				//damos de alta los tipos de participaciones
				tipoTitulo+=parseInt(key.replace("\"",""))
			}
			
			let existe=false
			if(this.state.nuevoActivado){
			
				this.state.capitalSocActual.find(capital=>{
					if(capital.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && capital.TIPO_TITULO===tipoTitulo){
						existe=true
						console.log(existe)
					}
				})
				// //comprobar que no exista un socio con ese tipo de titulo.
				// let existe=await this.props.client
				// .query({
				//   query: gql`
				// 	{
				// 	  capSocialActualDistribucion (CAMPOS:"ID_PERSONA,NUMERO_TITULOS",FILTROS:{ID_PERSONA:${this.nuevoTitulo.state.socioSeleccionado.value},TIPO_TITULO:${tipoTitulo}}){
				// 		ID_PERSONA
				// 		NUMERO_TITULOS
				// 		PERSONA {
				// 		  TIPO_PERSONA {
				// 			ID_TIPO_PERSONA
				// 		  }
				// 		}
				// 	  }
				// 	}
				//   `,fetchPolicy:'network-only'
				// })
				// .then(result => {
				//   if(result.data.capSocialActualDistribucion.length>0)return true;
				//   return false;
				// });
				if(existe===true){
					this.setState({cargandoSaveCapAct:false});
					alert(this.props.i18next.t("capitalsocialactual.valid3",{ns:"page"}))
					return
				}
		
				await this.nuevoTitulo.aceptarDistribución()
				//si se ha creado el titulo
				if(this.nuevoTitulo.state.tituloCreado){
					await this.cargarCapital()
					await this.cargarNumTitulos()
		
					this.setState({
						cargandoSaveCapAct:false,
						distribucionSeleccionada:null
					})
		
					cerrarModal()
		
				}
				this.setState({cargandoSaveCapAct:false}); 
			} else {
	
				//comprobar que no exista un socio con ese tipo de titulo.
				let existe=this.state.capitalSocial.find(cap=>{ return cap.ID_PERSONA===this.nuevoTitulo.state.socioSeleccionado.value && cap.TIPO_TITULO===tipoTitulo && cap.ID_DISTRIBUCION!==this.state.distribucionSeleccionada.ID_DISTRIBUCION})
				if(existe){
					this.setState({cargandoSaveCapAct:false});
					alert(this.props.i18next.t("capitalsocialactual.valid3",{ns:"page"}))
					return
				}
		
				await this.nuevoTitulo.aceptarDistribución()
				//si se ha creado el titulo
				if(this.nuevoTitulo.state.tituloActualizado){
					await this.cargarCapital()
					await this.cargarNumTitulos()
		
					
					this.setState({
						cargandoSaveCapAct:false,
						distribucionSeleccionada:null
					})
		
					cerrarModal()
				} 
				this.setState({cargandoSaveCapAct:false});
			}

	  
   
	};

	const handleOnSelect=(row, isSelect) => {
	if (isSelect) {
	this.setState({
		distribucionSeleccionada: row
	});
	}
	return true;
	}

	const eliminarDistribucion=async()=>{
	  
	  //eliminamos la distribucion
	  await this.props.client.mutate({
		mutation:gql`mutation{eliminarcapSocialActualDistribucion(ID_DISTRIBUCION:${this.state.distribucionSeleccionada.ID_DISTRIBUCION}){ID_DISTRIBUCION}}`
	  })
	  .then(async result=>{

		this.setState({
		  distribucionSeleccionada:null
		})
		await this.cargarCapital()
		await this.cargarNumTitulos()
		
		alert(this.props.i18next.t("deletecorrecto",{ns:"global"}))

		cerrarModal()
		
	  }).catch(err=>{
		alert(this.props.i18next.t("error",{ns:"global"}))
	  })

	  
	}
	const cerrarModalImprimir= () => {
			this.setState({
				modalImprimir:false
			})
	}

	return (
	  <>
		  {
			this.state.cargando === true?
			<Cargando />
			:
			<>
				<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={this.props.i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimir}
                    style={styles.dialog}
                >
                    {!this.state.cargandoImprimir?
                            <div style={{ width: '100%', height: '600px'}} id="generarImprimir-pdf"></div>
                            :
                                <Spinner />
                        }
				</Dialog>		
				<Dialog
					transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title="Datos capital inicial"
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.abrirModalGuardarDatos}
				>
				<div className={Classes.DIALOG_BODY}>
					<p>{this.props.i18next.t("capitalsocialactual.datoscapital",{ns:"page"})}:</p>
					<br />
					<p>
					{this.props.i18next.t("capitalsocialactual.fechavalidez",{ns:"page"})}
					{moment(this.state.fechaSeleccionada).format("DD-MM-YYYY")}
					</p>
					<p>{this.props.i18next.t("capitalsocialactual.valorindividual",{ns:"page"})}: {this.state.valorTitulo}</p>
					<br />
					<p>{this.props.i18next.t("capitalsocialactual.questionsave",{ns:"page"})}</p>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={guardarCambiosCapitalSocial}
						>
						{this.props.i18next.t("yes",{ns:"global"})}
						</AnchorButton>
					</Tooltip>
					<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
						<Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={cerrarModal}
				title={this.props.i18next.t("capitalsocialactual.titleguardando",{ns:"page"})}
				canEscapeKeyClose={false}
				canOutsideClickClose={false}
				isCloseButtonShown={false}
				usePortal={true}
				isOpen={this.state.cargandoSaveCapAct}
				>
					<Cargando />
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="warning-sign"
				onClose={cerrarModal}
				title={this.props.i18next.t("capitalsocialactual.titledistribucion",{ns:"page"})}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.abrirModalDistribucion}
				>
				<NuevoTituloSociosActual datosDistribucion={this.state.distribucionSeleccionada} ref={(input) => { this.nuevoTitulo= input; }} i18next={this.props.i18next}/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip
						content={this.props.i18next.t("aceptar",{ns:"global"})}
						position="top"
					>
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={aceptar}
						>
						{this.props.i18next.t("aceptar",{ns:"global"})}
						</AnchorButton>
					</Tooltip>
					<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
						<Button onClick={cerrarModal}>{this.props.i18next.t("cancelar",{ns:"global"})}</Button>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog transitionDuration={400}
					autoFocus={true}
					enforceFocus={true}
					icon="warning-sign"
					onClose={cerrarModal}
					title={this.props.i18next.t("delete",{ns:"global"})}
					canEscapeKeyClose={true}
					canOutsideClickClose={true}
					isCloseButtonShown={true}
					usePortal={true}
					isOpen={this.state.modalBorrarAbierto}
				>
						<div className={Classes.DIALOG_BODY}>
							<h2>{this.props.i18next.t("capitalsocialactual.questiondeldistribucion",{ns:"page"})}</h2>
						</div>
						<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Tooltip content={this.props.i18next.t("delete",{ns:"global"})} position="top">
								<AnchorButton
									intent={Intent.PRIMARY}
									onClick={eliminarDistribucion}
								>
									{this.props.i18next.t("yes",{ns:"global"})}
								</AnchorButton>
							</Tooltip>
							<Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
								<Button onClick={cerrarModal}>{this.props.i18next.t("no",{ns:"global"})}</Button>
							</Tooltip>
						</div>
					</div>
				</Dialog>
				<div className="tablaTreinta">
					<Card  id="datos-capital-social-actual"  interactive={true} elevation={Elevation.TWO}>
						<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("capitalsocialactual.infocapital",{ns:"page"})}</Callout>
					<br />
					<FormGroup label={this.props.i18next.t("capitalsocialactual.fechavalidez",{ns:"page"})+":"} labelFor="informacion">
					<DateInput minDate={new Date(1900, 1 ,1)}
						{...getMomentFormatter("DD-MM-YYYY")}
						locale="es"
						onChange={escogeFecha}
						value={new Date(this.state.fechaSeleccionada)}
					/>
					</FormGroup>
					<br />
					<FormGroup label={this.props.i18next.t("capitalsocialactual.valortitulo",{ns:"page"})+":"} labelFor="informacion">
					<InputGroup
						name="Valor del titulo"
						value={this.state.valorTitulo}
						onChange={cambiarValorTitulo}
						type="text"
					/>
					</FormGroup>
					<br />
					<FormGroup label={this.props.i18next.t("capitalsocialactual.numerotitulos",{ns:"page"})+":"} labelFor="informacion">
					<InputGroup
						name="Número de títulos"
						value={this.state.numTitulos}
						disabled
					/>
					</FormGroup>
					<br />
					<FormGroup
					label={this.props.i18next.t("capitalsocialactual.capitalsocialactual",{ns:"page"})+":"}
					labelFor="informacion"
					>
					<InputGroup
						name="Capital social actual (total)"
						value={
							Intl.NumberFormat('es-ES').format(parseFloat(this.state.valorTitulo?this.state.valorTitulo.replace(/\./g, '').replace(/\,/g, '.'):this.state.valorTitulo) * this.state.numTitulos) + " €"
						}
						disabled
					/>
					</FormGroup>
					<Button
					alignText="right"
					icon="floppy-disk"
					text={this.props.i18next.t("guardar",{ns:"global"})}
					onClick={abrirModalGuardar}
					className="bp3-intent-danger iconoDerecha bp3-minimal"
					/>
					<br />
					<br />
				</Card>
				</div>
				<div className="tablaSetenta">
				<Card  id="vista-general-estado-social"  interactive={true} elevation={Elevation.TWO}>
				<Callout className="pixelAlrededorGrande callout-centrado bp3-intent-danger">{this.props.i18next.t("capitalsocialactual.titledistribucion",{ns:"page"})}</Callout>
					<Navbar>
					<Navbar.Group>
						<NavbarHeading>
							{this.props.i18next.t("capitalsocialactual.introduzca",{ns:"page"})}
						</NavbarHeading>
					</Navbar.Group>
					</Navbar>
					<Navbar>
					<Navbar.Group>
						<ButtonGroup align={Alignment.LEFT}>
						<Button
							className="bp3-minimal"
							icon="add"
							text={this.props.i18next.t("new",{ns:"global"})}
							active={this.state.nuevoActivado}
							onClick={abrirModalDistribucion}
						/>
						<Button
							className="bp3-minimal"
							icon="edit"
							text={this.props.i18next.t("edit",{ns:"global"})}
							onClick={abrirModalDistribucionEditar}
							active={this.state.editarActivado}
						/>
						<Button
							className="bp3-minimal"
							icon="delete"
							text={this.props.i18next.t("delete",{ns:"global"})}
							onClick={abrirModalDistribucionBorrar}
							active={this.state.borrarActivado}
						/>
						<Button
								className="bp3-minimal"
								icon="print"
								text={this.props.i18next.t("print",{ns:"global"})}
								active={this.state.imprimirActivado}
								onClick={()=>this.generarImprimir()}
							/>
						{this.state.cargandoCsv===false?
							<Button
							className="bp3-minimal"
							icon="document"
							>
							<CSVLink data={this.state.datosCsv} separator={";"} enclosingCharacter={``} filename={"Capital_Actual.csv"}>{this.props.i18next.t("exportar",{ns:"global"})}</CSVLink>
							</Button>
						:
							<Button
							className="bp3-minimal"
							icon="document"
							text={this.props.i18next.t("cargando",{ns:"global"})}
							/>
						}
						</ButtonGroup>
					</Navbar.Group>
					</Navbar>
					{
					this.state.cargandoGrid === true?
					<Cargando />
					:
					<div  id="tabla-distribucion-actual">
						<BootstrapTable
						keyField='ID_DISTRIBUCION'
						data={ this.state.capitalSocial }
						columns={ columns }
						selectRow={ const_table.selectRow(handleOnSelect) }
						pagination={ paginationFactory(const_table.options) }
						headerClasses="header-class"
						rowClasses="row-class"
						hover
						condensed
						noDataIndication={ this.props.i18next.t("datavacio",{ns:"global"}) }
						/>
						
					</div>
  					}
				</Card>
				</div>
			</>
		  }
	  </>
	);
  }
  componentWillUnmount(props) {
	this._isMounted = false;
  }
}


export default withApollo(EstadoCapitalSocial)