import React, { Component } from "react";
import {
  Card,
  Navbar,
  ButtonGroup,
  Alignment,
  Popover,
  Button,
  NavbarDivider,
  Dialog,
  Classes,
  Tooltip,
  AnchorButton,
  Intent,
  Elevation,
  Spinner,
  Callout
} from "@blueprintjs/core";
import InformacionGeneralNuevo from "../Pantallas/Elementos/InformacionGeneralNuevo";
import { DatosPersonasFisicas } from "./Elementos/DatosPersonasFisicas";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { DatosPersonasJuridicas } from "./DatosPersonasJuridicas";
import moment from "moment"
import pantallaInicio from "../../Assets/LOGO REGISTRADO PEQUEÑO.png"
import { Cargando } from "../Pantallas/Cargando"
import ReactDOM from 'react-dom';
import iconoFooter from '../../Assets/lc.png';
import { PDFViewer, Page,Text,View,Document,Image} from '@react-pdf/renderer';
import i18next from 'i18next';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import const_table from "./BootstrapTable";
import styles from './stylesPrint';

class OtrasPartesInteresadas extends Component {
  state = {
	modalNuevoAbierto: false,
	editarActivado: false,
	borrarActivado: false,
	otrasPersonas: [],
	personaSeleccionada:null,
	cargando:false,
	cargandoForm:false,
	datosEmpresa:null,
	cargandoImprimirG:false,
	modalImprimirG: false,
	logotipo:null,
	personasFisicas:null,
	personasJuridicas:null,
	cargandoGrid:false
  };


  cargarPersonas = async () => {
	let personas = [];
	this.setState({cargando:true})
	//console.log(this.state.personasFisicas);
	//console.log(this.state.personasJuridicas);
	for(let persona of this.state.personasFisicas){
		personas.push({"value":persona.ID_PERSONA,"nombre":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

	}

	for(let persona of this.state.personasJuridicas){
		personas.push({"value":persona.ID_PERSONA,"ID_PERSONA":persona.ID_PERSONA,"nombre":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 
	}

	this.setState({
	  otrasPersonas: personas,
	  cargando:false
	});
  };

  obtenerLogoEmpresa=async()=>{
	//obtencion logo de la empresa
	 await this.props.client
	 .query({
		 query: gql`
			 {adjuntos(FILTROS:{NOMBRE:"LOGOTIPO_EMPRESA",ID_ENTIDAD_ASOCIADA:12,ID_ASOCIADO:0}){ID_ADJUNTO,DATOS}}
		 `,fetchPolicy:'network-only'             
	 }).then(result=>{ 
		 if(result.data.adjuntos.length>0){

			 var request = require('request').defaults({ encoding: null,withCredentials:true });

			 request.get(process.env.REACT_APP_DOMINIO+""+result.data.adjuntos[0].DATOS.replace(".",""),  (error, response, body)=> {
				 if (!error && response.statusCode === 200) {
				 let data = "data:" + response.headers["content-type"] + ";base64," + new Buffer(body).toString('base64');
				 this.setState({logotipo:data})
				 }
			 });

		 }
	 })
 
 }

 
generarImprimirG= async() => {
	this.setState({
		cargandoImprimirG:true,
		modalImprimirG:true,
		cargandoGrid:true,
		personaSeleccionada:null
	}, async () => {
		 this.setState({cargandoImprimirG:false}, () => {
			 ReactDOM.render(this.ImprimirG(), document.getElementById('generarG-pdf'))
		 })
	})
}


ImprimirG=()=>(
	<PDFViewer style={{width: '100%', height: '100%'}}>
	<Document>
	  <Page size="A4" style={styles.page}>
		<View style={styles.body}>
			<View fixed style={styles.row}>
			  <Image
				style={styles.imageHeadL}
				src={this.state.logotipo?this.state.logotipo:pantallaInicio}
				/>
			</View>
			<View fixed style={styles.rowHead}>
				<Text style={styles.labelHead}>{i18next.t("company",{ns:"global"}).toUpperCase()}:</Text>
				<Text style={styles.text}>{this.state.datosEmpresa?this.state.datosEmpresa.razonSocial:""}</Text>
			</View>
			<View fixed style={styles.rowHeadTwo}>
				<Text style={styles.labelHead}>{i18next.t("report",{ns:"global"}).toUpperCase()}:</Text>
				<Text style={styles.text}>{i18next.t("otraspartesinteresadas.title",{ns:"page"}).toUpperCase()}</Text>
			</View>
				{this.state.otrasPersonas?this.state.otrasPersonas.map((cliente, index)=>{
					return(
						<View key={index}>
						   {index ===0 && (
							<View style={styles.colHead}>
							   <Text style={{textAlign:'center',flexDirection: 'column', fontSize:8, fontFamily: 'Open Sans Bold', width:'100%'}}>{i18next.t("name",{ns:"global"}).toUpperCase()}</Text>
							</View>
						   )}
							<View style={styles.colHead}>
								<Text style={{textAlign:'left',flexDirection: 'column',fontSize:8,fontFamily: 'Open Sans',width:'100%'}}>{cliente.nombre?cliente.nombre:''}</Text>
							</View>                               
						
					</View>
					)
				}):<></>}      
		</View>

		<Text
			style={styles.footerPage}
			render={({ pageNumber, totalPages }) => i18next.t("page",{ns:"global"})+' '+pageNumber+' '+i18next.t("de",{ns:"global"})+' '+totalPages}
			fixed
		  />
		<Image
				style={styles.icono}
				src={iconoFooter}
				fixed
				/>
		<Text fixed style={styles.footer}>{i18next.t("footpage",{ns:"global"})} {moment().format("DD/MM/YYYY")} {i18next.t("at",{ns:"global"})} {moment().format("HH:mm")}</Text>
	  </Page>
	</Document>
  </PDFViewer>
)

cargarDatos=async()=> {
	let datosEmpresa= await this.cargarEmpresa()
	let personasFisicas=await this.cargarPersonasFisicas()
	let personasJuridicas=await this.cargarPersonasJuridicas()
	
	this.setState({
		datosEmpresa:datosEmpresa,
		personasFisicas: personasFisicas,
		personasJuridicas: personasJuridicas,
	})

}

cargarEmpresa= async() => {
         
	//DATOS JURIDICOS
	let datosEmpresa= await this.props.client
	.query({
		query: gql`
		{
			personasJuridicas(FILTROS:{ID_PERSONA:1}){RAZON_SOCIAL}
		}
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{
		//si tenemos razon social.
		if(result.data.personasJuridicas.length>0){
			return {
				"razonSocial":result.data.personasJuridicas[0].RAZON_SOCIAL
			}

		}
		
	})

   return datosEmpresa

}

cargarPersonasFisicas= async () => {
	let personasFisicas=this.props.client
	.query({
		query: gql` 
		{
			personasFisicasOtras {
			  ID_PERSONA
			  NOMBRE
			  APELLIDO1
			  APELLIDO2
			  PERSONA {
				TIPO_PERSONA{ID_TIPO_PERSONA}
			  }
			}
		  }
		`,
		fetchPolicy: 'network-only'

	}).then(result=>{ 
	 return result.data.personasFisicasOtras;
	})
	
	return personasFisicas;
   
}

cargarPersonasJuridicas= async () => {
	let personasJuridicas=this.props.client
	.query({
		query: gql`
		{
			personasJuridicasOtras {
			  ID_PERSONA
			  RAZON_SOCIAL
			  EMPRESA_PRINCIPAL
			  PERSONA {
				TIPO_PERSONA{ID_TIPO_PERSONA}
			  }
			}
		  }
		`,
		fetchPolicy: 'network-only'

   }).then(result=>{
	 return result.data.personasJuridicasOtras;
	})
	
	return personasJuridicas;
	
}

  componentDidMount = props => {
	this.setState({
		cargandoForm: true
	},async () => {
		await this.obtenerLogoEmpresa()
		await this.cargarDatos()
		await this.cargarPersonas();
		this.setState({cargandoForm: false})
	}); 
  };

  
  render() {

	document.title =i18next.t("lexcontrol",{ns:"global"})+" | "+i18next.t("otraspartesinteresadas.title",{ns:"page"})

	const columns = [
		{
		dataField: 'nombre',
		text: i18next.t("name",{ns:"global"})
		}
	];
	  
	const Botonera = () => {
	  return (
		<>
		  <Button
			className="bp3-minimal"
			icon="new-link"
			text={i18next.t("newpersfisica",{ns:"global"})}
			fill={true}
			onClick={abrirModalNuevaPersona}
		  />
		  <br />
		  <Button
			className="bp3-minimal"
			icon="git-new-branch"
			text={i18next.t("newpersjuridica",{ns:"global"})}
			onClick={abrirModalNuevaPersonaJurídica}
		  />
		</>
	  );
	};

	const abrirModalNuevaPersona = () => {

	  //abrir modal de persona física
	  this.setState({
		  personaSeleccionada:null,
		  modalPersonaAbierto: true,
		  popoverAbierto: false,
		  nuevaPresonaActivado: true,
		  editarPersonaActivado: false,
		  cargandoGrid:true
	  });
	};

	const abrirModalNuevaPersonaJurídica = () => {
	  //abrir modal de persona jurídica
	  this.setState({
		modalNuevaJuridicaAbierto: true,
		popoverAbierto: false,
		nuevaPresonaActivado: true,
		editarPersonaActivado: false,
		cargandoGrid:true
	  });

	};

	const crearPersona = async () => {
	  await this.datosPersona.crearPersona();
	  if (this.datosPersona.state.idPersonaCreada) {
		//cerrar el modal
		await this.cargarPersonas();

		cerrarModal();

	  }
	};

	const actualizarPersona = async () => {
	  await this.datosPersona.actualizarPersona();
	  if (this.datosPersona.state.idPersonaActualizada) {
		//cerrar el modal

		await this.cargarPersonas();

		cerrarModal();
	  }
	};

	const abrirModalEditarPersona = () => {

	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert(i18next.t("sinpermiso",{ns:"global"}))
		return
	  }

	 if (!this.state.personaSeleccionada) {
		alert(i18next.t("noseleccionado",{ns:"global"}));
		return;
	  }

	  this.setState({ nuevaPresonaActivado: false });
	  this.setState({ editarPersonaActivado: true });


	  //si es personas física
	  if (this.state.personaSeleccionada.tipo === 1) {
		this.setState({ modalPersonaAbierto: true });
		//si es persona jurídica
	  } else if (this.state.personaSeleccionada.tipo === 2) {
		this.setState({ modalEditarPersonaJuridica: true });
	  }
	};

	const cerrarModal = () => {
	  this.setState({
		modalEditarPersonaJuridica: false,
		modalNuevaJuridicaAbierto: false,
		modalPersonaAbierto: false,
		cargandoGrid:false,
		personaSeleccionada:null
	   });
	};

	const guardarPersonaJuridica = async () => {

	   
 
	  

	  let result=await this.informacion.handleGuardarDatos() 
	  if(!result)return;
	  await this.cargarPersonas();

		this.setState({ modalNuevaJuridicaAbierto: false,cargandoGrid:false,personaSeleccionada:null });

	};

	const abrirPopover = () => {
	  //si no tiene permisos para editar la pantalla
	  if(this.props.editable===false){
		alert(i18next.t("sinpermiso",{ns:"global"}))
		return
	  }else{
		this.setState({ popoverAbierto: true });
	  }
	};

	const handleOnSelect=(row, isSelect) => {
	if (isSelect) {
	this.setState({
	personaSeleccionada: row
	});
	}
	return true;
	}

	
  const cerrarModal2 = async () => {
	this.setState({
		modalEditarPersonaJuridica:false,
	},()=>{
	   this.cargarPersonas()
	})
  }

  const cerrarModalImprimir= () => {
	this.setState({
		modalImprimirG:false,
		cargandoGrid:false
	})
}
	return (
	  <>
		  {
			  this.state.cargandoForm === true?
			  <Cargando />
			  :
			  <>
			  	<Dialog 
                    transitionDuration={400}
                    autoFocus={true}
                    enforceFocus={true}
                    icon="print"
                    onClose={cerrarModalImprimir}
                    title={i18next.t("print",{ns:"global"})}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    isCloseButtonShown={true}
                    usePortal={true}
                    isOpen={this.state.modalImprimirG}
                    style={styles.dialogL}
                >
                    {!this.state.cargandoImprimirG?
                            <div style={{ width: '100%', height: '500px'}} id='generarG-pdf'>
                            </div>
                            :
                                <Spinner />
                        }

                </Dialog>
                
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal}
				title={i18next.t("propcompay",{ns:"global"})}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.modalNuevaJuridicaAbierto}
				className="datos-empresa-dialog"
				>
				<InformacionGeneralNuevo
					ref={input => {
					this.informacion = input;
					}}
					i18next={i18next}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content={i18next.t("close",{ns:"global"})} position="top">
						<Button
						alignText="left"
						icon="log-out"
						text={i18next.t("close",{ns:"global"})}
						intent="danger"
						onClick={cerrarModal}
						className="iconoIzquierda bp3-minimal"
						/>
					</Tooltip>
					<Tooltip content={i18next.t("guardar",{ns:"global"})} position="top">
						<Button
						alignText="left"
						icon="floppy-disk"
						text={i18next.t("guardar",{ns:"global"})}
						intent="danger"
						onClick={guardarPersonaJuridica}
						className="iconoIzquierda bp3-minimal"
						/>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal}
				title={i18next.t("propiedadpersona",{ns:"global"})}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				className="dialog-ancho"
				isOpen={this.state.modalPersonaAbierto}
				>
				<DatosPersonasFisicas
					datosPersona={
					this.state.personaSeleccionada
						? this.state.personaSeleccionada.ID_PERSONA
						: null
					}
					ref={input => {
					this.datosPersona = input;
					}}
					i18next={i18next}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Tooltip content={i18next.t("cerraryvolver",{ns:"global"})} position="top">
						<Button onClick={cerrarModal}>{i18next.t("close",{ns:"global"})}</Button>
					</Tooltip>
					<Tooltip
						content={
						this.state.nuevaPresonaActivado
							? i18next.t("agregar",{ns:"global"})
							: i18next.t("edit",{ns:"global"})
						}
						position="top"
					>
						<AnchorButton
						intent={Intent.PRIMARY}
						onClick={
							this.state.nuevaPresonaActivado
							? crearPersona
							: actualizarPersona
						}
						>
						{this.state.nuevaPresonaActivado
							? i18next.t("agregar",{ns:"global"})
							: i18next.t("edit",{ns:"global"})}
						</AnchorButton>
					</Tooltip>
					</div>
				</div>
				</Dialog>
				<Dialog
				transitionDuration={400}
				autoFocus={true}
				enforceFocus={true}
				icon="info-sign"
				onClose={cerrarModal2}
				title={i18next.t("propcompay",{ns:"global"})}
				canEscapeKeyClose={true}
				canOutsideClickClose={true}
				isCloseButtonShown={true}
				usePortal={true}
				isOpen={this.state.modalEditarPersonaJuridica}
				className="datos-empresa-dialog"
				>
				<DatosPersonasJuridicas
					animacion={this.animacion}
					cambiarPestaña={this.cambiarPestaña}
					pestaña={this.state.pestaña}
					empresaSeleccionada={this.state.personaSeleccionada?this.state.personaSeleccionada:null}
					i18next={i18next}
				/>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS} />
					<ButtonGroup>
						<Button
							alignText="right"
							icon="log-out"
							intent="danger"
							text={i18next.t("close",{ns:"global"})}
							onClick={cerrarModal2}
						/>
					</ButtonGroup>
				</div>
				</Dialog>
				<div className="tablaSesenta pixelAlrededorGrande">
				<Card>
				<Callout className="bp3-intent-danger">{i18next.t("otraspartesinteresadas.title",{ns:"page"})}</Callout>
				{this.state.cargandoGrid?
					<Cargando/>
				:
				<>
				<Navbar>
					<Navbar.Group>
					<ButtonGroup align={Alignment.LEFT}>
						{!this.props.editable?

							<Button
							disabled={this.state.editando}
							className="bp3-minimal"
							icon="add"
							text={i18next.t("new",{ns:"global"})}
							active={this.state.nuevaPresonaActivado}
							onClick={abrirPopover}
							/>
											:
							<Popover
							disabled={this.state.editando}
							content={<Botonera />}
							transitionDuration={400}
							target={
							<Button
								disabled={this.state.editando}
								className="bp3-minimal"
								icon="add"
								text={i18next.t("new",{ns:"global"})}
								active={this.state.nuevaPresonaActivado}
								onClick={abrirPopover}
							/>
							}
						/>
						}

						<Button
						className="bp3-minimal"
						icon="edit"
						text={i18next.t("edit",{ns:"global"})}
						onClick={abrirModalEditarPersona}
						active={this.state.editarActivado}
						/>
						<NavbarDivider />
						<Tooltip 
							content={i18next.t("print",{ns:"global"})} 
							position="right" 
						>
							<Button 
								className="bp3-minimal" 
								icon="print" 
								text={i18next.t("print",{ns:"global"})} 
								onClick={()=>this.generarImprimirG()}
							/>
						</Tooltip>
						
					</ButtonGroup>
					</Navbar.Group>
				</Navbar>
				<BootstrapTable
				keyField='value'
				data={ this.state.otrasPersonas }
				columns={ columns }
				selectRow={ const_table.selectRow(handleOnSelect) }
				pagination={ paginationFactory(const_table.options) }
				headerClasses="header-class"
				rowClasses="row-class"
				hover
				condensed
				noDataIndication={ i18next.t("datavacio",{ns:"global"}) }
				/>
				</>
  				}
				<br />
				</Card>
				</div>
				<div className="pixelAlrededorGrande tablaCuarenta">
					<Card interactive={true} elevation={Elevation.TWO}>
					<Callout className="bp3-intent-danger">{i18next.t("otraspartesinteresadas.titleley",{ns:"page"})}</Callout>
					<p>{i18next.t("otraspartesinteresadas.descley",{ns:"page"})}</p>
					</Card>
				</div>
			</>
		  }
	  </>
	);
  }
}

export default withApollo(OtrasPartesInteresadas);
