import React from "react";
import {
  Card,
  Elevation,
  FormGroup,
  Button,
  Checkbox,
  InputGroup,
  Popover,
  Tooltip,
  AnchorButton,
  Intent,
  Classes,
  Tab,
  Dialog,
  Tabs,
  Spinner
} from "@blueprintjs/core";
import Select from "react-select";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import InformacionGeneralNuevo from "../../Pantallas/Elementos/InformacionGeneralNuevo"
import InformacionGeneral from "../../Pantallas/Elementos/InformacionGeneral"
import MediosContacto from "../../Pantallas/Elementos/MediosContacto"
import CentrosTrabajo from "../../Pantallas/Elementos/CentrosTrabajo"
import Documentos from "../../Pantallas/Elementos/Documentos"
import Vinculos from "../../Pantallas/Elementos/Vinculos"
import ArchivosAdjuntos from "../../Pantallas/Elementos/ArchivosAdjuntos"
import { DatosPersonasFisicas } from "../Elementos/DatosPersonasFisicas"

let client = new ApolloClient({
    uri: process.env.REACT_APP_DOMINIO+"/api",
    credentials: 'include',
});

export class NuevoTituloSociosActual extends React.Component {

  state={
    administradores: [],
    socios: [],
    activadoCheckbox: [],
    numeroTitulos:1,
    tiposParticipacion:[],
    socioSeleccionado:null,
    nuevaPresonaActivado:true,
    editarPersonaActivado:false,
    tituloCreado:null,
    tituloActualizado:null,
    numeracionTitulos:null,
    numeroParticipaciones:null,
    tipoCapital:null,
    cargand:false
  }

    cargarTiposAccion=async()=>{

        // si el tipo es acciones mostramos los tipo de acciones
        if(this.state.tipoCapital===1){
            
            await client.query({
                query:gql`{tiposAccion{
                    ID_TIPO_ACCION,
                    DESCRIPCION,
                    INCOMPATIBLE,
                }}`
            }).then(async(result)=>{
                this.setState({tiposParticipacion:result.data.tiposAccion})
            })

        }else{

            //de lo contrario, mostramos las participaciones
            await client.query({
                query:gql`{tiposParticipacion{
                    ID_TIPO_PARTICIPACION,
                    DESCRIPCION,
                    INCOMPATIBLE,
                }}`
            }).then(async(result)=>{
                this.setState({tiposParticipacion:result.data.tiposParticipacion})
            })

        }


    }

    hayRepetidos=(num)=>{

        let numeros=[]
        let numeracion=num
        if(numeracion.includes(",")){
          numeracion=numeracion.split(",")
        }else{
          numeracion=[numeracion]
        }
        numeracion.forEach(num=>{
          if(num.includes("-")){
              let dis=num.split("-")
              for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
                  numeros.push(String(i))
              }
          }else{
              numeros.push(num)
          }

      })

      let findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index)

      if(findDuplicates(numeros).length>0)return true

      return false

    }

    hayCeros=(num)=>{

        let numeros=[]
        let numeracion=num
        if(numeracion.includes(",")){
          numeracion=numeracion.split(",")
        }else{
          numeracion=[numeracion]
        }
        numeracion.forEach(num=>{
          if(num.includes("-")){
              let dis=num.split("-")
              for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
                  numeros.push(String(i))
              }
          }else{
              numeros.push(num)
          }

      })

      if(numeros.find(num=>{return num==="0"}))return true

      return false

    }

    validarNumeracion=(numeracion)=>{
        //validamos la entrada de la numeracion de titulos.
        let pattern = /^[0-9]{1,9}([,-]{1}[0-9]+)*$/;
        if (pattern.test(numeracion)) {
            return true;
        } 
        return false;
    }

    cargarPersonas=async ()=>{
        let personas=[]

        let personasFisicas =await client.query({
            query:gql`{personasFisicas(ORDEN:"APELLIDO1,ASC"){ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},NOMBRE,APELLIDO1,APELLIDO2}}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
           return result.data.personasFisicas            
        })

        let personasJuridicas= await client.query({
            query:gql`{personasJuridicas{ID_PERSONA,PERSONA{TIPO_PERSONA{ID_TIPO_PERSONA}},RAZON_SOCIAL}}`,fetchPolicy:'network-only'
        }).then(async(result)=>{
            //console.log(result)
           return result.data.personasJuridicas

        })
        personasFisicas.forEach(async(persona)=>{            
            personas.push({"value":persona.ID_PERSONA,"label":`${persona.APELLIDO1} ${persona.APELLIDO2?persona.APELLIDO2:""} ${persona.NOMBRE}`,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        })
        personasJuridicas.forEach(async(persona)=>{
            
            personas.push({"value":persona.ID_PERSONA,"label":persona.RAZON_SOCIAL,tipo:persona.PERSONA.TIPO_PERSONA.ID_TIPO_PERSONA}) 

        })


        if(this.props.datosDistribucion && Object.keys(this.props.datosDistribucion).length >0){
            //buscamos a la persona.
            let personaSel=personas.find(persona=>{return persona.value===this.props.datosDistribucion.ID_PERSONA})
            this.setState({socioSeleccionado:personaSel})
        }
        //console.log(personas)
        this.setState({socios:personas})

    }

    obtenerPotencias=(n)=>{
        let array = [];
        let binaryRepresentation = parseInt(n, 10).toString(2);
        binaryRepresentation = binaryRepresentation.split("").reverse().join(""); // You need to reverse the string to get the power of 2 corresponding
        for(let i = binaryRepresentation.length - 1; i >= 0; i--){
            if(parseInt(binaryRepresentation[i]) === 1){
                array['"'+Math.pow(2, i)+'"']=true
            }
        }
        console.log()
        return array
    }
    cargarTipo= async()=>{
        await client
        .query({
            query: gql`
            {
                personasJuridicas(CAMPOS:"ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL",FILTROS:{EMPRESA_PRINCIPAL:true}){ID_PERSONA,ID_TIPO_CAPITAL_SOCIAL}
            }
            `,
            fetchPolicy:'network-only'
        }).then(result=>{ 

            this.setState({tipoCapital:result.data.personasJuridicas[0].ID_TIPO_CAPITAL_SOCIAL})
       
        })
    }
    
    componentDidMount=async(props)=>{
        this.setState({cargando:true}, async()=>{
            await this.cargarPersonas()
            await this.cargarTipo()
            await this.cargarTiposAccion()
            if(this.props.datosDistribucion && Object.keys(this.props.datosDistribucion).length >0){
                this.setState({numeroTitulos:this.props.datosDistribucion.NUMERO_TITULOS})
                this.setState({numeracionTitulos:this.props.datosDistribucion.NUMERACION_TITULOS})
                let valoresActivados= this.obtenerPotencias(this.props.datosDistribucion.TIPO_TITULO)
               this.setState({activadoCheckbox:valoresActivados})
    
            }
            this.setState({cargando:false})
       })   

    }

    buscarIncomptaible=async(participacionAccion)=>{

        let inconmpatible=false
        let valoresIncompatibles=participacionAccion.INCOMPATIBLE?participacionAccion.INCOMPATIBLE.split(","):null

        for(let inc of valoresIncompatibles){
            let estaMarcado=Object.keys(this.state.activadoCheckbox).find(activado=>{
                return activado.replace(/"/g, "")===inc
            })
            if(estaMarcado){
                inconmpatible=true                
                //cogemos la descripcion del marcado
                let tipo=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===parseInt(estaMarcado.replace(/"/g, "")):tipo.ID_TIPO_PARTICIPACION===parseInt(estaMarcado.replace(/"/g, ""))})
                alert(this.props.i18next.t("capitalsocialactual.valid4",{ns:"page"})+" '"+tipo.DESCRIPCION+"' "+this.props.i18next.t("capitalsocialactual.and",{ns:"page"})+" '"+participacionAccion.DESCRIPCION+"'")
            }
        }
        return inconmpatible
    }


    aceptarDistribución = async() => {
        
        let tipos=Object.keys(this.state.activadoCheckbox)
        tipos.forEach((tipo,index)=>{
            tipos[index]=parseInt(tipo.replace(/"/g, ""))
        })
        let incompatible=false

        tipos.forEach(async t=>{

            let tipo=this.state.tiposParticipacion.find(tipo=>{return this.state.tipoCapital===1?tipo.ID_TIPO_ACCION===t:tipo.ID_TIPO_PARTICIPACION===t})
            if(tipos.indexOf(parseInt(tipo.INCOMPATIBLE))>=0 && !incompatible){

                incompatible= this.buscarIncomptaible(tipo)

            }
            
        })

        if(incompatible)return
        if(!this.state.numeracionTitulos)return
        if(this.validarNumeracion(this.state.numeracionTitulos)===false){
            alert(this.props.i18next.t("capitalsocialactual.valid5",{ns:"page"}))
            return
        }

        if(this.hayRepetidos(this.state.numeracionTitulos)){
            alert(this.props.i18next.t("capitalsocialactual.valid6",{ns:"page"}))
            return
        }

        
        if(this.hayCeros(this.state.numeracionTitulos)){
            alert(this.props.i18next.t("capitalsocialactual.valid7",{ns:"page"}))
            return
        }


        if(!this.state.socioSeleccionado)return
        if(Object.keys(this.state.activadoCheckbox).length===0)return

        let tipoTitulo=0
        for (let key in this.state.activadoCheckbox) {
            //damos de alta los tipos de participaciones
            tipoTitulo+=parseInt(key.replace("\"",""))
        }

        if(this.props.datosDistribucion && Object.keys(this.props.datosDistribucion).length >0){

           
            await client.mutate({

                //si seleccionamos un numero menor al actual.
                mutation:gql`mutation{actualizarcapSocialActualDistribucion(ID_DISTRIBUCION:${this.props.datosDistribucion.ID_DISTRIBUCION},VALORES:{
                    TIPO_TITULO:${tipoTitulo}
                    NUMERO_TITULOS:${this.state.numeroTitulos},
                    NUMERACION_TITULOS:"${this.state.numeracionTitulos}"
                }){ID_DISTRIBUCION}}`

            }).then(result=>{

                alert(this.props.i18next.t("updatecorrecto",{ns:"global"}))
                this.setState({tituloActualizado:result.data.actualizarcapSocialActualDistribucion.ID_DISTRIBUCION})
                
            }).catch(err=>{
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }else{
            

            await client.mutate({

                mutation:gql`mutation{crearcapSocialActualDistribucion(VALORES:{
                    ID_PERSONA:${this.state.socioSeleccionado.value}
                    TIPO_TITULO:${tipoTitulo}
                    TIPO_CAPITAL:"A"
                    NUMERO_TITULOS:${this.state.numeroTitulos}
                    NUMERACION_TITULOS:"${this.state.numeracionTitulos}"
                }){ID_DISTRIBUCION}}`

            }).then(result=>{

                alert(this.props.i18next.t("insertcorrecto",{ns:"global"}))
                this.setState({tituloCreado:result.data.crearcapSocialActualDistribucion.ID_DISTRIBUCION})
                
            }).catch(err=>{
                console.log(err)
                alert(this.props.i18next.t("error",{ns:"global"}))
            })

        }
        
    }

  
  render() {


    const seleccionSocio = value => {
        this.setState({ socioSeleccionado: value });
    };
  
    const handleEnabledChange = (index) => {
        let activados=this.state.activadoCheckbox
        let valor=!activados[index]
        if(valor){
            activados[index]=valor
        }else{
            delete activados[index]
        }

        this.setState({ activadoCheckbox: activados});
    };
  
    const camibarNumtTiutulos=(ev)=>{

        this.setState({numeroTitulos:ev.currentTarget.value})
      
    }

    const camibarNumtParticipaciones=(ev)=>{
          //si la nuemeracion es válida.
          let numeros=[]
          let numeracion=ev.currentTarget.value
          if(numeracion.includes(",")){
            numeracion=numeracion.split(",")
          }else{
            numeracion=[numeracion]
          }
          numeracion.forEach(num=>{
            if(num.includes("-")){
                let dis=num.split("-")
                for(let i=parseInt(dis[0]);i<=parseInt(dis[1]);i++){
                    numeros.push(String(i))
                }
            }else{
                numeros.push(num)
            }

        })


        this.setState({numeroTitulos:numeros.length,numeracionTitulos:ev.currentTarget.value})
     
    }

    
    const Botonera = () => {
        return(
            <>
                <Button className="bp3-minimal" icon="new-link" text={this.props.i18next.t("newpersfisica",{ns:"global"})} fill={true} onClick={abrirModalNuevaPersona} />
                <br />
                <Button className="bp3-minimal" icon="git-new-branch" text={this.props.i18next.t("newpersjuridica",{ns:"global"})} onClick={abrirModalNuevaPersonaJurídica} />
            </>
        )
    }

    const abrirModalNuevaPersona=()=>{

        //abrir modal de persona física
        this.setState({socioSeleccionado:null})
        this.setState({modalPersonaAbierto:true})
        this.setState({popoverAbierto:false})
        this.setState({nuevaPresonaActivado:true})
        this.setState({editarPersonaActivado:false})

    }

    const abrirModalNuevaPersonaJurídica=()=>{
        this.setState({socioSeleccionado:null})
        //abrir modal de persona jurídica
        this.setState({modalNuevaJuridicaAbierto:true})
        this.setState({popoverAbierto:false})
        this.setState({nuevaPresonaActivado:true})
        this.setState({editarPersonaActivado:false})
        
    }

    const abrirPopover = () => {
        this.setState({popoverAbierto: true})
    }

    const cerrarModal2 = () => {
        this.setState({
            modalPersonaAbierto: false,
        })
    }

    const cerrarModal=()=>{
        this.setState({modalNuevaJuridicaAbierto:false})
        this.setState({modalEditarPersonaJuridica:false})
        this.setState({modalTi:false})
    }

    const guardarPersonaJuridica=async ()=>{

        let result=await this.informacion.handleGuardarDatos()
        if(!result)return
        await this.setState({modalNuevaJuridicaAbierto:false})
        if(this.informacion.state.personaCreada){

            await this.cargarPersonas()
            //seleccionamos a al persona creada en la lista.
            let personaSel=this.state.socios.find(socio=>{return socio.value===this.informacion.state.personaCreada})
            this.setState({socioSeleccionado:personaSel})
        }

    }

    const crearPersona=async()=>{

        await this.datosPersona.crearPersona()
        if(this.datosPersona.state.idPersonaCreada){
            await this.cargarPersonas()
            //seleccionar empleado creado
            let personaSel=this.state.socios.find(persona=>{return persona.value===this.datosPersona.state.idPersonaCreada})
            console.log(personaSel)
            this.setState({socioSeleccionado:personaSel})
            //cerrar el modal
            cerrarModal2()
        }

    }

    const actualizarPersona=async ()=>{

        await this.datosPersona.actualizarPersona()
        if(this.datosPersona.state.idPersonaActualizada){
            await this.cargarPersonas()
            
            //seleccionar empleado creado
            let personaSel=this.state.socios.find(persona=>{return persona.value===this.datosPersona.state.idPersonaActualizada})
            this.setState({socioSeleccionado:personaSel})
            //cerrar el modal
            cerrarModal2()
        }

    }

    const abrirModalEditarPersona=()=>{

        if(!this.state.socioSeleccionado){
            alert(this.props.i18next.t("noseleccionado",{ns:"global"}))
            return
        }

        this.setState({nuevaPresonaActivado:false})
        this.setState({editarPersonaActivado:true})
            //si es personas física
        if(this.state.socioSeleccionado.tipo===1){
            this.setState({modalPersonaAbierto:true})
            //si es persona jurídica
        }else if(this.state.socioSeleccionado.tipo===2){
            this.setState({modalEditarPersonaJuridica:true})
        }

    }
   
    return (
        <>
        {this.state.cargando===true?
            <Spinner/>
        :
        <>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalNuevaJuridicaAbierto}
            className="datos-empresa-dialog"
        >
                <InformacionGeneralNuevo  ref={(input) => { this.informacion= input; }} i18next={this.props.i18next}/>  
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content={this.props.i18next.t("guardar",{ns:"global"})} position="top">
                <Button
                    alignText="left"
                    icon="floppy-disk"
                    text={this.props.i18next.t("guardar",{ns:"global"})}
                    intent="danger"
                    onClick={guardarPersonaJuridica}
                    className="iconoIzquierda bp3-minimal"
                />
                </Tooltip>
            </div>
        </div>  
        </Dialog>
        <Dialog transitionDuration={400}
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal}
            title={this.props.i18next.t("organoadministracion.propcompany",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            usePortal={true}
            isOpen={this.state.modalEditarPersonaJuridica}
            className="datos-empresa-dialog"
        >
            <Tabs id="datos-empresa"
                animate={this.animacion} 
                onChange={this.cambiarPestaña} 
                selectedTabId={this.state.pestaña}
            >
                <Tab id="informacion-general" title="Información general" panel={ <InformacionGeneral empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null}  ref={(input) => { this.datos= input; }} cargarPersonas={this.cargarPersonas} i18next={this.props.i18next}/> } />
                <Tab id="medios de contacto" title="Medios de Contacto" panel={<MediosContacto empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} i18next={this.props.i18next}/>} />
                <Tab id="centros de trabajo" title="Centros de trabajo / Sucursales" panel={<CentrosTrabajo empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} i18next={this.props.i18next} />} />
                {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="documentos" title="Documentos" panel={<Documentos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} i18next={this.props.i18next} />} />:<></>}
                {this.state.socioSeleccionado && this.state.socioSeleccionado.esPrincipal?<Tab id="vinculos" title="Vínculos" panel={<Vinculos empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} i18next={this.props.i18next} />}  />:<></>}
                <Tab id="archivos-adjuntos" title="Archivos adjuntos" panel={<ArchivosAdjuntos   empresaSeleccionada={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} i18next={this.props.i18next} />} />
            </Tabs>
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            </div>
        </div>  
        </Dialog>
        <Dialog
            autoFocus={true}
            enforceFocus={true}
            icon="info-sign"
            onClose={cerrarModal2}
            title={this.props.i18next.t("organosinstruccion.propiedadpersona",{ns:"page"})}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isCloseButtonShown={true}
            className="dialog-ancho"
            isOpen={this.state.modalPersonaAbierto}
        >
            <DatosPersonasFisicas i18next={this.props.i18next} datosPersona={this.state.socioSeleccionado?this.state.socioSeleccionado.value:null} ref={(input) => { this.datosPersona= input; }} />
            <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Tooltip content={this.props.i18next.t("cerraryvolver",{ns:"global"})} position="top">
                    <Button onClick={cerrarModal2}>{this.props.i18next.t("close",{ns:"global"})}</Button>
                </Tooltip>
                <Tooltip content={this.state.nuevaPresonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} position="top">
                    <AnchorButton
                        intent={Intent.PRIMARY}
                        onClick={this.state.nuevaPresonaActivado?crearPersona:actualizarPersona} 
                    >
                    {this.state.nuevaPresonaActivado?this.props.i18next.t("agregar",{ns:"global"}):this.props.i18next.t("edit",{ns:"global"})} 
                    </AnchorButton>
                </Tooltip>
            </div>
        </div>
        </Dialog>
    
        <Card interactive={true} elevation={Elevation.TWO}>
          <FormGroup
            label={this.props.i18next.t("capitalsocialactual.socio",{ns:"page"})+":"}
            labelFor="socio"
            intent="danger"
            fill={true}
            helperText={!this.state.socioSeleccionado?this.props.i18next.t("emptyfield",{ns:"global"})+this.props.i18next.t("capitalsocialactual.socio",{ns:"page"}):""}
          >
            <div style={!this.state.socioSeleccionado?{border:"1px solid red"}:{}}>
                <Select
                options={this.state.socios}
                className="pixelAlrededor"
                onChange={seleccionSocio}
                isDisabled={this.props.datosDistribucion && Object.keys(this.props.datosDistribucion).length >0}
                value={this.state.socioSeleccionado}
                style={{
                    width: 350
                }}
                />
            </div>
            <FormGroup>
                <Popover  disabled={this.state.editando} active={this.state.nuevaPresonaActivado} content={<Botonera />} transitionDuration={400} target={<Button  disabled={this.state.editando}  icon="new-object" active={this.state.nuevaPresonaActivado} onClick={abrirPopover} />} />
                <Button icon="edit" active={this.state.editarPersonaActivado} onClick={abrirModalEditarPersona}/>
            </FormGroup> 
          
          </FormGroup>
          <FormGroup
            label={this.props.i18next.t("capitalsocialactual.nroparticipaciones",{ns:"page"})+":"}
            labelFor="participaciones"
          >
            <InputGroup type="number" readOnly={true} step="1" min={1} onChange={camibarNumtTiutulos} value={this.state.numeroTitulos}/>
          </FormGroup>
          <FormGroup
            label={this.props.i18next.t("capitalsocialactual.numtitulos",{ns:"page"})+":"}
            labelFor="participaciones"
            intent={!this.state.numeracionTitulos?"danger":"primary"}
            helperText={!this.state.numeracionTitulos?this.props.i18next.t("capitalsocialactual.valid8",{ns:"page"}):""}
          >
            <InputGroup intent={!this.state.numeracionTitulos?"danger":"primary"} onChange={camibarNumtParticipaciones} value={this.state.numeracionTitulos}/>
          </FormGroup>

          <FormGroup
            label={this.props.i18next.t("capitalsocialactual.tipoacciones",{ns:"page"})+":"}
            labelFor="tipo-o-participantes"
          >
           {this.state.tiposParticipacion.map((participacion,index) => {
            return (
                <Checkbox
                    label={participacion.DESCRIPCION}
                    checked={this.state.activadoCheckbox[this.state.tipoCapital===1?'"'+participacion.ID_TIPO_ACCION+'"':'"'+participacion.ID_TIPO_PARTICIPACION+'"']}
                    onChange={() => handleEnabledChange(this.state.tipoCapital===1?'"'+participacion.ID_TIPO_ACCION+'"':'"'+participacion.ID_TIPO_PARTICIPACION+'"')}
                />
            );
          })}
           
          </FormGroup>
        </Card>
        </>
    }
    </>
    );
  }
}
